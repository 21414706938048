import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../core/services/user.service';
import { User, UserLevel } from '../core/models/user.model';
import { AuthService } from '../core/services/auth.service';
import { CommonService } from '../core/services/common.service';

@Component( {
    selector: 'app-header',
    templateUrl: './header.component.html',
} )

export class HeaderComponent {

    @Input()
    user: User | any;

    constructor ( private router: Router,
        private userService: UserService,
        private auth: AuthService,
        private commonService: CommonService
    ) {

        this.user = this.userService.getCurrentUser();
    }

    get isAdmin () { return this.user.level_id >= UserLevel.Admin; }

    get isSuperAdmin () { return this.user.level_id == UserLevel.SuperAdmin; }

    logout () {
        this.auth.logout().subscribe( {
            next: ( response ) => {

                this.commonService.notifications.success( response[ 'message' ] );
                this.user = null;
                this.userService.purgeAuth();
                this.router.navigateByUrl( '/login' );
            },
            error: ( err ) => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        } )

    }

}
