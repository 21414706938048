import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';


@Component( {
    selector: 'app-edit-permission',
    templateUrl: './edit-permission.component.html',
    styleUrls: [ './edit-permission.component.css' ],
} )
export class EditPermissionComponent implements OnInit {

    isSubmitting = false;
    editAssignedPermissionForm!: FormGroup;
    permissions!: any;
    components!: any;
    user_id!: number;
    permission_id: any = [];
    optionsModel!: number[];
    myOptions!: any[];

    @ViewChild( 'editPermissionsModal', { static: false } )
    public editPermissionsModal!: ModalDirective;

    @Output()
    onPermissionUpdated = new EventEmitter<boolean>();

    constructor ( private permissionService: PermissionsService,
        private commonService: CommonService,
        private fb: FormBuilder ) { }

    ngOnInit () {

        this.initForm();

        this.permissionService.getPermissionSubjects().subscribe(
            data => {
                this.components = data;

            }
        )

        this.getAllPermissions();

    }

    initForm () {
        this.editAssignedPermissionForm = this.fb.group( {
            user: [ '', Validators.required ],
            component: [ '' ],
            permission: [ [] ],
        } );

    }

    show ( id: number ) {
        this.user_id = id;
        this.permission_id = [];
        this.getAssignedPermissions( id );
        this.editPermissionsModal.show();
    }

    hide () {

        this.editPermissionsModal.hide();
    }

    updateAssignedPermissions () {

        this.isSubmitting = true;

        this.permissionService.assignPermissions( this.editAssignedPermissionForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( response[ 'message' ] );
                this.isSubmitting = false;
                this.hide();
                this.onPermissionUpdated.emit( true );

            },
            err => {
                this.isSubmitting = false;
            }
        )
    }

    getAssignedPermissions ( id: number ) {
        this.permissionService.getUserAssignedPermissions( this.user_id ).subscribe(
            ( data: any ) => {
                if ( data.length ) {

                    for ( let i = 0; i < data.length; i++ ) {

                        this.permission_id.push( data[ i ].id );

                    }
                    this.optionsModel = this.permission_id;
                }

            }
        )
    }

    changeComponent ( $event: any ) {

        if ( $event.target.value == '' ) {
            return;
        }

        if ( $event.target.value == 'all' ) {
            this.getAllPermissions();
        }
        else {
            this.getPermissionGroups( $event.target.value );
        }

    }

    getAllPermissions () {
        this.permissions = [];
        this.myOptions = [];

        this.permissionService.getAllPermissions().subscribe(
            data => {

                for ( let i = 0; i < data.length; i++ ) {
                    this.permissions.push( { id: data[ i ].id, name: data[ i ].subject + ' | ' + data[ i ].action } );
                }
                this.myOptions = this.permissions;
            }
        )
    }

    getPermissionGroups ( name: string ) {
        this.permissions = [];
        this.myOptions = [];

        this.permissionService.getPermissionGroups( name ).subscribe(
            data => {
                for ( let i = 0; i < data.length; i++ ) {
                    this.permissions.push( { id: data[ i ].id, name: data[ i ].subject + ' | ' + data[ i ].action } );
                }
                this.myOptions = this.permissions;
            }
        )
    }

}
