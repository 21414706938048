import { Directive, ElementRef, Input } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Directive( {
    selector: '[appAccess]',
} )
export class AccessDirective {

    @Input( 'appAccess' ) permission!: string; // Required permission passed in

    constructor (
        private el: ElementRef,
        private userService: UserService
    ) { }

    ngOnInit (): boolean | void {

        if ( this.userService.isSuperAdmin ) return true;

        if ( this.userService.hasPermission( this.permission ) ) return true;

        this.el.nativeElement.remove();
    }
}
