
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { User, UserLevel } from '../models/user.model';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { Pagination } from '../models/pagination.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { ModalService } from './modal.service';

@Injectable( {
    providedIn: 'root'
} )
export class UserService {

    private currentUserObject = new BehaviorSubject<User>( new User() );

    private isAuthenticatedSubject = new ReplaySubject<boolean>( 1 );

    public isAuthenticated = this.isAuthenticatedSubject.asObservable();

    get isAdmin () { return this.currentUser.level_id >= UserLevel.Admin; }

    get isSuperAdmin () { return this.currentUser.level_id == UserLevel.SuperAdmin ? true : false; }

    constructor (
        private apiService: ApiService,
        private http: HttpClient,
        private jwtService: JwtService,
        private modalService: ModalService

    ) { }

    get currentUser (): User {

        return this.getCurrentUser();

    }

    populate () {
        if ( this.jwtService.getToken() ) {
            this.apiService.get( 'user' ).subscribe(
                data => this.setAuth( data ),
                err => this.purgeAuth()
            );
        } else {
            this.purgeAuth();
        }
    }

    handleError (): void {
        console.log( 'error' );
    }

    setAuth ( user: User ) {
        if ( !this.jwtService.getToken() ) {
            this.jwtService.saveToken( user.token );
        }
        this.currentUserObject.next( user );
        this.isAuthenticatedSubject.next( true );
    }

    purgeAuth () {
        this.jwtService.destroyToken();
        this.currentUserObject.next( new User() );
        this.isAuthenticatedSubject.next( false );
    }

    attemptAuth ( type: string, credentials: Object | undefined ): Observable<User> {
        const route = ( type === 'login' ) ? 'clients/login' : '';

        return this.apiService.post( route, credentials ).pipe(
            map( data => {
                this.setAuth( data );

                return data;
            } ) );
    }

    getCurrentUser (): User {

        return this.jwtService.getCurrentUser() ? this.jwtService.getCurrentUser() : undefined;

    }

    getAllUsers ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.apiService.get( 'users/', params );
    }

    getUsersByLevelId ( levelIds: UserLevel[] ): Observable<User[]> {
        return this.apiService.get( 'users?paginate=false&level_id_in=' + levelIds );
    }

    // @ts-ignore
    // public populateLevels () {
    //     return ( this.global as CustomGlobal )[ 'user-levels' ] =
    //         [
    //             {
    //                 id: 1,
    //                 name: 'Default',
    //             },
    //             {
    //                 id: 2,
    //                 name: 'Editor',
    //             },
    //             {
    //                 id: 3,
    //                 name: 'Moderator',
    //             },
    //             {
    //                 id: 4,
    //                 name: 'Admin',
    //             },
    //             {
    //                 id: 5,
    //                 name: 'Super-Admin',
    //             },
    //         ];
    // }


    create ( values: Object ) {
        return this.apiService.post( 'users', values );
    }

    update ( id: number, values: Object ) {
        return this.apiService.put( 'users/' + id, values );
    }

    toggleStatus ( id: number ): Observable<User> {
        return this.apiService.get( 'users/' + id + '/toggle-status' );
    }

    deleteUser ( id: any ) {
        return this.apiService.delete( 'users/' + id );
    }

    resetPassword ( id: number, values: any ) {
        return this.apiService.put( 'users/' + id + '/reset-password', values );
    }

    logoutAll (): Observable<any> {
        return this.apiService.get( 'users/logout-all' );
    }


    resetAllPasswords (): Observable<any> {
        return this.apiService.get( 'users/reset-all-passwords' );
    }

    bulkCreate ( object: object ) {
        return this.apiService.post( 'users/bulk-create', object );
    }

    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }

    getHistory ( userId: number ) {
        return this.apiService.get( 'users/' + userId + '/history' );
    }

    hasPermission ( permissionName: string ): boolean {
        return this.getCurrentUser().permissions.includes( permissionName );
    }

}
