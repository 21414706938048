import { UserResetPasswordComponent } from './user/user-reset-password/user-reset-password.component';
import { AdminGuard } from './core/services/admin.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { CompanyEditComponent } from './company/company-edit/company-edit.component';
import { CompanyCreateComponent } from './company/company-create/company-create.component';
import { CompanyShowComponent } from './company/company-show/company-show.component'
import { LotShowComponent } from './lot/lot-show/lot-show.component';
import { EmployeeCreateComponent } from './employee/employee-create/employee-create.component';
import { EmployeeEditComponent } from './employee/employee-edit/employee-edit.component';
import { LoginComponent } from './login/login.component';
import { NoAuthGuard } from './login/no-auth-guard.service';
import { SearchListComponent } from './search-list/search-list.component';
import { LotListComponent } from './lot/lot-list/lot-list.component';
import { LotEditComponent } from './lot/lot-edit/lot-edit.component';
import { LotCreateComponent } from './lot/lot-create/lot-create.component';
import { VcourtJobListComponent } from './vcourt/vcourt-job-list/vcourt-job-list.component';
import { VcourtJobShowComponent } from './vcourt/vcourt-job-show/vcourt-job-show.component';
import { IcrListComponent } from './icr/icr-list/icr-list.component';
import { IcrEditComponent } from './icr/icr-edit/icr-edit.component';
import { IdentityListComponent } from './identity/identity-list/identity-list.component';
import { IdentityEditComponent } from './identity/identity-edit/identity-edit.component';
import { SubmitToIcrComponent } from './identity/submit-to-icr/submit-to-icr.component';
import { VcourtJobCreateComponent } from './vcourt/vcourt-job-create/vcourt-job-create.component';
import { IcrCreateActTypeComponent } from './icr/icr-create-act-type/icr-create-act-type.component';
import { AuthGuard } from './core/services/auth-guard.service'
import { EmployeeImportComponent } from './employee/employee-import/employee-import.component';
import { WorkHistoryComponent } from './work/work-history/work-history.component';
import { WorkListComponent } from './work/work-list/work-list.component';
import { IdentityAssignedWorkListComponent } from './identity/identity-assigned-work-list/identity-assigned-work-list.component';
import { IcrAssignedWorkListComponent } from './icr/icr-assigned-work-list/icr-assigned-work-list.component';
import { SubmitToVcourtComponent } from './icr/submit-to-vcourt/submit-to-vcourt.component';
import { DocumentListComponent } from './document/document-list/document-list.component';
import { DocumentEditComponent } from './document/document-edit/document-edit.component';
import { DocumentAssignedWorkListComponent } from './document/document-assigned-work-list/document-assigned-work-list.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { EmploymentListComponent } from './employment/employment-list/employment-list.component';
import { EmploymentEditPageComponent } from './employment/employment-edit-page/employment-edit-page.component';
import { EmploymentAssignedWorkListComponent } from './employment/employment-assigned-work-list/employment-assigned-work-list.component';
import { EducationListComponent } from './education/education-list/education-list.component';
import { EducationEditComponent } from './education/education-edit/education-edit.component';
import { EducationAssignedWorkListComponent } from './education/education-assigned-work-list/education-assigned-work-list.component';
import { GlobalDbListComponent } from './global-db/global-db-list/global-db-list.component';
import { GlobalDbEditComponent } from './global-db/global-db-edit/global-db-edit.component';
import { AddressListComponent } from './address/address-list/address-list.component';
import { AddressEditComponent } from './address/address-edit/address-edit.component';
import { PccListComponent } from './pcc/pcc-list/pcc-list.component';
import { PccEditComponent } from './pcc/pcc-edit/pcc-edit.component';
import { PccAssignedWorkListComponent } from './pcc/pcc-assigned-work-list/pcc-assigned-work-list.component';
import { ClientEditComponent } from './clients/client-edit/client-edit.component';
import { ClientCreateComponent } from './clients/client-create/client-create.component';
import { ClientUploadComponent } from './clients/client-upload/client-upload.component';
import { AddressAssignedWorkListComponent } from './address/address-assigned-work-list/address-assigned-work-list.component';
import { ReferenceListComponent } from './reference/reference-list/reference-list.component';
import { ReferenceAssignedWorkListComponent } from './reference/reference-assigned-work-list/reference-assigned-work-list.component';
import { ReferenceEditComponent } from './reference/reference-edit/reference-edit.component';
import { ClientFormsListComponent } from './client-forms/client-forms-list/client-forms-list.component';
import { ClientFormsEditComponent } from './client-forms/client-forms-edit/client-forms-edit.component';
import { ClientFormsCreateComponent } from './client-forms/client-forms-create/client-forms-create.component';
import { AuthorityListComponent } from './authority/authority-list/authority-list.component';
import { EducationInstitutesListComponent } from './education/institutes/education-institutes-list/education-institutes-list.component';
import { EmploymentListCompanyComponent } from './employment/employment-list-company/employment-list-company.component';
import { CompanyExcelComponent } from './company/company-excel/company-excel.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { LotMergeComponent } from './lot/lot-merge/lot-merge.component';
import { VehicleListComponent } from './vehicle/vehicle-list/vehicle-list.component';
import { WebhookListComponent } from './webhook/webhook-list/webhook-list.component';
import { WebhookLogsListComponent } from './webhook/webhook-logs-list/webhook-logs-list.component';
import { SmsListComponent } from './sms/sms-list/sms-list.component';
import { VehicleExcelImportComponent } from './vehicle/vehicle-excel-import/vehicle-excel-import.component';
import { LicenceListComponent } from './licence/licence-list/licence-list.component';
import { LicenceExcelImportComponent } from './licence/licence-excel-import/licence-excel-import.component';
import { AadhaarLogsComponent } from './aadhaar/aadhaar-logs/aadhaar-logs.component';
import { AadhaarExcelImportComponent } from './aadhaar/aadhaar-excel-import/aadhaar-excel-import.component';
import { ReferenceImportComponent } from './reference/reference-import/reference-import.component';
import { DistrictCreateComponent } from './common/district-create/district-create.component';
import { VendorCreateComponent } from './common/vendor-create/vendor-create.component';
import { StatusCreateComponent } from './verification-status/status-create/status-create.component';
import { PanListComponent } from './pan/pan-list/pan-list.component';
import { PendingJobsComponent } from './common/pending-jobs/pending-jobs.component';
import { CriminalExcelImportComponent } from './criminal/criminal-excel-import/criminal-excel-import.component';
import { CriminalSearchComponent } from './criminal/criminal-search/criminal-search.component';
import { EmploymentAnalysisComponent } from './employment/employment-analysis/employment-analysis.component';
import { StolenVehicleExcelSearchComponent } from './stolen-vehicle/stolen-vehicle-excel-search/stolen-vehicle-excel-search.component';
import { UnclaimedVehicleExcelSearchComponent } from './unclaimed-vehicle/unclaimed-vehicle-excel-search/unclaimed-vehicle-excel-search.component';
import { CriminalExcelSearchComponent } from '../app/criminal/criminal-excel-search/criminal-excel-search.component';
import { EmployeeAdditionalDetailsImportComponent } from './common/employee-additional-details-import/employee-additional-details-import.component';
import { PccImportExcelComponent } from './pcc/pcc-import-excel/pcc-import-excel.component';
import { BulkUploadFilesComponent } from './common/bulk-upload-files/bulk-upload-files.component';
import { VoterListComponent } from './voter/voter-list/voter-list.component';
import { VoterExcelImportComponent } from './voter/voter-excel-import/voter-excel-import.component';
import { PanExcelImportComponent } from './pan/pan-excel-import/pan-excel-import.component';
import { ApiRequestLogsComponent } from './api-request-logs/api-request-logs.component';

import { UidaiLogsComponent } from './aadhaar/uidai-logs/uidai-logs.component';
import { UidaiExcelImportComponent } from './aadhaar/uidai-excel-import/uidai-excel-import.component';
import { EmployeeBulkUpdateComponent } from './employee/employee-bulk-update/employee-bulk-update.component';
import { GstImportExcelComponent } from './gst/gst-import-excel/gst-import-excel.component';
import { GstListComponent } from './gst/gst-list/gst-list.component';
import { EmailLogsListComponent } from './email-log/email-logs-list/email-logs-list.component';
import { PermitListComponent } from './permit/permit-list/permit-list.component';
import { PermitExcelImportComponent } from './permit/permit-excel-import/permit-excel-import.component';
import { HistoryShowComponent } from './history/history-show/history-show.component';
import { RemarksListComponent } from './remarks/remarks-list/remarks-list.component';
import { WorkBulkAssignmentComponent } from './work/work-bulk-assignment/work-bulk-assignment.component';
import { HistoryListComponent } from './history/history-list/history-list.component';
import { IncomingWebhookLogsListComponent } from './webhook/incoming-webhook-logs-list/incoming-webhook-logs-list.component';
import { BlacklistedListComponent } from './blacklisted/blacklisted-list/blacklisted-list.component';
import { BlacklistedExcelImportComponent } from './blacklisted/blacklisted-excel-import/blacklisted-excel-import.component';
import { InsuranceListComponent } from './insurance/insurance-list/insurance-list.component';
import { InsuranceExcelImportComponent } from './insurance/insurance-excel-import/insurance-excel-import.component';
import { UberImportComponent } from './employee/uber-import/uber-import.component';
import { VerificationClientRemarkCreateComponent } from './common/verification-client-remark-create/verification-client-remark-create.component';

import { AutoAssignDashboardComponent } from './auto-assign/auto-assign-dashboard/auto-assign-dashboard.component';

import { AutoAssignShowWorkComponent } from './auto-assign/auto-assign-show-work/auto-assign-show-work.component'
import { IdentityWorkCroppingComponent } from './auto-assign/identity-work-cropping/identity-work-cropping.component';

import { OcrListComponent } from './ocr/ocr-list/ocr-list.component';
import { ClientApiCredentialsComponent } from './client-api/client-api-credentials/client-api-credentials.component';

import { WorkTypeListComponent } from './work/work-type-list/work-type-list.component';

import { IdentityWorkDataEntryComponent } from './auto-assign/identity-work-data-entry/identity-work-data-entry.component';
import { IdentityWorkValidationComponent } from './auto-assign/identity-work-validation/identity-work-validation.component';
import { IdentityWorkQaComponent } from './auto-assign/identity-work-qa/identity-work-qa.component';
import { IdentityWorkDataEntryQaComponent } from './auto-assign/identity-work-data-entry-qa/identity-work-data-entry-qa.component';
import { IdentityWorkManualValidationComponent } from './auto-assign/identity-work-manual-validation/identity-work-manual-validation.component';
import { ClientApiListComponent } from './client-api/client-api-list/client-api-list.component';
import { FaceRecognitionLogListComponent } from './face-recognition/face-recognition-log-list/face-recognition-log-list.component';
import { FormInviteListComponent } from './invite/invite-list/form-invite-list.component';
import { InviteUploadComponent } from './invite/invite-upload/invite-upload.component';
import { LotDeletedComponent } from './lot/lot-deleted/lot-deleted.component';
import { ClientFormsDeletedComponent } from './client-forms/client-forms-deleted/client-forms-deleted.component';
import { OcrExcelImportComponent } from './ocr/ocr-excel-import/ocr-excel-import.component';
import { FaceRecognitionExcelImportComponent } from './face-recognition/face-recognition-excel-import/face-recognition-excel-import.component';
import { ApiRequestLogsListComponent } from './api-request-logs/api-request-logs-list/api-request-logs-list/api-request-logs-list.component';
import { VerificationStatusListComponent } from './verification-status/verification-status-list/verification-status-list.component';
import { EmployeeDashboardComponent } from './employee/employee-dashboard/employee-dashboard.component';
import { VcourtUpdateCasesComponent } from './vcourt/vcourt-update-cases/vcourt-update-cases.component';
import { IdentityWorkVerificationComponent } from './auto-assign/identity-work-verification/identity-work-verification.component';
import { CompanyMetafieldsComponent } from './company/company-metafields/company-metafields.component';
import { LotHistoryComponent } from './lot/lot-history/lot-history.component';
import { ClientFormsHistoryComponent } from './client-forms/client-forms-history/client-forms-history.component';

import { RoleListComponent } from './company/role/role-list/role-list.component';
import { RoleDeletedComponent } from './company/role/role-deleted/role-deleted.component';
import { CompanyUpdateRoleComponent } from './company/company-update-role/company-update-role.component';
import { EpfoListComponent } from '../app/epfo/epfo-list/epfo-list.component';
import { EpfoExcelImportComponent } from './epfo/epfo-excel-import/epfo-excel-import.component';
import { PccLetterheadComponent } from './pcc/pcc-letterhead/pcc-letterhead.component';
import { PccChallanComponent } from './pcc/pcc-challan/pcc-challan.component';
import { CandidateDocumentsPageComponent } from './employee/candidate-documents-page/candidate-documents-page.component';
import { DownloadListComponent } from './download/download-list/download-list.component';
import { GlobalDbLogListComponent } from './global-db-log/global-db-log-list/global-db-log-list.component';
import { GlobalDbLogExcelImportComponent } from './global-db-log/global-db-log-excel-import/global-db-log-excel-import.component';
import { DigitalAddressListComponent } from './digital-address/digital-address-list/digital-address-list.component';
import { CompanyClientsListComponent } from './clients/company-clients-list/company-clients-list.component';
import { AllClientsListComponent } from './clients/all-clients-list/all-clients-list.component';
import { UserUploadComponent } from './user/user-upload/user-upload.component';
import { UserForgetPasswordComponent } from './user/user-forget-password/user-forget-password.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { CreditReportListComponent } from './credit-report/credit-report-list/credit-report-list.component';
import { CreditReportExcelImportComponent } from './credit-report/credit-report-excel-import/credit-report-excel-import.component';
import { CompanyHistoryComponent } from './company/company-history/company-history.component';
import { ClientHistoryComponent } from './clients/client-history/client-history.component';
import { UserHistoryComponent } from './user/user-history/user-history.component';
import { PermissionGuard } from './core/services/permission-guard.service';
import { VcourtCnrDetailsComponent } from './vcourt/vcourt-cnr-details/vcourt-cnr-details.component';




const routes: Routes = [
    { path: 'login', component: LoginComponent, canActivate: [ NoAuthGuard ] },
    { path: '', component: CompanyListComponent, canActivate: [ AuthGuard ] },
    { path: 'home', component: CompanyListComponent, canActivate: [ AuthGuard ] },
    { path: 'search', component: SearchListComponent, canActivate: [ AuthGuard ] },
    { path: 'companies', component: CompanyListComponent, canActivate: [ AuthGuard ] },
    { path: 'companies/:id/excel', component: CompanyExcelComponent, canActivate: [ AuthGuard ] },
    { path: 'clients', component: AllClientsListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'clients/:id/edit', component: ClientEditComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'clients/create', component: ClientCreateComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'clients/upload', component: ClientUploadComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'companies/create', component: CompanyCreateComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'companies/:id/edit', component: CompanyEditComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'companies/:id/clients', component: CompanyClientsListComponent, canActivate: [ AuthGuard ] },
    { path: 'companies/:id', component: CompanyShowComponent, canActivate: [ AuthGuard ] },
    { path: 'companies/:id/metafields', component: CompanyMetafieldsComponent, canActivate: [ AuthGuard ] },
    { path: 'companies/:id/lots/deleted', component: LotDeletedComponent, canActivate: [ AuthGuard ] },
    { path: 'employees', component: EmployeeDashboardComponent, canActivate: [ AuthGuard ] },
    { path: 'employees/create', component: EmployeeCreateComponent, canActivate: [ AuthGuard ] },
    { path: 'employees/:id/edit', component: EmployeeEditComponent, canActivate: [ AuthGuard ] },
    { path: 'employees/bulk-update', component: EmployeeBulkUpdateComponent, canActivate: [ AuthGuard ] },
    { path: 'employees/:id', component: EmployeeEditComponent, canActivate: [ AuthGuard ] },
    { path: 'employees/:id/documents', component: CandidateDocumentsPageComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/create', component: LotCreateComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/:id/merge', component: LotMergeComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/:id/export-excel', component: EmployeeListComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/:id/import', component: EmployeeImportComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/:id/uber', component: UberImportComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/:id/edit', component: LotEditComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/:id/history', component: LotHistoryComponent, canActivate: [ AuthGuard ] },
    { path: 'forms/:id/history', component: ClientFormsHistoryComponent, canActivate: [ AuthGuard ] },
    { path: 'lots/:id', component: LotShowComponent, canActivate: [ AuthGuard ] },
    { path: 'lots', component: LotListComponent, canActivate: [ AuthGuard ] },
    { path: 'vcourt/job/create', component: VcourtJobCreateComponent, canActivate: [ AuthGuard ] },
    { path: 'vcourt/update-cases', component: VcourtUpdateCasesComponent, canActivate: [ AuthGuard ] },
    { path: 'vcourt/fetch-cnr', component: VcourtCnrDetailsComponent, canActivate: [ AuthGuard ] },
    { path: 'vcourt/:id', component: VcourtJobShowComponent, canActivate: [ AuthGuard ] },
    { path: 'vcourt', component: VcourtJobListComponent, canActivate: [ AuthGuard ] },
    { path: 'icr', component: IcrListComponent, canActivate: [ AuthGuard ] },
    { path: 'icr/submit-to-vcourt', component: SubmitToVcourtComponent, canActivate: [ AuthGuard ] },
    { path: 'icr/:id/edit', component: IcrEditComponent, canActivate: [ AuthGuard ] },
    { path: 'icr/create/act', component: IcrCreateActTypeComponent, canActivate: [ AuthGuard ] },
    { path: 'icr/assigned-work', component: IcrAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'identity', component: IdentityListComponent, canActivate: [ AuthGuard ] },
    { path: 'identity/submit-to-icr', component: SubmitToIcrComponent, canActivate: [ AuthGuard ] },
    { path: 'identity/:id/edit', component: IdentityEditComponent, canActivate: [ AuthGuard ] },
    { path: 'identity/assigned-work', component: IdentityAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'users/history/:id', component: WorkHistoryComponent, canActivate: [ AuthGuard ] },
    { path: 'users/my-work', component: WorkListComponent, canActivate: [ AuthGuard ] },
    { path: 'document', component: DocumentListComponent, canActivate: [ AuthGuard ] },
    { path: 'document/:id/edit', component: DocumentEditComponent, canActivate: [ AuthGuard ] },
    { path: 'document/assigned-work', component: DocumentAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'employment', component: EmploymentListComponent, canActivate: [ AuthGuard ] },
    { path: 'employment/:id/edit', component: EmploymentEditPageComponent, canActivate: [ AuthGuard ] },
    { path: 'employment/assigned-work', component: EmploymentAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'employment/companies', component: EmploymentListCompanyComponent, canActivate: [ AuthGuard ] },
    { path: 'education', component: EducationListComponent, canActivate: [ AuthGuard ] },
    { path: 'education/:id/edit', component: EducationEditComponent, canActivate: [ AuthGuard ] },
    { path: 'education/assigned-work', component: EducationAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'global-db', component: GlobalDbListComponent, canActivate: [ AuthGuard ] },
    { path: 'global-db/:id/edit', component: GlobalDbEditComponent, canActivate: [ AuthGuard ] },
    { path: 'address', component: AddressListComponent, canActivate: [ AuthGuard ] },
    { path: 'address/:id/edit', component: AddressEditComponent, canActivate: [ AuthGuard ] },
    { path: 'address/assigned-work', component: AddressAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'pcc', component: PccListComponent, canActivate: [ AuthGuard ] },
    { path: 'pcc/:id/edit', component: PccEditComponent, canActivate: [ AuthGuard ] },
    { path: 'pcc/import', component: PccImportExcelComponent, canActivate: [ AuthGuard ] },
    { path: 'pcc/assigned-work', component: PccAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'reference', component: ReferenceListComponent, canActivate: [ AuthGuard ] },
    { path: 'reference/import', component: ReferenceImportComponent, canActivate: [ AuthGuard ] },
    { path: 'reference/:id/edit', component: ReferenceEditComponent, canActivate: [ AuthGuard ] },
    { path: 'reference/assigned-work', component: ReferenceAssignedWorkListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'forms', component: ClientFormsListComponent, canActivate: [ AuthGuard ] },
    { path: 'forms/deleted', component: ClientFormsDeletedComponent, canActivate: [ AuthGuard ] },
    { path: 'forms/:id/edit', component: ClientFormsEditComponent, canActivate: [ AuthGuard ] },
    { path: 'forms/create', component: ClientFormsCreateComponent, canActivate: [ AuthGuard ] },
    { path: 'forms/deleted', component: ClientFormsDeletedComponent, canActivate: [ AuthGuard ] },
    { path: 'identity/authorities', component: AuthorityListComponent, canActivate: [ AuthGuard ] },
    { path: 'education/institutes', component: EducationInstitutesListComponent, canActivate: [ AuthGuard ] },
    { path: 'users', component: UserListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'vehiclerc', component: VehicleListComponent, canActivate: [ AuthGuard, PermissionGuard ], data: { permissionAllow: 'vehicle,view' } },
    { path: 'vehiclerc/import', component: VehicleExcelImportComponent, canActivate: [ AuthGuard ] },
    { path: 'webhooks', component: WebhookListComponent, canActivate: [ AuthGuard ] },
    { path: 'webhooks/:id', component: WebhookLogsListComponent, canActivate: [ AuthGuard ] },
    { path: 'clients/api', component: ClientApiListComponent, canActivate: [ AuthGuard ] },
    { path: 'sms', component: SmsListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'licence/import', component: LicenceExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'licence', component: LicenceListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'aadhaar', component: AadhaarLogsComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'aadhaar/import', component: AadhaarExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'statuses/create', component: StatusCreateComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'client-remarks/create', component: VerificationClientRemarkCreateComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'vendors/create', component: VendorCreateComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'districts/create', component: DistrictCreateComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'pan', component: PanListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'pan/import-excel', component: PanExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'pending-jobs', component: PendingJobsComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'criminal', component: CriminalSearchComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'criminal/import', component: CriminalExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'employment/analysis', component: EmploymentAnalysisComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'vehiclerc/stolen/import', component: StolenVehicleExcelSearchComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'vehiclerc/unclaimed/import', component: UnclaimedVehicleExcelSearchComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'criminal/excel-search', component: CriminalExcelSearchComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'voter', component: VoterListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'voter/import', component: VoterExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'uidai', component: UidaiLogsComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'uidai/import', component: UidaiExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },

    { path: 'companies/client-api-credentials', component: ClientApiCredentialsComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'work/work-type', component: WorkTypeListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    {
        path: 'gst',
        component: GstListComponent,
        canActivate: [ AuthGuard, AdminGuard ],
    },
    { path: 'gst/import', component: GstImportExcelComponent, canActivate: [ AuthGuard, AdminGuard ] },
    {
        path: 'employee/additional-detail/import',
        component: EmployeeAdditionalDetailsImportComponent,
        canActivate: [ AuthGuard ],
    },
    {
        path: 'bulk-upload-files',
        component: BulkUploadFilesComponent,
        canActivate: [ AuthGuard ],
    },
    {
        path: 'api-request-logs/stats',
        component: ApiRequestLogsComponent,
        canActivate: [ AuthGuard, AdminGuard ],
    },
    {
        path: 'api-request-logs', component:
            ApiRequestLogsListComponent, canActivate:
            [ AuthGuard, AdminGuard ],
    },
    {
        path: 'email-logs', component:
            EmailLogsListComponent, canActivate:
            [ AuthGuard ],
    },
    { path: 'permit', component: PermitListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'permit/import', component: PermitExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },

    { path: 'insurance', component: InsuranceListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'insurance/import', component: InsuranceExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    {
        path: 'employees/:id/history',
        component: HistoryShowComponent,
        canActivate: [ AuthGuard ],
    },
    { path: 'remarks', component: RemarksListComponent, canActivate: [ AuthGuard, AdminGuard ] },

    { path: 'work/bulk-assign', component: WorkBulkAssignmentComponent, canActivate: [ AuthGuard ] },

    { path: 'history', component: HistoryListComponent, canActivate: [ AuthGuard ] },
    { path: 'incoming-webhooks', component: IncomingWebhookLogsListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'blacklisted', component: BlacklistedListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'blacklisted/import', component: BlacklistedExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },

    { path: 'face-recognition', component: FaceRecognitionLogListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'face-recognition/import', component: FaceRecognitionExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },

    { path: 'ocr', component: OcrListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'ocr/import', component: OcrExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'form-invites', component: FormInviteListComponent, canActivate: [ AuthGuard ] },
    { path: 'form-invites/upload', component: InviteUploadComponent, canActivate: [ AuthGuard ] },
    { path: 'statuses', component: VerificationStatusListComponent, canActivate: [ AuthGuard, AdminGuard ] },

    { path: 'work/:verification', component: AutoAssignDashboardComponent, canActivate: [ AuthGuard ] },
    { path: 'work/:verification/:id', component: AutoAssignShowWorkComponent, canActivate: [ AuthGuard ] },

    { path: 'work/identity/cropping/:id', component: IdentityWorkCroppingComponent, canActivate: [ AuthGuard ] },
    { path: 'work/identity/data-entry/:id', component: IdentityWorkDataEntryComponent, canActivate: [ AuthGuard ] },
    { path: 'work/identity/verification/:id', component: IdentityWorkVerificationComponent, canActivate: [ AuthGuard ] },
    { path: 'work/identity/validation/:id', component: IdentityWorkValidationComponent, canActivate: [ AuthGuard ] },
    { path: 'work/identity/quality-check/:id', component: IdentityWorkQaComponent, canActivate: [ AuthGuard ] },
    { path: 'work/identity/data-entry-qa/:id', component: IdentityWorkDataEntryQaComponent, canActivate: [ AuthGuard ] },
    { path: 'work/identity/manual-validation/:id', component: IdentityWorkManualValidationComponent, canActivate: [ AuthGuard ] },

    { path: 'company-roles/list', component: RoleListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'company-roles/deleted/list', component: RoleDeletedComponent, canActivate: [ AuthGuard, AdminGuard ] },

    { path: 'companies/:id/update-role', component: CompanyUpdateRoleComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'epfo', component: EpfoListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'epfo/import-excel', component: EpfoExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'global-db-log', component: GlobalDbLogListComponent, canActivate: [ AuthGuard ] },
    { path: 'global-db-log/import-excel', component: GlobalDbLogExcelImportComponent, canActivate: [ AuthGuard ] },
    { path: 'pcc/letterheads', component: PccLetterheadComponent, canActivate: [ AuthGuard ] },
    { path: 'pcc/challan', component: PccChallanComponent, canActivate: [ AuthGuard ] },

    { path: 'digital-address-invites', component: DigitalAddressListComponent, canActivate: [ AuthGuard ] },
    { path: 'users/downloads', component: DownloadListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'users/upload', component: UserUploadComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'users/change-password', component: ChangePasswordComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'forget-password', component: UserForgetPasswordComponent, canActivate: [ NoAuthGuard ] },
    { path: 'reset-password/:token', component: UserResetPasswordComponent, canActivate: [ NoAuthGuard ] },
    { path: 'credit-report', component: CreditReportListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'credit-report/import-excel', component: CreditReportExcelImportComponent, canActivate: [ AuthGuard, AdminGuard ] },
    {
        path: 'bank-account',
        canActivate: [ AuthGuard, AdminGuard ],
        loadChildren: () => import( './bank-account/bank-account.module' ).then( module => module.BankAccountModule )
    },
    { path: 'companies/:id/history', component: CompanyHistoryComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'clients/:id/history', component: ClientHistoryComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'users/:id/history', component: UserHistoryComponent, canActivate: [ AuthGuard, AdminGuard ] }

];


@NgModule( {
    imports: [

        RouterModule.forRoot(
            routes,
            { useHash: true }
            // { enableTracing: true } // <-- debugging purposes only
        ),
    ],
    exports: [
        RouterModule,
    ],
} )
export class AppRoutingModule {
}
