export class IdentityVerification {
    id!: number;
    employee_id!: number;
    employee_name!: string;
    name!: string;
    father_name!: string;
    dob!: string;
    number!: string;
    type!: string;
    address!: string;
    lot_name!: string;
    company_name!: string;
    lot_id!: number;
    company_id!: number;
    state_id!: number;
    state_name!: string;
    district_id!: number;
    district_name!: string;
    country_id!: number;
    country_name!: string;
    pincode!: number;
    status_name!: string;
    status_id!: string;
    color!: string;
    front_url!: string;
    back_url!: string;
    proof_url!: string;
    is_checked!: boolean;
    created_at!: string;
    updated_at!: string;
    completed_at!: string;
    type_name!: string;
    file_number!: string;
    registered_authority_name!: string;
    registered_authority_id!: number;
    work_assigned!: any[];
    vendor_id!: number;
    vendor_name!: string;
    sent_to_vendor_on!: string;
    insuff_raised_at!: string;
    insuff_cleared_at!: string;
    client_remarks!: string;
    type_id!: number;
    type_of_doc!: number;

    // TODO: Add a key-map for this in common service and replace its usage
    static typeToString ( type: number ): string {

        switch ( type ) {

            case IdentityTypeId.NoDocument: return IdentityTypeName.NoDocument;

            case IdentityTypeId.VoterId: return IdentityTypeName.VoterId

            case IdentityTypeId.PanCard: return IdentityTypeName.PanCard;

            case IdentityTypeId.AadhaarCard: return IdentityTypeName.AadhaarCard

            case IdentityTypeId.DrivingLicence: return IdentityTypeName.DrivingLicence;

            case IdentityTypeId.Passport: return IdentityTypeName.Passport;

            case IdentityTypeId.NationalIdentity: return IdentityTypeName.NationalIdentity;

            default: return IdentityTypeName.UnknownDocument;
        }
    }
}

export enum IdentityTypeId {
    NoDocument = 0,
    VoterId = 1,
    PanCard = 2,
    AadhaarCard = 3,
    DrivingLicence = 4,
    Passport = 5,
    NationalIdentity = 6
}

export enum IdentityTypeName {
    NoDocument = 'No Document',
    VoterId = 'Voter ID Card',
    PanCard = 'PAN Card',
    AadhaarCard = 'Aadhaar Card',
    DrivingLicence = 'Driving Licence',
    Passport = 'Passport',
    NationalIdentity = 'National Identity',
    UnknownDocument = 'Unknown Document'
}
