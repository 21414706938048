import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { validateDate } from '../../common/validators/date-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { IdentityTypeId, IdentityVerification } from '../../core/models/identity-verification.model';
import { IdentityService } from '../../core/services/identity.service';
import { Employee } from 'src/app/core/models/employee.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { State } from 'src/app/core/models/state.model';
import { District } from 'src/app/core/models/district.model';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { Country } from 'src/app/core/models/country.model';


@Component( {
    selector: 'app-identity-edit',
    templateUrl: './identity-edit.component.html',
} )
export class IdentityEditComponent extends VerificationEditComponent implements OnInit, OnChanges {

    override verification: string = 'identity';

    @Input() showNavigation: boolean = true;
    @Input() showAutoButtons: boolean = false;

    @Output() exit: EventEmitter<any> = new EventEmitter();
    @Output() done: EventEmitter<any> = new EventEmitter();

    verifications: IdentityVerification[] = [];
    doc_number_error_message: string = 'Required';
    statusChanged: boolean = false;
    override attachments: any[] = [];
    vendors!: any[];

    // List of statuses for which validations is required on completion Date.
    validStatusesForCompletionDate: number[] = [ 1, 2, 3, 6, 7, 8, 9, 11, 12, 13, 14, 16, 17, 18, 19, 23, 24 ];

    private isVisible: boolean = false;


    constructor (
        public override verificationService: IdentityService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override common: CommonService,
        public global: GlobalService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected override confirmationModalService: ConfirmationModalService
    ) {
        // TODO: Implement Shortcuts
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );
        // Initialise the identity verification form
        // with the required form controls
        this.initForm();

    }

    get states (): State[] { return this.global.fetchStates(); }

    get districts (): District[] { return this.global.fetchDistricts(); }

    get countries (): Country[] { return this.global.fetchCountries(); }

    get identityTypes () { return this.global.fetchIdentityDocumentTypes() };

    get statuses () { return this.global.fetchStatuses(); }

    get verificationClientRemarks () { return this.global.fetchVerificationClientRemarks(); }

    get authorities () { return this.global.fetchAuthorities(); }

    initForm (): void {
        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            name: [ null ],
            client_employee_id: [ null ],
            mobile_number: [ null ],
            father_name: [ null ],
            dob: [ null ],
            remarks: [ null ],
            id: [ null ],
            gender: [ null ],
            formArray: this.fb.array( [] ),
        } );
    }


    override ngOnInit (): void {

        this.verification = 'identity';

        this.common.populateAuthorities( 'licence' );

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                employee => {
                    this.employee = employee;
                    this.populateForm( employee );
                    this.isSubmitting = false;
                    this.getAttachments( employee );
                    this.ngOnChanges();
                }
            );
        } );
        // Subscribe to changes in the form.
        this.subscribeToQueryChanges();

        this.common.getVendors( 'identity' ).subscribe(
            response => {
                this.vendors = response;
            }
        );

        this.ngOnChanges();

    }

    populateForm ( employee: Employee ): void {

        this.verificationFormGroup.patchValue( employee );

        const verifications: any = employee.identity_verifications as IdentityVerification[];

        const verificationFormGroup: FormGroup[] = verifications.map( ( verification: any ) => this.fb.group( verification ) );

        const verificationGroupArray: FormArray = this.fb.array( verificationFormGroup );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );
    }


    ngOnChanges (): void {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );

            this.applyDefaultValidations( form );

            const stateId = form.get( 'state_id' )?.value;
            if ( stateId !== null ) {
                this.applyValidationsOnDistrct( stateId, form );
            }

            // Subscribe to any Change in Verification Status
            form.get( 'status_id' )?.valueChanges.subscribe(
                ( statusId: number ) => {
                    if ( this.validStatusesForCompletionDate.indexOf( statusId ) != -1 ) { this.statusChanged = true; }
                    this.applyValidationsForStatusChange( statusId, form );
                }
            );

            // Subscribe to any change in Doc Type
            form.get( 'type_id' )?.valueChanges.subscribe(
                ( type_id: any ) => {
                    this.applyValidationsForTypeChange( type_id, form );
                }
            );

            form.get( 'state_id' )?.valueChanges.subscribe(
                ( state_id: number ) => {
                    form.get( 'district_id' )?.setValue( null );
                    this.applyValidationsOnDistrct( state_id, form );
                }
            );

            // Change max height when Proof Url is uploaded
            form.get( 'proof_url' )?.valueChanges.subscribe(
                ( proof_url: any ) => {
                    return this.getMaxHeight( form );
                }
            );

            // Change max height when front url is uploaded
            form.get( 'front_url' )?.valueChanges.subscribe(
                ( front_url: any ) => {

                    return this.getMaxHeight( form );
                }
            );

            // Change max height when back url is uploaded
            form.get( 'back_url' )?.valueChanges.subscribe(
                ( front_url: any ) => {

                    return this.getMaxHeight( form );
                }
            );

        } );
    }

    createIdentity () {
        this.verificationService.create( this.employee.id ).subscribe(
            response => {
                this.populateForm( response );
            }
        );

        this.common.notifications.success( 'Identity Verification added' );
    }


    /**
     * Get the max Height for image panel
     *
     * @param verification
     * @returns {any}
     */
    getMaxHeight ( verification: any ): string {

        // Set max height on page Load
        const proofUrl: string = verification.get( 'proof_url' ).value;
        const frontUrl: string = verification.get( 'front_url' ).value;
        const backUrl: string = verification.get( 'back_url' ).value;

        if ( this.employee.company_name === 'Testing' ) {
            if ( frontUrl && !backUrl || !frontUrl && backUrl ) {

                return '700px';
            } else {
                return '360px';
            }
        } else {
            if ( frontUrl && !proofUrl || !frontUrl && proofUrl ) {

                return '700px';
            } else {
                return '360px';
            }
        }

    }

    swapFrontAndBack ( verification: any ): void {

        const verificationId: number = verification.value.id;

        const data: any = {
            id: verificationId,
            item1: 'front_url',
            item2: 'back_url',
        };

        this.verificationService.swapFrontAndBack( data ).subscribe(
            ( response: any ) => {

                if ( response[ 'status' ] === 'success' ) {

                    this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                        employee => {
                            this.employee = employee;
                            this.getAttachments( employee );
                        },
                    );
                    verification.get( data[ 'item1' ] ).setValue( response[ data[ 'item1' ] ] );
                    verification.get( data[ 'item2' ] ).setValue( response[ data[ 'item2' ] ] );

                    this.common.notifications.success( 'Successful', response[ 'message' ] );
                } else {
                    this.common.notifications.error( 'Error Occurred', response[ 'message' ] );
                }

            },
            error => {
                this.common.notifications.error( 'Error Occurred', 'Unable to Swap Documents' );
            }
        );
    }

    onSwapFrontAndBackButtonClicked ( verification: any ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.swapFrontAndBack( verification ) );

    }

    imageSaved (): void {
        this.ngOnInit();
    }


    /**
     * Apply Validations when Status is changed
     *
     * @param statusId
     * @param {AbstractControl} form
     */
    protected applyValidationsForStatusChange ( statusId: any, form: AbstractControl ) {


        // List of statuses for which validations is required on type of doc.
        const validStatusesForType = [ 1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 13, 15, 16, 17, 20, 21, 22, 23, 24, 28 ];

        // List of statuses for which validations is required on client remarks.
        const validStatusesForClientRemarks = [ 2, 3, 5, 9, 11, 12, 14, 17, 18, 19, 21, 23, 24 ];

        // List of Statuses for which validations is required on sent to vendor on date.
        const validStatusesForSentToVendorOnDateAndVendorName = [ 28 ];

        const completedAt = form.get( 'completed_at' )?.value;

        // Check if changed status is in our list.
        if ( this.validStatusesForCompletionDate.indexOf( statusId ) != -1 ) {


            form.get( 'completed_at' )?.setValidators( [ Validators.required, validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );


            if ( this.statusChanged ) {
                form.get( 'completed_at' )?.setValue( new Date().toJSON().slice( 0, 10 ) );
            }

        } else {
            if ( this.statusChanged && completedAt == null ) {
                form.get( 'completed_at' )?.setValue( '' );
            }

            form.get( 'completed_at' )?.setValidators( [ validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );
        }

        if ( validStatusesForType.indexOf( statusId ) != -1 ) {

            form.get( 'type_id' )?.setValidators( [ Validators.required, validateLength( '1' ) ] )

        } else {

            form.get( 'type_id' )?.setValidators( [] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForClientRemarks.indexOf( statusId ) != -1 ) {

            if ( this.employee.company_name === 'NOtUber' ) {

                form.get( 'client_remark_name' )?.setValidators( [ Validators.required ] );
                form.get( 'client_remark_id' )?.setValidators( [ Validators.required ] );
            } else {

                form.get( 'client_remarks' )?.setValidators( [ Validators.required ] );
            }

        } else {

            form.get( 'client_remark_name' )?.setValidators( [] );
            form.get( 'client_remark_id' )?.setValidators( [] );
            form.get( 'client_remarks' )?.setValidators( [] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForSentToVendorOnDateAndVendorName.indexOf( statusId ) != -1 ) {

            form.get( 'sent_to_vendor_on' )?.setValidators( [ Validators.required, validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );
            form.get( 'vendor_id' )?.setValidators( [ Validators.required ] );

        } else {
            form.get( 'sent_to_vendor_on' )?.setValidators( [ validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );
            form.get( 'vendor_id' )?.setValidators( [] );
        }

        form.get( 'sent_to_vendor_on' )?.updateValueAndValidity();
        form.get( 'vendor_id' )?.updateValueAndValidity();
        form.get( 'completed_at' )?.updateValueAndValidity();
        form.get( 'type_id' )?.updateValueAndValidity();
        form.get( 'client_remarks' )?.updateValueAndValidity();
        form.get( 'client_remark_name' )?.updateValueAndValidity();
        form.get( 'client_remark_id' )?.updateValueAndValidity();
    }

    protected applyValidationsOnDistrct ( state_id: number, form: AbstractControl ) {
        if ( state_id !== null ) { form.get( 'district_id' )?.setValidators( [ Validators.required ] ); }
        else { form.get( 'district_id' )?.clearValidators(); }

        form.get( 'district_id' )?.updateValueAndValidity();
    }

    /**
     * Apply Validations when Doc Type is changed
     *
     * @param type_id
     * @param {AbstractControl} form
     */
    protected applyValidationsForTypeChange ( type_id: any, form: AbstractControl ) {

        // Pan Card Validation
        if ( type_id == '2' ) {
            form.get( 'number' )?.setValidators( [ Validators.required, Validators.pattern( '^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$' ) ] );
            this.doc_number_error_message = 'Incorrect Format';
        }
        // Aadhaar Card Validation
        else if ( type_id == '3' ) {

            form.get( 'number' )?.setValidators( [ Validators.required, validateLength( '12' ) ] );
            this.doc_number_error_message = 'Must be 12 digits long';

        } else if ( type_id == IdentityTypeId.NationalIdentity ) {

            form.get( 'number' )?.setValidators( [ Validators.required ] );
            this.doc_number_error_message = 'Required';

            form.get( 'pincode' )?.clearValidators();
            form.get( 'pincode' )?.updateValueAndValidity();

            form.get( 'district_id' )?.clearValidators();
            form.get( 'district_id' )?.updateValueAndValidity();

        }
        else if ( type_id != '0' ) {
            form.get( 'number' )?.setValidators( [ Validators.required ] );
            this.doc_number_error_message = 'Required';
        }
        else {
            form.get( 'number' )?.setValidators( [] );
            this.doc_number_error_message = '';
        }

        form.get( 'number' )?.updateValueAndValidity();
    }

    /**
     * Set Default Validations to be applied on Page Load
     *
     * @param {AbstractControl} form
     */
    private applyDefaultValidations ( form: AbstractControl ) {

        form.get( 'name' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
        form.get( 'name' )?.updateValueAndValidity();
        form.get( 'father_name' )?.setValidators( [ validateAllowedCharacters( '()/' ) ] );
        form.get( 'father_name' )?.updateValueAndValidity();
        form.get( 'dob' )?.setValidators( [ validateDate( 'dd-mm-yyyy,yyyy' ) ] );
        form.get( 'dob' )?.updateValueAndValidity();
        form.get( 'pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
        form.get( 'pincode' )?.updateValueAndValidity();
        form.get( 'client_remark_name' )?.updateValueAndValidity();

        form.get( 'country_id' )?.setValidators( [ Validators.required ] );
        form.get( 'country_id' )?.updateValueAndValidity();

        this.applyValidationsForStatusChange( form.get( 'status_id' )?.value, form );
        this.applyValidationsForTypeChange( form.get( 'type_id' )?.value, form );
    }

    shouldShowCountry ( verificationIndex: number ): boolean {

        const formGroup = this.formArray.at( verificationIndex ) as FormGroup;

        if ( formGroup.get( 'type_id' )?.value == IdentityTypeId.NationalIdentity ) return true;

        return false;
    }

    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.cdr.detectChanges();
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                () => {
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }

}
