<div bsModal #creditReportResponseModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4>{{source | uppercase}} response</h4>
                </div>
                <div class="panel-body">

                    <ng-container *ngIf="attachments.length > 0; else responseTemplate">
                        <h5> Links </h5>
                        <div>
                            <p *ngFor="let attachment of attachments ">
                                <a href="{{attachment.link}}" target="_blank" class="btn-link">{{attachment.name}}</a>
                            </p>
                        </div>
                    </ng-container>

                    <ng-template #responseTemplate>
                        <fieldset>
                            <pre>{{ response | json }}</pre>
                        </fieldset>
                    </ng-template>
                </div>

                <div class="modal-footer">
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
