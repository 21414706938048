<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Employment Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="employment"></app-verification-stats>
</ng-container>

<app-verification-filters verification="employment" [filterParams]="filterParams"
    filterBy="company,lot,verification_status,created_after,created_before,completion_date_from,completion_date_to,assigned_to,employment_company,qa_present,qa_required,qa_done,employee_id,track_id,dov_present,work_completed,consent_present,has_contact,email_sent_at,initiated_at,initiated_present,work_assignment">
</app-verification-filters>

<app-work-assign-modal #workAssignModal [verification]="'employment'" [checkedIds]="ids"
    (onWorkAssigment)="onModalUpdated()"></app-work-assign-modal>

<app-employment-edit-modal #editModal (onVerificationUpdated)="onModalUpdated()">
</app-employment-edit-modal>

<app-employment-revert-form-modal #revertForm></app-employment-revert-form-modal>

<div class="wrapper-md">
    <div class="row">
        <div class="col-md-12">
            <a [appCan]="'editor'" class="btn btn-primary btn-addon" (click)="exportExcel()"
                [appHide]="'employment,download.excel'">
                <i class="fa fa-edit"></i>
                Download Excel
            </a>
            <a class="btn btn-primary btn-addon" routerLink="/employment/analysis">
                Analysis
            </a>

            <a class="btn btn-primary btn-addon" routerLink="/employment/companies">
                All Companies
            </a>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="row wrapper">
            <div class="col-sm-8" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses['employment']"
                            (click)="onChangeStatusButtonClicked(status.id,status.title)">
                            <a class="dropdown-item">{{status.title}}</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa required <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa Done <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>
                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('employment')"
                    class="btn btn-danger btn-addon"><i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{user.name}}</a>
                        </li>
                    </ul>
                </div>

                <button (click)="onSendFollowUpMailButtonClicked()" class="btn btn-primary btn-addon">Send
                    Follow Up Mail
                </button>

                <button (click)="onSendconsentMailButtonClicked()" class="btn btn-primary btn-addon">
                    Send Consent Email
                </button>

            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)">
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:20%;">Documents</th>
                        <th style="width:30%;">Details</th>
                        <th style="width:20%;">Timeline</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let verification of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">
                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{verification.id}}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id,$event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <a class="btn-link text-main"
                                (click)="editModal.show(verification.employee_id,verification.id)">
                                <strong>{{verification.employee_id}}-{{verification.id}}</strong>
                            </a>

                            <br>


                            <a class="btn-link text-main" (click)="showEmployment(verification)">
                                <strong>{{verification.track_id}}</strong>
                            </a>

                            <br>


                            <a class="btn-link text-main"
                                (click)="showEmployment(verification)"><strong>{{verification.employee_name}}</strong></a>
                            <br>

                            <a class="btn-link" routerLink="/lots/{{verification.lot_id}}">
                                {{verification.lot_name}}
                            </a>
                            <br>
                            <a class="btn-link" routerLink="/companies/{{verification.company_id}}">
                                {{verification.company_name}}
                            </a>
                            <p class="text-muted">{{verification.created_at}}</p>

                        </td>

                        <td class="{{ verification.color }}">

                            <app-verification-status verification="employment" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at"
                                (onVerificationUpdated)="onModalUpdated()">
                            </app-verification-status>

                            <div *ngIf="verification.is_email_sent">
                                <strong>Email Sent Via Platform</strong>
                            </div>

                            <div class="row" *ngIf="attachments[i]['documents']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['documents']">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>

                            <div class="row" *ngIf="attachments[i]['screenshots']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['screenshots']">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>

                            <div class="row" *ngIf="attachments[i]['payment_urls']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['payment_urls']">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                            <div class="row" *ngIf="verification.proof_url">
                                <div class="col-md-12">
                                    <a target="_blank" href="{{verification.proof_url}}" class="btn-link">
                                        Proof_url
                                    </a>
                                </div>
                            </div>
                        </td>

                        <!-- files -->
                        <td>
                            <a *ngFor="let document of verification.documents; let i = index" class="btn-link"
                                href="{{document.full_path}}" target="_blank">
                                {{ i + 1 }} {{ document.name }} <br />
                            </a>
                            <a *ngFor="let attachment of verification.new_attachments; let i = index" class="btn-link"
                                href="{{attachment.full_path}}" target="_blank">
                                {{ i + 1 }} {{ attachment.name }} <br />
                            </a>
                            <a *ngIf="verification.proof_url" class="btn-link" href="{{verification.proof_url}}"
                                target="_blank">
                                proof_url
                            </a>
                        </td>

                        <td>

                            <ul class="list-unstyled">

                                <li><strong>{{verification.employment_company_name }}</strong></li>

                                <li>Has Contact: {{ verification.company_contacts_count? "Yes": "No" }}</li>


                                <li *ngIf="verification.designation">Designation: {{verification.designation}}</li>


                                <li *ngIf="verification.department">Department: {{verification.department}}</li>


                                <li *ngIf="verification.employment_type">Employment Type:
                                    {{verification.employment_type}}
                                </li>

                                <li *ngIf="verification.joining_date">Joining Date: {{verification.joining_date}}</li>

                                <li *ngIf="verification.end_date">Worked Till: {{verification.end_date}}</li>


                                <li *ngIf="verification.country_name">Country: {{verification.country_name}}</li>

                                <li *ngFor="let work of verification.work_assigned[0]">
                                    <strong>{{work.title}} is assigned to {{work.assigned_to}}</strong>
                                </li>

                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li *ngIf="verification.revert_form_id">
                                    <a class="btn-link text-main" (click)="revertForm.show(verification)">
                                        <strong>Revert Form</strong>
                                    </a>
                                </li>
                                <li *ngIf="verification.completed_at">Completion Date: {{ verification.completed_at}}
                                </li>
                                <li>TAT: {{ verification.tat }}</li>
                                <li *ngFor="let log of verification.email_logs; let iEmail = index;">
                                    {{ iEmail + 1 }} : {{ log.created_at | date : 'longDate' }}
                                </li>
                                <li *ngIf="verification.last_email_at">Last Email At: {{ verification.last_email_at }}
                                </li>
                            </ul>
                        </td>
                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id,i)">
                                Delete
                            </button>

                            <div class="row" *ngIf="attachments[i]['documents']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['documents']">
                                    <button
                                        (click)="onDeleteDocumentButtonClicked(verification.id,attachment['id'],'delete_document_'+attachment['id'])"
                                        class="btn btn-danger btn-xs" id="delete_document_{{attachment['id']}}">
                                        {{attachment['file_name']}}
                                    </button>
                                </div>
                            </div>
                            <div class="row" *ngIf="attachments[i]['screenshots']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['screenshots']">
                                    <button
                                        (click)="onDeleteAttachmentButtonClicked(verification.id,attachment['id'],'delete_screenshot_'+attachment['id'])"
                                        class="btn btn-danger btn-xs" id="delete_screenshot_{{attachment['id']}}">
                                        {{attachment['file_name']}}
                                    </button>
                                </div>
                            </div>
                            <div class="row" *ngIf="attachments[i]['payment_urls']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['payment_urls']">
                                    <button
                                        (click)="onDeleteAttachmentButtonClicked(verification.id,attachment['id'],'delete_payment_url_'+attachment['id'])"
                                        class="btn btn-danger btn-xs" id="delete_payment_url_{{attachment['id']}}">
                                        {{attachment['file_name']}}
                                    </button>
                                </div>
                            </div>
                            <div class="row" *ngIf="verification.proof_url">
                                <div class="col-md-12">
                                    <button
                                        (click)="onDeleteFileButtonClicked(verification.id,'proof_url','delete_proof_url_'+i)"
                                        class="btn btn-danger btn-xs" id="delete_proof_url_{{i}}">
                                        Delete Proof
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>