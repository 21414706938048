import { IdentityVerification } from './identity-verification.model';
import { IcrVerification } from './icr-verification.model';
import { EducationVerification } from './education-verification.model';
import { EmploymentVerification } from './employment-verification.model';
import { PccVerification } from './pcc-verification.model';
import { DocumentVerification } from './document-verification.model';
import { ReferenceVerification } from './reference-verification.model';
import { Remarks } from './remarks.model';
import { AddressVerification } from './address-verification.model';
import { GlobalDbVerification } from './global-db-verification.model';
import { EmployeeAdditionalDetails } from './employee-additional-details.model';
import { Document } from './document.model';
import { Location } from './location.model';
import { Client } from './client.model';
import { EmployeeSetting } from './employee-setting.model';
import { Country } from './country.model';
import { Company } from './company.model';

export class Employee {
    id!: number;
    /**
     * @deprecated
     */
    employee_id!: number;
    name!: string;
    lot_id!: string;
    lot_name!: string;
    company_id!: number;
    company_name!: string;
    track_id!: string;
    dob!: string;
    identity_verifications!: IdentityVerification[];
    address_verifications!: AddressVerification[];
    icr_verifications!: IcrVerification[];
    education_verifications!: EducationVerification[];
    employment_verifications!: EmploymentVerification[];
    pcc_verifications!: PccVerification[];
    document_verifications!: DocumentVerification[];
    reference_verifications!: ReferenceVerification[];
    global_db_verifications!: GlobalDbVerification[];
    created_at!: string;
    expected_joining_date!: string;
    mobile_number!: string;
    father_name!: string;
    client_employee_id!: string;
    age!: string;
    email!: string;
    completion_date!: string;
    verification_status!: string;
    interim_report_date!: string;
    remarks!: Remarks[];
    attachments!: any[];
    documents!: Document[];
    is_checked!: boolean;
    addition_details!: EmployeeAdditionalDetails;
    verifications!: any[];
    invite_response: any;
    gender!: string;
    metadata!: {};
    location!: Location;
    recruiter!: Client | any;
    resume_url!: string | null;
    official_email!: string;
    settings!: EmployeeSetting;
    country!: Country;
    company!: Company;
}

