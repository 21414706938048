import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreditReportService } from 'src/app/core/services/credit-report.service';

@Component( {
    selector: 'app-credit-report-stats',
    templateUrl: './credit-report-stats.component.html',
    styleUrls: [ './credit-report-stats.component.css' ]
} )
export class CreditReportStatsComponent implements OnInit {

    @Input() filterParams: HttpParams = new HttpParams();
    stats!: any[];

    constructor (
        private route: ActivatedRoute,
        private creditReportService: CreditReportService
    ) { }

    ngOnInit () {

        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1

        this.route.queryParams.subscribe( params => {

            let updatedParams = new HttpParams();

            Object.keys( params ).forEach( key => {

                if ( params[ key ] !== '' ) {
                    updatedParams = updatedParams.set( key, params[ key ] );
                }
            } );

            this.filterParams = updatedParams;

            this.filterStats();
        } );
    }

    filterStats (): void {
        if ( this.filterParams ) {
            this.creditReportService.getStats( this.filterParams ).subscribe(
                response => {
                    this.stats = response;
                }
            );
        }
    }

}
