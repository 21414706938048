import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root'
} )
export class AuthDataService {

    private userEmail: string | null = null;
    private showTwoFactorPage: boolean = false;

    setShowTwoFactorPage ( showTwoFactorPage: boolean ) {
        this.showTwoFactorPage = showTwoFactorPage;
    }

    getShowTwoFactorPage (): boolean {
        return this.showTwoFactorPage;
    }

    setUserEmail ( userEmail: string ) {
        this.userEmail = userEmail;
    }

    getUserEmail (): string | null {
        return this.userEmail;
    }

    resetData (): void {
        this.userEmail = null;
        this.showTwoFactorPage = false;
    }
}
