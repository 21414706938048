<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3"> Document Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="document"></app-verification-stats>
</ng-container>

<app-verification-filters verification="document" [filterParams]="filterParams"
    filterBy="company,lot,verification_status,type_of_document,created_after,created_before,completion_date_from,completion_date_to,assigned_to,proof_url,track_id,dov_present,work_completed,qa_required,qa_done,work_assignment">
</app-verification-filters>

<app-work-assign-modal #workAssignModal [verification]="'document'" [checkedIds]="ids"
    (onWorkAssigment)="onModalUpdated()"></app-work-assign-modal>

<app-document-auto-verify-modal #autoVerifyModal [filterParams]="filterParams" [verification]="'document'"
    [checkedIds]="ids"></app-document-auto-verify-modal>


<div class="row" style="padding: 0px 0px 0px 35px">
    <a [appCan]="'editor'" [appHide]="'document,download.excel'" class="btn btn-primary btn-addon"
        (click)="downloadNewExcel()">
        <i class="fa fa-download"></i>
        Download Excel
    </a>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="row wrapper">
            <div class="col-sm-8" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses['document']"
                            (click)="onChangeStatusButtonClicked(status.id,status.title)">
                            <a class="dropdown-item">{{status.title}}</a>
                        </li>
                    </ul>
                </div>

                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('document')"
                    class="btn btn-danger btn-addon"><i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{user.name}}</a>
                        </li>
                    </ul>
                </div>
                <a [appCan]="'superadmin'" class="btn btn-primary btn-addon" (click)="autoVerifyModal.show()">
                    Auto Verify
                </a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)">
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:10%;">Type</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:40%;">Details</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let verification of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">
                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{verification.id}}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id,$event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <a class="btn-link text-main text-semibold"
                                href="/#/document/{{verification.employee_id}}/edit" target="_blank">
                                {{ verification.employee_name }}
                            </a>
                            <br>
                            <a class="btn-link" (click)="showDocument(verification)">{{ verification.track_id }}</a>
                            <p>Created on: {{ verification.created_at }}</p>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>{{ verification.type_name }}</li>
                                <li>{{ verification.document_number }}</li>
                            </ul>
                        </td>
                        <td class="{{ verification.color }}">
                            <app-verification-status verification="document" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at">
                            </app-verification-status>
                            <div class="row">
                                <div class="col-md-12" *ngIf="verification.document_url">
                                    <a href="{{verification.document_url}}" class="btn-link">
                                        Document Url
                                    </a>
                                </div>

                                <div class="col-md-12" *ngIf="verification.proof_url">
                                    <a href="{{verification.proof_url}}" class="btn-link">
                                        Proof Url
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>Name: {{ verification.name }}</li>
                                <li>Date Of Issue: {{ verification.date_of_issue }}</li>
                                <li>Date Of Expiry: {{ verification.date_of_expiry }}</li>
                                <li>Issuing Authority: {{ verification.issuing_authority }}</li>
                                <li *ngFor="let work of verification.work_assigned[0]">
                                    <strong>{{work.title}} is assigned to {{work.assigned_to}}</strong>
                                </li>
                            </ul>
                        </td>
                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id,i)">
                                Delete
                            </button>

                            <div class="row">
                                <div class="col-md-12" *ngIf="verification.document_url">
                                    <button
                                        (click)="onDeleteFileButtonClicked(verification.id,'document_url','delete_document_url_'+i)"
                                        class="btn btn-danger btn-xs" id="delete_document_url_{{i}}">
                                        Delete Document Url
                                    </button>
                                </div>

                                <div *ngIf="attachments">
                                    <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                                        <div class="col-md-12">
                                            <button
                                                (click)="onDeleteAttachmentButtonClicked(verification.id,attachment['id'],'delete_attachment_'+attachment['id'])"
                                                class="btn btn-danger btn-xs"
                                                id="delete_attachment_{{attachment['id']}}">
                                                Delete {{attachment['file_name']}}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" *ngIf="verification.proof_url">
                                    <button (click)="deleteFile(verification.id,'proof_url','delete_proof_url_'+i)"
                                        class="btn btn-danger btn-xs" id="delete_proof_url_{{i}}">
                                        Delete Proof Url
                                    </button>
                                </div>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>