import { LotService } from './../../core/services/lot.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormV2 } from '../../core/models/client-forms.model';
import { Verification } from '../../core/models/verification.model';
import { ClientFormsService } from '../../core/services/client-forms.service';
import { CommonService } from '../../core/services/common.service';
import { VerificationService } from '../../core/services/verification.service';
import { ClientFormType } from 'src/app/core/enums/client-form-type.enum';
import { Lot } from 'src/app/core/models/lot.model';

@Component( {
    selector: 'app-client-forms-edit',
    templateUrl: './client-forms-edit.component.html',
    styleUrls: [ './client-forms-edit.component.css' ],
} )
export class ClientFormsEditComponent implements OnInit {

    form: FormV2 = new FormV2();
    editForm!: FormGroup;
    isSubmitting: boolean = false;
    verifications: Verification[] = [];
    fileTypes: any[] = [
        {
            id: 'image/*',
            name: 'Image',
        },
        {
            id: '.pdf',
            name: 'pdf',
        },
        {
            id: '.doc',
            name: 'doc',
        },
        {
            id: '.docx',
            name: 'docx',
        },
    ];

    get formType () {
        return this.editForm.get( 'type' ) as FormControl;
    }

    get consentForm () {
        return this.formSetting.get( 'consent' ) as FormGroup;
    }

    get basicForm () {
        return this.formSetting.get( 'basic' ) as FormGroup;
    }
    get formSetting (): FormGroup {
        return this.editForm.get( 'settings' ) as FormGroup;
    }

    get identityVerificationItem (): FormArray {
        return this.formSetting.get( 'identity.verifications' ) as FormArray;
    }

    get addressVerificationItem (): FormArray {
        return this.formSetting.get( 'address.verifications' ) as FormArray;
    }

    get educationVerificationItem (): FormArray {
        return this.formSetting.get( 'education.verifications' ) as FormArray;
    }

    get employmentVerificationItem (): FormArray {
        return this.formSetting.get( 'employment.verifications' ) as FormArray;
    }

    get icrVerificationItem (): FormArray {
        return this.formSetting.get( 'icr.verifications' ) as FormArray;
    }

    get globalVerificationItem (): FormArray {
        return this.formSetting.get( 'global_db.verifications' ) as FormArray;
    }

    get documentVerificationItem (): FormArray {
        return this.formSetting.get( 'document.verifications' ) as FormArray;
    }

    get referenceVerificationItem (): FormArray {
        return this.formSetting.get( 'reference.verifications' ) as FormArray;
    }

    get pccVerificationItem (): FormArray {
        return this.formSetting.get( 'pcc.verifications' ) as FormArray;
    }

    get fieldsArray (): FormArray {
        return this.editForm.get( 'fields' ) as FormArray;
    }

    get isAddressPresentInLot (): boolean {
        return this.verifications.find( verification => verification.shortTitle === 'current_address' || verification.shortTitle === 'permanent_address' ) ? true : false;
    }

    get isDigitalForm (): boolean {
        return this.form.type == ClientFormType.DigitalAddress
    }

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private formService: ClientFormsService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private verificationService: VerificationService,
        private lotService: LotService
    ) { }

    ngOnInit (): void {

        this.initForm();

        this.verificationService.getAllVerifications().then( verifications => this.verifications = verifications );

        const lotId = this.route.snapshot.paramMap.get( 'id' ) as unknown as number;

        this.formService.find( lotId ).subscribe( ( response: FormV2 ) => {

            this.form = response;
            this.filterVerificationsAsPerLot( response );
        } )

    }

    filterVerificationsAsPerLot ( response: FormV2 ): void {

        this.lotService.find( response.lot.lot_id ).subscribe( {

            next: ( res: Lot ) => {

                this.verifications = this.verifications.filter(
                    verification => {
                        return res.verifications.includes( verification.lotColumnName );
                    }
                )

                this.populateForm( response )
            }
        } );
    }

    initForm (): void {
        this.editForm = this.fb.group( {
            id: [],
            name: [ '', Validators.required ],
            status: [ '', Validators.required ],
            type: [ null, Validators.required ],
            email_subject: [ '' ],
            email_body: [ '' ],
            company_id: [ '', Validators.required ],
            lot_id: [ [], Validators.required ],
            fields: this.fb.array( [] ),
            settings: this.fb.group( {} ),
        } );
    }

    verificationsPatchValue (): void {

        this.basicForm.patchValue( this.form.settings.basic )

        this.form.settings.identity.verifications.forEach( verification => {
            this.identityVerificationItem.push( this.fb.group( verification ) );
        } );

        this.form.settings.education.verifications.forEach( verification => {
            this.educationVerificationItem.push( this.fb.group( verification ) );
        } );

        this.form.settings.employment.verifications.forEach( verification => {
            this.employmentVerificationItem.push( this.fb.group( verification ) );
        } );

        this.form.settings.icr.verifications.forEach( verification => {
            this.icrVerificationItem.push( this.fb.group( verification ) );
        } );

        this.form.settings.global_db.verifications.forEach( verification => {
            this.globalVerificationItem.push( this.fb.group( verification ) );
        } );

        this.form.settings.document.verifications.forEach( verification => {
            this.documentVerificationItem.push( this.fb.group( verification ) );
        } );

        this.form.settings.reference.verifications.forEach( verification => {
            this.referenceVerificationItem.push( this.fb.group( verification ) );
        } );

        this.form.settings.pcc.verifications.forEach( ( verification ) => {

            const identityArray = this.fb.array(
                verification.documents.map( ( idType ) => this.fb.group( idType ) )
            );

            const formGroup = this.fb.group( {
                address_type: verification.address_type,
                address_required: verification.address_required,
                documents: identityArray,
                required: verification.required
            } )

            this.pccVerificationItem.push( formGroup )
        } );

        this.patchAddressFormSettings();
    }

    patchAddressFormSettings () {

        if ( !this.isAddressPresentInLot ) return;

        this.form.settings.address.verifications.forEach( verification => {

            const areDocumentsPresent = verification.documents && verification.documents?.length > 0

            const documentsArray = areDocumentsPresent ? this.fb.array(

                verification.documents.map( ( idType ) => this.fb.group( idType ) )

            ) : this.fb.array( [] );

            const formGroup = this.fb.group( {
                type: verification.type,
                required: [ verification.required, [ ( this.isDigitalForm || verification.is_digital ) ? Validators.requiredTrue : Validators.required ] ],
                instructions: verification.instructions,
                is_digital: [ verification.is_digital || false, [ this.isDigitalForm ? Validators.requiredTrue : Validators.nullValidator ] ],
                documents: documentsArray
            } )

            this.addressVerificationItem.push( formGroup )
        } );

        if ( this.formType?.value == ClientFormType.DigitalAddress ) {

            this.addressVerificationItem?.addValidators( Validators.required )

        } else if ( this.formType?.value == ClientFormType.Invite ) {

            this.addressVerificationItem?.removeValidators( Validators.required )
        }

        this.addressVerificationItem?.updateValueAndValidity();
    }

    updateClient ( redirect: boolean = false ): void {

        this.isSubmitting = true;

        this.formService.update( this.editForm.value.id, this.editForm.value ).subscribe( {

            next: () => {
                this.commonService.notifications.success( 'Form Updated' );

                this.isSubmitting = false;

                if ( redirect ) {
                    this.router.navigate( [ '/forms' ] );
                }
            },

            error: () => {
                this.isSubmitting = false;
            }

        } );
    }

    populateForm ( response: any ): void {

        this.editForm.patchValue( this.form );
        this.editForm.get( 'company_id' )?.setValue( response.company.company_id );
        this.editForm.get( 'lot_id' )?.setValue( response.lot.lot_id );

        this.verificationsPatchValue();

        let fields: any = response.fields;

        const areFieldsFilled = response.fields && response.fields.length > 0

        if ( !areFieldsFilled ) {

            this.editForm.setControl( 'fields', this.fb.array( [] ) );

            return;
        }

        fields = fields.map( ( field: { type: string; key: any; label: any; required: any; placeholder: any; file_types: any; select_option: any; } ) => {

            if ( field.type === 'file' ) {
                return {
                    key: field.key,
                    label: field.label,
                    required: field.required,
                    placeholder: field.placeholder,
                    type: field.type,
                    file_types: [ field.file_types ],
                    select_option: field.select_option,
                };
            }

            return field;
        } );

        const fieldsFormGroup: FormGroup[] = fields.map( ( field: any ) => this.fb.group( field ) );

        const fieldsFormGroupArray: FormArray = this.fb.array( fieldsFormGroup );

        this.editForm.setControl( 'fields', fieldsFormGroupArray );
    }

    onCheckboxChange ( event: any, index: number, verificationData: any ): void {

        const list: any = this.editForm.value.verificationList;

        if ( event.target.checked === true ) {
            verificationData.index = index;
            list.push( verificationData );
        } else {
            if ( list.length > 1 ) {
                for ( let i: number = 0; i < list.length; i++ ) {
                    if ( list[ i ].index === index ) {
                        list.splice( i, true );
                        break;
                    }
                }
            } else {
                list.splice( 0, true );
            }

        }
        this.editForm.value.verificationList = list;

    }

    onFormTypeChanged () {

        this.addressVerificationItem.clear()

        // remove address verification required validation when it is Invite Form

        if ( this.formType.value == ClientFormType.Invite ) {

            this.addressVerificationItem?.removeValidators( Validators.required );

            this.addressVerificationItem?.updateValueAndValidity();

            return;
        };

        this.fieldsArray.clear();
        this.identityVerificationItem.clear()
        this.educationVerificationItem.clear()
        this.employmentVerificationItem.clear()
        this.icrVerificationItem.clear()
        this.pccVerificationItem.clear()
        this.globalVerificationItem.clear()
        this.documentVerificationItem.clear()
        this.referenceVerificationItem.clear()

        // add address verification required validator when it is Digital Address Form

        this.addressVerificationItem?.addValidators( Validators.required )
        this.addressVerificationItem?.updateValueAndValidity();
    }
}
