import { EventEmitter, Injectable, Output } from '@angular/core';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { JwtService } from './jwt.service';

export interface LoginResponse {
    success: boolean;
    message: string;
}

export interface SubmitOtpResponse {
    success: boolean;
    message: string;
    token?: string;
    expiresIn?: number;
}


@Injectable( {
    providedIn: 'root'
} )
export class AuthService {

    public token!: string;

    @Output() userLoggedIn: EventEmitter<any> = new EventEmitter();

    constructor (
        private apiService: ApiService,
        private jwtService: JwtService
    ) { }

    login ( body: any ): Observable<LoginResponse> {
        return this.apiService.post( 'login', body );
    }

    submitOtp ( otp: string, email: string ): Observable<SubmitOtpResponse> {
        return this.apiService.post( 'submit-otp', { 'otp': otp, 'email': email } );
    }

    get user (): User | Partial<User> {
        return this.jwtService.getCurrentUser() ? this.jwtService.getCurrentUser() : undefined;
    }

    logout (): Observable<any> {
        return this.apiService.get( 'logout' );
    }

    forgetPassword ( body: any ): Observable<any> {
        return this.apiService.post( 'password/forgot', body );
    }

    resetPassword ( body: any ): Observable<any> {
        return this.apiService.post( 'password/reset', body );
    }
}
