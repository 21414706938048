import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GlobalDbVerification } from '../../core/models/global-db-verification.model';
import { CommonService } from '../../core/services/common.service';
import { GlobalDbService } from '../../core/services/global-db.service';
import { VerificationList } from 'src/app/core/components/verification-list.component';
import { GlobalService } from 'src/app/core/services/global.service';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { AttachmentCollection } from 'src/app/core/interfaces/attachment-collection';

@Component( {
    selector: 'app-global-db-list',
    templateUrl: './global-db-list.component.html',
    styleUrls: [ './global-db-list.component.css' ],
} )
export class GlobalDbListComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications!: GlobalDbVerification[];
    isSubmitting: boolean = false;
    statsVisible: boolean = false;
    attachments: AttachmentCollection[] = [];

    constructor (
        protected override verificationService: GlobalDbService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected modalService: BsModalService,
        protected override viewContainerRef: ViewContainerRef,
        public override confirmationModalService: ConfirmationModalService

    ) {
        super( confirmationModalService );

    }

    get users () {
        return this.global.fetchUsers();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {
        this.verification = 'globaldb';
        this.subscribeToQueryChanges();
        this.getVendorsList();
    }

    showGlobalDb ( globalDb: GlobalDbVerification ): void {
        this.router.navigate( [ 'global-db', globalDb.employee_id, 'edit' ], this.navigationExtras );
    }

    filterResults (): void {
        this.verificationService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    populateExtraDetails ( globalDbVerification: GlobalDbVerification[] ): void {

        for ( const index in globalDbVerification ) {

            const verification: GlobalDbVerification = globalDbVerification[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    toggleStatsVisibility (): void {
        this.statsVisible = !this.statsVisible;
    }

    downloadNewExcel () {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/globaldb/downloadExcel',
            fileName: 'Global Db Verification',
            method: 'get',
            postParams: null,
        };

        this.verificationService.showDownloadModal( initialState );
    }

}
