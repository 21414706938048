import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';


import { UserService } from './user.service';

@Injectable( {
    providedIn: 'root'
} )
export class PermissionGuard implements CanActivate {

    constructor (
        private router: Router,
        private userService: UserService,
    ) { }

    canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {

        if ( this.userService.isSuperAdmin ) return true;

        if ( this.userService.hasPermission( route.data[ 'permissionAllow' ] ) ) return true;

        this.router.navigate( [ '/home' ] );

        return false;
    }
}
