<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Users</h1>
</div>
<app-user-filters />
<div class="wrapper-md" [appCan]="'superadmin'">
    <div class="row">
        <div class="col-lg-6">
            <a class="btn btn-primary btn-addon" (click)="userCreateModal.show()">
                <i class="fa fa-plus"></i>Create User
            </a>
            <a class="btn btn-primary btn-addon" (click)="exportExcel()">
                <i class="fa fa-download"></i>
                Download Excel
            </a>
            <a routerLink="/users/upload" class="btn btn-primary btn-space">
                <i class="fa fa-upload"></i>
                Upload Bulk Users
            </a>
        </div>
        <div class="col-lg-3">
            <a class="btn m-b-xs btn-sm btn-danger" (click)="logoutAll()">
                Logout All users</a>
        </div>

        <div class="col-lg-3">
            <a class="btn m-b-xs btn-sm btn-danger" (click)="resetAll()">
                Reset All passwords</a>
        </div>
    </div>
</div>

<app-user-create #userCreateModal (onUserCreated)="onModalUpdated()"></app-user-create>

<app-user-edit #userEditModal (onUserEdited)="onModalUpdated()"></app-user-edit>

<app-show-permission #userPermissionsModal></app-show-permission>
<app-edit-permission #editPermissionsModal (onPermissionUpdated)="onModalUpdated()"></app-edit-permission>

<app-user-change-password #userResetPasswordModal (onUserEdited)="onModalUpdated()"></app-user-change-password>
<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} lots out of total {{ total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">

            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Work</th>
                        <th>Details</th>
                        <th>Permissions</th>
                        <th [appCan]="'superadmin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let user of pagination.data |paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">
                        <td>

                            <strong>{{ user.name }}</strong>
                            <div>
                                <a [appCan]="'superadmin'" class="btn-link" (click)="userEditModal.show(user)">Edit</a>
                            </div>

                            <div>
                                <a [appCan]="'superadmin'  || 'admin' " class="btn-link"
                                    routerLink="/users/{{user.id}}/history"> History</a>
                            </div>

                        </td>
                        <td>
                            <a [appCan]="'superadmin'  || 'admin' " class="btn-link"
                                routerLink="/users/history/{{user.id}}">Work History</a>


                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    Email: {{user.email}}
                                </li>
                                <li>
                                    Level: {{user.level_name}}
                                </li>
                                <li>
                                    Designation: {{user.designation}}
                                </li>
                                <li>
                                    Mobile Number: {{user.mobile_number}}
                                </li>
                                <li>
                                    Birthday: {{user.dob}}
                                </li>
                                <li>
                                    Last Login At: {{user.last_login_at}}
                                </li>
                                <li>
                                    Last Login IP: {{user.ip}}
                                </li>
                            </ul>
                        </td>
                        <td>

                            <button class="btn btn-success btn-xs" (click)="userPermissionsModal.show(user.id)">
                                <i class="fa fa-list"></i> Show</button>
                            <br>
                            <br>
                            <button [appCan]="'superadmin'" class="btn btn-success btn-xs"
                                (click)="editPermissionsModal.show(user.id)"><i class="fa fa-edit"></i> Edit</button>
                        </td>
                        <td [appCan]="'superadmin'">
                            <button [ngClass]="user.active ? 'btn btn-danger btn-xs' : 'btn btn-success btn-xs' "
                                (click)="toggleStatus(user)">

                                {{ getActivationStatusLabel(user)}}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
