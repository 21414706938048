import { Component, ElementRef, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { DocumentVerification } from '../../core/models/document-verification.model';
import { DocumentService } from '../../core/services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';

import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { map } from 'rxjs/operators';
import { validateNumericCharacters } from 'src/app/common/validators/numeric-validator.directive';
import { Employee } from 'src/app/core/models/employee.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { environment } from 'src/environments/environment';
import { State } from 'src/app/core/models/state.model';
import { District } from 'src/app/core/models/district.model';
import Status from 'src/app/core/models/status.model';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { DocumentVerificationTypes } from 'src/app/core/enums/document-verification-types.enum';

@Component( {
    selector: 'app-document-edit',
    templateUrl: './document-edit.component.html',
    styleUrls: [ './document-edit.component.css' ],
} )
export class DocumentEditComponent extends VerificationEditComponent implements OnInit, OnChanges {

    verifications: DocumentVerification[] = [];
    statusChanged: boolean = false;

    override maxFileSize: number = 5 * 1024; // 5mb;
    override maxFileError = false;

    fields = new Map();

    override verification: string = 'Document';

    constructor (
        protected override verificationService: DocumentService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override common: CommonService,
        public global: GlobalService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected override confirmationModalService: ConfirmationModalService
    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );

        // Initialise the identity verification form
        // with the required form controls
        this.initForm();

        this.populateDocumentTypeFieldMap();
    }

    get states (): State[] { return this.global.fetchStates() }

    get districts (): District[] { return this.global.fetchDistricts(); }

    get documentTypes () { return this.global.fetchDocumentTypes(); }

    get statuses () { return this.global.fetchStatuses(); }

    initForm (): void {

        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            name: [ null ],
            client_employee_id: [ null ],
            mobile_number: [ null ],
            father_name: [ null ],
            dob: [ null ],
            remarks: [ null ],
            id: [ null ],
            attachments: [ '' ],
            gender: [ null ],
            formArray: this.fb.array( [] ),
        } );

    }

    getFields ( docType: string ): Array<{ name: string, label: string, type: string }> {

        return this.fields.get( parseInt( docType ) ) ? this.fields.get( parseInt( docType ) ) : [];

    }

    override ngOnInit (): void {

        this.verification = 'document';

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                {
                    next: ( employee: Employee ) => {
                        this.employee = employee;
                        this.populateForm( employee );
                        this.isSubmitting = false;
                        this.applyFormValidations();
                    },
                }
            );
        } );

        // Subscribe to changes in the form.
        this.subscribeToQueryChanges();
    }

    populateForm ( employee: Employee ): void {

        const verifications: any = employee.document_verifications;

        verifications.forEach( ( verification: any ) => {

            const address = verification[ 'address' ];

            verification[ 'address' ] = this.fb.group( {
                ...address,
            } );

            const fields = verification[ 'fields' ];

            const docFields: any = {};

            const docFieldsArray = this.getFields( ( verification[ 'type' ] ) );

            if ( docFieldsArray.length > 0 ) {

                docFieldsArray.forEach( field => {

                    docFields[ field.name ] = '';

                } );

                verification[ 'fields' ] = this.fb.group( {
                    ...docFields,
                } );

                verification[ 'fields' ].patchValue( fields );
            }

        } );

        this.verificationFormGroup.patchValue( employee );

        const verificationFormGroup = verifications.map( ( verification: any ) => this.fb.group( verification ) );

        const verificationGroupArray: FormArray = this.fb.array( verificationFormGroup );

        verificationGroupArray.patchValue( verifications );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );
    }

    updateFieldControls ( form: any ): void {

        const fields = form.get( 'fields' ).value;

        const docFields: any = {};

        const docFieldsArray = this.getFields( form.get( 'type' ).value );

        if ( docFieldsArray.length > 1 ) {

            docFieldsArray.forEach( field => {
                docFields[ field.name ] = '';
            } );

            form.setControl( 'fields', this.fb.group( {
                ...docFields,
            } ) );

            if ( fields ) { form.controls[ 'fields' ].patchValue( fields ); }
        }


    }


    ngOnChanges (): void {


    }

    applyFormValidations () {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );

            this.applyDefaultValidations( form );

        } );
    }

    onTypeChanged ( form: FormGroup | any ): void {

        this.applyValidationsForTypeChange( form.get( 'type' )?.value, form );

        this.updateFieldControls( form );

    }

    onStatusChanged ( form: FormGroup | any ): void {

        this.statusChanged = true;

        this.applyValidationsForStatusChange( form.get( 'status_id' )?.value, form );

    }

    onDocumentChanged ( form: FormGroup | any ): void {

        this.getMaxHeight( form );

        this.upload( form, 'document_url' );

    }

    onBackUrlChanged ( form: FormGroup | any ): void {

        this.getMaxHeight( form );

        this.upload( form, 'back_url' );

    }

    onProofChanged ( form: FormGroup | any ): void {

        this.getMaxHeight( form );

        this.upload( form, 'proof_url' );

    }

    override upload ( verification: any, type_of_file: any ) {

        const verificationId = verification.value.id;

        // Locate the file element meant for the file upload.
        const inputEl: HTMLInputElement | any = this.el.nativeElement.querySelector( '#' + type_of_file + '_' + verificationId );

        // Get the total amount of files attached to the file input.
        const fileCount: number = inputEl.files.length;

        this.checkFileSize( inputEl, fileCount );

        if ( !this.maxFileError ) {

            //         // Create a new form data instance
            const formData = new FormData();

            // Check if the file count is greater than zero, to be sure a file was selected.
            if ( fileCount > 0 ) { // a file was selected

                const files = inputEl.files;

                for ( let i = 0; i < fileCount; i++ ) {

                    formData.append( 'file_' + i, inputEl.files.item( i ) );
                }

                formData.append( 'type', type_of_file + '_' + verificationId );

                const url = environment.api_url + this.verification + '/' + verification.value.id + '/upload';

                const headers = new HttpHeaders( { 'Authorization': 'Bearer ' + localStorage.getItem( 'token' ) } );

                const options = { headers: headers };

                // TODO:: move http to service

                this.http.post( url, formData, options ).pipe(

                    map( ( res: any ) => res ) ).subscribe(
                        ( success: any ) => {

                            if ( verification.get( type_of_file ) ) {
                                verification.get( type_of_file ).setValue( success[ 'file_name' ] );
                            }
                            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                                employee => {
                                    this.employee = employee;
                                    this.getAttachments( employee );
                                },
                            );
                            this.common.notifications.success( 'File uploaded Successfully' );
                            this.ngOnInit();
                        },
                        ( error ) => this.common.notifications.error( 'Unable to upload File' )
                    )
            }
        }
        this.maxFileError = false;
    }

    /**
     * Set Default Validations to be applied on Page Load
     *
     * @param {AbstractControl} form
     */
    applyDefaultValidations ( form: AbstractControl ) {

        form.get( 'name' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
        form.get( 'name' )?.updateValueAndValidity();

        form.get( 'address.pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
        form.get( 'address.pincode' )?.updateValueAndValidity();

        this.applyValidationsForStatusChange( form.get( 'status_id' )?.value, form );
        this.applyValidationsForTypeChange( form.get( 'type' )?.value, form );
    }


    /**
     * Apply Validations when Status is changed
     *
     * @param statusId
     * @param {AbstractControl} form
     */
    applyValidationsForStatusChange ( statusId: any, form: AbstractControl ) {

        // List of statuses for which validations is required on completion Date.
        const validStatusesForCompletedAtDate: string[] = [ '1', '2', '3', '7', '8' ];
        const validStatusesForClientRemarks: string[] = [ '2', '3', '5', '7', '8' ];

        // Check if changed status is in our list.
        if ( validStatusesForCompletedAtDate.indexOf( statusId ) !== -1 ) {

            form.get( 'completed_at' )?.setValidators(
                [ Validators.required, validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );

            if ( this.statusChanged ) {
                form.get( 'completed_at' )?.setValue( new Date().toJSON().slice( 0, 10 ) );
            }

            form.get( 'type' )?.setValidators( [ Validators.required ] );

        } else {

            if ( this.statusChanged ) {
                form.get( 'completed_at' )?.setValue( '' );
            }

            form.get( 'completed_at' )?.setValidators(
                [ validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );
            form.get( 'type' )?.setValidators( [] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForClientRemarks.indexOf( statusId ) !== -1 ) {

            form.get( 'client_remarks' )?.setValidators( [ Validators.required ] );

        } else {
            form.get( 'client_remarks' )?.setValidators( [] );
        }


        form.get( 'completed_at' )?.updateValueAndValidity();
        form.get( 'type' )?.updateValueAndValidity();
        form.get( 'client_remarks' )?.updateValueAndValidity();
    }

    /**
     * Apply Validations when Doc Type is changed
     *
     * @param type
     * @param {AbstractControl} form
     */
    applyValidationsForTypeChange ( type: any, form: AbstractControl ) {

        if ( type !== '0' ) {
            form.get( 'document_number' )?.setValidators( [ Validators.required ] );
        } else {
            form.get( 'document_number' )?.setValidators( [] );
        }

        form.get( 'document_number' )?.updateValueAndValidity();
    }


    createDocument () {
        this.verificationService.create( this.employee.id ).subscribe(
            response => {
                this.populateForm( response );
                this.ngOnChanges();
            }
        );

        this.common.notifications.success( 'Document Verification added' );
    }

    override checkFileSize ( inputEl: any, fileCount: any ) {
        let fileSize = 0;
        for ( let i = 0; i <= fileCount; i++ ) {
            if ( inputEl.files[ i ] ) {
                fileSize = inputEl.files[ i ].size / 1024;
                if ( fileSize > this.maxFileSize ) {
                    this.maxFileError = true;
                    this.common.notifications.error( 'Unable to upload!! File size must be less than 5mb' );
                    alert( `Unable to upload!! File size must be less than ${ this.maxFileSize }` );
                }
            }
        }
    }


    /**
     * Get the max Height for image panel
     *
     * @param verification
     * @returns {any}
     */
    getMaxHeight ( verification: any ) {

        // Set max height on page Load
        const proof_url = verification.get( 'proof_url' ).value;
        const document_url = verification.get( 'document_url' ).value;

        if ( document_url && !proof_url || !document_url && proof_url ) {

            return '536px';
        } else {
            return '240px';
        }

    }


    changeTypeaheadNoResults ( event: any, formGroup: number, controlName: string ): void {

        if ( this.formArray.controls[ formGroup ].get( controlName )?.value === '' ) {

            this.formArray.controls[ formGroup ].get( controlName )?.setValue( null );
        }
    }


    // TypeAhead functionality.
    OnSelect ( event: any, formGroup: number, controlName: string ): void {

        this.formArray.controls[ formGroup ].get( controlName )?.setValue( event.item.id );

        if ( controlName === 'address.state_id' ) {

            this.formArray.controls[ formGroup ].get( 'address.district_id' )?.setValue( '' );
            this.formArray.controls[ formGroup ].get( 'address.district_name' )?.setValue( '' );
        }
    }

    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.cdr.detectChanges();
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                () => {
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }

    imageSaved (): void {
        this.ngOnInit();
    }

    isTypeCreditReport ( verification: any ) {
        return verification.get( 'type' )?.value == DocumentVerificationTypes.CREDIT_REPORT;
    }

    isTypeBankAccount ( verification: any ) {
        return verification.get( 'type' )?.value == DocumentVerificationTypes.BANK_ACCOUNT;
    }

    populateDocumentTypeFieldMap (): void {

        // fields for vehicle_rc
        this.fields.set( 1, [
            { name: 'chassis_number', label: 'Chassis Number', type: 'text' },
            { name: 'engine_number', label: 'Engine Number', type: 'text' },
            { name: 'vehicle_class', label: 'Vehicle Class', type: 'text' },
            { name: 'vehicle_type', label: 'Vehicle Type', type: 'text' },
            { name: 'vehicle_company', label: 'Vehicle Company', type: 'text' },
            { name: 'vehicle_model', label: 'Vehicle Model', type: 'text' },
            { name: 'fuel_type', label: 'Fuel Type', type: 'text' },
            { name: 'valid_upto', label: 'Valid Upto', type: 'date' },
            { name: 'fitness_upto', label: 'Fitness Upto', type: 'date' },
            { name: 'fitness_certificate_expiry_date', label: 'Fitness Certificate Expiry Date', type: 'date' },
            { name: 'insurance_upto', label: 'Insurance Upto', type: 'date' },
            { name: 'laden_weight', label: 'Laden Weight', type: 'text' },
            { name: 'unladen_weight', label: 'Unladen Weight', type: 'text' },
        ] );

        // fields for insurance_certificate
        this.fields.set( 6, [
            { name: 'vehicle_number', label: 'Vehicle Number', type: 'text' },
            { name: 'insurance_amount', label: 'Insurance Amount', type: 'text' },
        ] );

        // fields for credit report
        this.fields.set( 7, [
            { name: 'bureau', label: 'Credit Bureau', type: 'text' },
            { name: 'credit_score', label: 'Credit Score', type: 'text' }
        ] );

        // fields for gst
        this.fields.set( 8, [
            { name: 'legal_name', label: 'Legal Name', type: 'text' },
            { name: 'trade_name', label: 'Trade Name', type: 'text' },
            { name: 'type_of_registration', label: 'Type Of Registration', type: 'text' },
            { name: 'constitution_of_business', label: 'Constitution Of Business', type: 'text' },
        ] );

        this.fields.set( DocumentVerificationTypes.BANK_ACCOUNT, [
            { name: 'ifsc_code', label: 'IFSC Code', type: 'text' },
            { name: 'bank_name', label: 'Bank Account Name', type: 'text' }
        ] );
    }

}
