import { HttpParams } from '@angular/common/http';
import { Directive, HostListener, ElementRef, Input } from '@angular/core';

import { DownloadService } from '../../core/services/download.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Employee } from 'src/app/core/models/employee.model';

@Directive( { selector: '[downloadDocument]' } )
export class DownloadDocumentDirective {

    @Input() employee!: Employee;

    constructor (
        private elementRef: ElementRef,
        private downloadService: DownloadService,
        protected commonService: CommonService,
    ) { }

    @HostListener( 'click', [ '$event' ] )
    onClick ( event: MouseEvent ): void {

        event.preventDefault();

        const documentUrl = this.elementRef.nativeElement.getAttribute( 'href' );

        const documentType = this.elementRef.nativeElement.getAttribute( 'documentType' );

        const documentName = this.downloadService.reportName( this.employee, documentType );

        this.downloadService.downloadFile( documentUrl, documentType, null ).subscribe(
            ( blob: Blob ) => {
                this.downloadService.getFile( blob, documentName );
            },
            ( error ) => {
                this.commonService.notifications.error( 'Documents not found' );
            }
        );
    }

}
