<div bsModal #clientsApiCreate="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Fill the details to create new Client API</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <form [formGroup]="createForm" (ngSubmit)="submitForm()" (keydown.enter)="$event.preventDefault()">
                        <fieldset [disabled]="isSubmitting">

                            <fieldset class="form-group">
                                <label class="center-block"> Select Type</label>
                                <select class="form-control" formControlName="type" required (change)="onTypeChanged()">
                                    <option value="company">Company</option>
                                    <option value="vendor">Vendor</option>
                                </select>
                            </fieldset>

                            <fieldset class="form-group" *ngIf="showCompanies">
                                <label class="center-block"> Company</label>
                                <app-company-dropdown-field [form]="createForm" (changed)="onCompanySelected()" />
                            </fieldset>

                            <fieldset class="form-group" *ngIf="showCompanies">
                                <label class="center-block"> Lot</label>
                                <select class="form-control" formControlName="lot_id">
                                    <option *ngFor="let lot of lots" [value]="lot.id">
                                        {{lot.name}}
                                    </option>
                                </select>
                            </fieldset>

                            <fieldset class="form-group" *ngIf="showVendors">
                                <label class="center-block"> Vendors</label>
                                <select class="form-control" formControlName="vendor_id">
                                    <option *ngFor="let vendor of vendors" [value]="vendor.id">
                                        {{vendor.company_name}}
                                    </option>
                                </select>
                            </fieldset>

                            <fieldset class="form-group hidden">
                                <input class="form-control" formControlName="api_status" required hidden value="1">
                            </fieldset>

                            <fieldset class="form-group" *ngIf="showCompanies">
                                <label class="center-block"> Whitelist IP Addresses (Comma Separated eg. 0.0.0.0,
                                    127.0.0.1, 192.168.0.1)</label>
                                <input class="form-control" formControlName="ips" value="" />
                            </fieldset>

                            <fieldset class="form-group" formGroupName="limits" *ngIf="showCompanies">
                                <label class="center-block"> Limits (Note: Used only on testing environment. Same values
                                    also need to be
                                    set on testing environment)</label>
                                <div class="row">
                                    <div class="col-md-2">
                                        <label class="center-block">OCR</label>
                                        <input class="form-control" formControlName="ocr" type="number"
                                            placeholder="0" />
                                    </div>
                                    <div class="col-md-2">
                                        <label class="center-block">FACE</label>
                                        <input class="form-control" formControlName="face" type="number"
                                            placeholder="0" />
                                    </div>
                                    <div class="col-md-2">
                                        <label class="center-block">VEHICLE</label>
                                        <input class="form-control" formControlName="vehicle" type="number"
                                            placeholder="0" />
                                    </div>
                                    <div class="col-md-2">
                                        <label class="center-block">LICENCE</label>
                                        <input class="form-control" formControlName="licence" type="number"
                                            placeholder="0" />
                                    </div>
                                    <div class="col-md-2">
                                        <label class="center-block">GST</label>
                                        <input class="form-control" formControlName="gst" type="number"
                                            placeholder="0" />
                                    </div>
                                    <div class="col-md-2">
                                        <label class="center-block">VOTER</label>
                                        <input class="form-control" formControlName="voter" type="number"
                                            placeholder="0" />
                                    </div>
                                    <div class="col-md-2">
                                        <label class="center-block">AADHAAR</label>
                                        <input class="form-control" formControlName="aadhaar" type="number"
                                            placeholder="0" />
                                    </div>
                                    <div class="col-md-2">
                                        <label class="center-block">PAN</label>
                                        <input class="form-control" formControlName="pan" type="number"
                                            placeholder="0" />
                                    </div>

                                </div>
                            </fieldset>

                            <button class="btn btn-primary btn-block" [disabled]="!createForm.valid">
                                Create API
                            </button>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>