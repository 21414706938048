import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, throwError } from 'rxjs';
import { ClientCrms } from 'src/app/core/enums/client-crms.enum';
import { Lot } from 'src/app/core/models/client-forms.model';
import { Company } from 'src/app/core/models/company.model';
import { ClientCrmService } from 'src/app/core/services/client-crm.service';
import { CommonService } from 'src/app/core/services/common.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { LotService } from 'src/app/core/services/lot.service';

@Component( {
    selector: 'app-crm-edit',
    templateUrl: './client-crm-edit.component.html',
} )

export class ClientCrmEditComponent implements OnInit {

    editCrmForm!: FormGroup;
    companyId!: number;
    lots: any[] = [];
    lot!: Lot;
    response: any;
    clientCrms = ClientCrms;
    companies: Company[] = [];

    get headers (): FormArray {
        return this.editCrmForm.get( 'headers' ) as FormArray;
    }

    get params (): FormArray {
        return this.editCrmForm.get( 'params' ) as FormArray;
    }

    get verifications (): FormArray {
        return this.editCrmForm.get( 'verifications' ) as FormArray;
    }

    constructor ( private fb: FormBuilder,
        private route: Router,
        private router: ActivatedRoute,
        private companyService: CompanyService,
        private lotService: LotService,
        public clientCrmService: ClientCrmService,
        public commonService: CommonService,
        public globalService: GlobalService
    ) { }

    ngOnInit (): void {

        this.companies = this.globalService.fetchCompanies();

        this.initForm();

        this.router.paramMap.pipe(
            switchMap( ( params: ParamMap ) => {
                const id = params.get( 'id' );

                if ( id !== null && id !== undefined ) {
                    return this.clientCrmService.find( +id );
                } else {
                    return throwError( 'ID is null or undefined' );
                }
            } )
        ).subscribe( ( response: any ) => {
            this.response = response;
            this.companyId = response.company_id;
            this.fetchLots();
            this.transformResponse();
            this.fetchLot( response.lot_id );
        } );

    }

    transformResponse (): void {

        const headersArray = Object.keys( this.response.headers ).map( key => ( {
            header: key,
            value: this.response.headers[ key ],
        } ) );

        this.response.headers = headersArray;

        const paramsArray = Object.keys( this.response.params ).map( key => ( {
            param: key,
            value: this.response.params[ key ],
        } ) );

        this.response.params = paramsArray;

        const verificationsArray = Object.keys( this.response.verifications ).map( key => ( {
            verification_name: key,
            value: this.response.verifications[ key ],
        } ) );

        this.response.verifications = verificationsArray;

        this.editCrmForm.patchValue( this.response );

        this.editCrmForm.setControl( 'params', this.createFormArray( paramsArray ) );

        this.editCrmForm.setControl( 'headers', this.createFormArray( headersArray ) );

        this.editCrmForm.setControl( 'verifications', this.createFormArray( verificationsArray ) );

    }

    private createFormArray ( itemsToFormArray: any[] ): FormArray {
        const formGroupArray: FormGroup[] = itemsToFormArray.map( ( item: any ) => this.fb.group( item ) );

        return this.fb.array( formGroupArray );
    }

    onCompanyChange (): void {

        this.companyId = this.editCrmForm.get( 'company_id' )?.value;

        if ( !this.companyId ) return;

        this.fetchLots();
    }

    onlotChange ( $event: any ): void {

        this.fetchLot( $event.target.value );
    }

    fetchLot ( lotId: number ): void {

        this.lotService.find( lotId ).subscribe( ( response: any ) => {
            this.lot = response;
        } );

    }

    fetchLots (): void {
        this.companyService?.getLots( this.companyId ).subscribe( ( response: Lot[] ) => {
            this.lots = response;
        } );
    }

    initForm (): void {
        this.editCrmForm = this.fb.group( {
            company_id: [ '', Validators.required ],
            lot_id: [ '', Validators.required ],
            type: [ '', Validators.required ],
            url: [ '', Validators.required ],
            headers: this.fb.array( [] ),
            params: this.fb.array( [] ),
            verifications: this.fb.array( [ this.createVerificationsRow() ] ),
        } );
    }

    createHeadersRow (): FormGroup {
        return this.fb.group( {
            header: [ '', [ Validators.required ] ],
            value: [ '', [ Validators.required ] ],
        } );
    }

    createParamsRow (): FormGroup {
        return this.fb.group( {
            param: [ '', [ Validators.required ] ],
            value: [ '', [ Validators.required ] ],
        } );
    }

    createVerificationsRow (): FormGroup {
        return this.fb.group( {
            verification_name: [ '', [ Validators.required ] ],
            value: [ '', [ Validators.required ] ],
        } );
    }

    addNewRow ( type: string ): void {

        if ( type === 'params' ) {
            this.params.push( this.createParamsRow() );
        }

        if ( type === 'headers' ) {
            this.headers.push( this.createHeadersRow() )
        }

        if ( type === 'verification' ) {
            this.verifications.push( this.createVerificationsRow() )
        }

    }

    deleteRow ( type: string, index: number ): void {
        if ( type === 'params' ) {
            this.params.removeAt( index );
        }

        if ( type === 'headers' ) {
            this.headers.removeAt( index );
        }

        if ( type === 'verification' ) {
            this.verifications.removeAt( index );
        }
    }

    submitForm (): void {

        const paresedFormData: any = this.clientCrmService.parseData( this.editCrmForm );

        this.clientCrmService.update( this.response.id, paresedFormData ).subscribe( ( response: { message: any; } ) => {
            this.commonService.notifications.success( response.message );
            this.route.navigateByUrl( '/client-crm' );
        } )
    }

}
