<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Vcourt</h1>
</div>


<app-vcourt-job-create #submitNewVcourtRequest (onSubmit)="onNewRequestSubmit()"></app-vcourt-job-create>


<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div *ngIf="companies" class="form-group col-md-2">
                        <label class="center-block">Filter By Company</label>
                        <app-company-dropdown-field [form]="filterFormGroup" (changed)="onCompanySelected()" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block"> Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Name</label>
                        <input class="form-control" formControlName="candidate_name">

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Address</label>
                        <input class="form-control" formControlName="address">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By TrackId</label>
                        <input class="form-control" formControlName="track_id">
                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Status</label>
                        <select class="form-control" formControlName="job_status">
                            <option [value]="all">-</option>
                            <option [value]="1">Completed</option>
                            <option [value]="0">Processing</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Source</label>
                        <select class="form-control" formControlName="via">
                            <option [value]="all">-</option>
                            <option [value]="instaveritas">Instaveritas</option>
                            <option [value]="verify247">Verify24*7</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Creation Date</label>
                        <input class="form-control" type="date" formControlName="vcourt_created_after" />
                        <input class="form-control" type="date" formControlName="vcourt_created_before" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="row">
        <div class="col-md-12">
            <a [appCan]="'admin'" class="btn btn-primary btn-addon" (click)="onFetchCasesForPendingJobsButtonClicked()">
                Fetch Cases for Pending Jobs
            </a>
            <a class="btn btn-primary btn-addon" (click)="submitNewVcourtRequest.show()">
                Submit New Request
            </a>
            <a class="btn btn-primary btn-addon" (click)="exportExcel()">
                <i class="fa fa-download"></i>
                Billing Excel
            </a>
            <a class="btn btn-primary btn-addon" routerLink="/vcourt/update-cases">
                <i class="fa fa-upload"></i>
                Update Cases
            </a>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)">
                                <i></i>
                            </label>
                        </th>
                        <th style="width: 65%">Details</th>
                        <th style="width: 15%">Status</th>
                        <th style="width: 15%">Timeline</th>
                        <th style="width: 5%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let job of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td><label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{job.id}}"
                                    [(ngModel)]="job.is_checked" (change)="getValues(job.id,$event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="btn-link" [routerLink]="['/employees', job.employee_id,'edit']">{{
                                        job.track_id }}</a>
                                </li>
                                <li>{{job.name}}</li>
                                <li>{{job.address}}</li>
                            </ul>
                        </td>

                        <td class="{{job.status}}">
                            <ul class="list-unstyled">

                                <li>{{job.cases_count}} Cases</li>
                                <li>Status: {{job.status}}</li>
                                <li>Via : {{job.via}}</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li> {{job.created_at}}</li>
                                <li> {{job.updated_at}}</li>
                            </ul>
                        </td>
                        <td>

                            <p>
                                <button class="btn btn-success btn-xs" (click)="showResultsByJobId(job.id)">Show Results
                                </button>
                            </p>

                            <p>
                                <button class="btn btn-primary btn-xs" (click)="refreshCases($event,job.id)">Refresh
                                    Cases
                                </button>
                            </p>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>