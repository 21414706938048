<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Global Database Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="toggleStatsVisibility()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="global-db"></app-verification-stats>
</ng-container>

<app-verification-filters verification="globaldb" [filterParams]="filterParams"
    filterBy="company,lot,verification_status,state,district,created_after,created_before,completion_date_from,completion_date_to,assigned_to,track_id,dov_present,work_completed,qa_required,qa_done,sent_to_vendor_date_present,sent_to_vendor,work_assignment">
</app-verification-filters>
<app-work-assign-modal #workAssignModal [verification]="'globaldb'" [checkedIds]="ids"></app-work-assign-modal>

<div class="row" style="padding: 0px 0px 0px 35px">
    <a [appCan]="'editor'" [appHide]="'globaldb,download.excel'" class="btn btn-primary btn-addon"
        (click)="downloadNewExcel()">
        <i class="fa fa-edit"></i>
        Download Excel
    </a>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="row wrapper">
            <div class="col-sm-8" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses['globaldb']"
                            (click)="onChangeStatusButtonClicked(status.id,status.title)">
                            <a class="dropdown-item">{{status.title}}</a>
                        </li>
                    </ul>
                </div>

                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('globaldb')"
                    class="btn btn-danger btn-addon"><i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{user.name}}</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown [appCan]="'admin'">
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Vendor <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu vendor-menu" role="menu">
                        <li role="menuitem" *ngFor="let vendor of vendors"
                            (click)="onChangeVendorButtonClicked(vendor.id)">
                            <a class="dropdown-item">{{vendor.name}}</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa required <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa Done <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>

            </div>

        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)">
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:30%;">Details</th>
                        <th style="width:20%;">Vendor Details</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let verification of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i=index">
                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{verification.id}}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id,$event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <a class="btn-link text-main text-semibold"
                                (click)="showGlobalDb(verification)">{{verification.employee_name}}</a>
                            <br>
                            <a class="btn-link text-main"
                                (click)="showGlobalDb(verification)">{{verification.track_id}}</a>
                            <br>
                            <a class="btn-link" routerLink="/lots/{{verification.lot_id}}">
                                {{verification.lot_name}}
                            </a>
                            <br>
                            <a class="btn-link" routerLink="/companies/{{verification.company_id}}">
                                {{verification.company_name}}
                            </a>
                            <ul class="list-unstyled">
                                <li>Father's name: {{ verification.father_name }}</li>
                                <li>DOB: {{ verification.dob}}</li>
                            </ul>
                        </td>

                        <td class="{{ verification.color }}">
                            <app-verification-status verification="globaldb" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at">
                            </app-verification-status>
                            <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                                <div class="col-md-12">
                                    <a href="{{attachment['full_path']}}" target="_blank"
                                        id="delete_attachment_{{attachment['id']}}">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                        </td>


                        <td>
                            <ul class="list-unstyled">
                                <li>Address: {{ verification.full_address}}</li>
                                <li>Sent to vendor : {{verification.sent_to_vendor_on}}</li>
                                <li>Report Date : {{verification.completed_at}}</li>
                                <li>Vendor Name : {{verification.vendor_name}} </li>
                            </ul>
                        </td>

                        <td>
                            <app-vendor-dropdown verification="globaldb" [verificationId]="verification.id"
                                [vendorId]="verification.vendor_id" [verificationDate]="verification.completed_at"
                                [sentToVendorDate]="verification.sent_to_vendor_on" [vendorList]="vendors"
                                (onVerificationUpdated)="onModalUpdated()">
                            </app-vendor-dropdown>
                        </td>

                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id,i)">Delete
                            </button>
                            <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                                <div class="col-md-12">
                                    <button
                                        (click)="onDeleteAttachmentButtonClicked(verification.id,attachment['id'],'delete_attachment_'+attachment['id'])"
                                        class="btn btn-danger btn-xs" id="delete_attachment_{{attachment['id']}}">
                                        Delete {{attachment['file_name']}}
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>