import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Company } from 'src/app/core/models/company.model';
import { Institute } from 'src/app/core/models/institute.model';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { InstituteService } from 'src/app/core/services/institute.service';
import { StatusService } from 'src/app/core/services/status.service';

@Component( {
    selector: 'app-employee-dashboard-filters',
    templateUrl: './employee-dashboard-filters.component.html',
    styleUrls: [ './employee-dashboard-filters.component.css' ]
} )
export class EmployeeDashboardFiltersComponent implements OnInit {

    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    lots: any[] = [];

    constructor (
        public globalService: GlobalService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService,
        private statusService: StatusService
    ) {
        this.initForm();
    }

    get companies (): Company[] {
        return this.globalService.fetchCompanies();
    }

    get users () {
        return this.globalService.fetchUsers();
    }

    get statuses () { return this.statusService.statuses }

    ngOnInit (): void {

        this.initForm();

        this.updateFormFilter();

        this.filterResults();

    }


    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            employee_name: '',
            completed: 'all',
            company_id: [ [] ],
            lot_id: [],
            employee_status_id: [ [] ],
            identity_status_id: [ [] ],
            current_address_status_id: [ [] ],
            permanent_address_status_id: [ [] ],
            icr_status_id: [ [] ],
            education_status_id: [ [] ],
            employment_status_id: [ [] ],
            reference_status_id: [ [] ],
            pcc_status_id: [ [] ],
            global_database_status_id: [ [] ],
            document_status_id: [ [] ],
            created_before: '',
            created_after: '',
            completed_before: '',
            completed_after: '',
            employee_id: 'all',
            track_id: 'all',
            email: 'all',
            official_email: 'all',
            mobile_number: 'all'
        } );
    }

    fetchLots (): void {

        const params = new HttpParams();

        const $companyIds: [] = this.filterFormGroup.get( 'company_id' )?.value;

        // remove all current lots
        this.lots.splice( 0, this.lots.length );

        const combinedLots: any[] = [];


        if ( !$companyIds || !$companyIds.toString() ) {
            this.lots = [];
            return;
        }

        const request = params.set( 'company_id', $companyIds.toString() );

        // fetch the lots for each company id selected
        this.commonService.companiesLots( request ).subscribe( lots => {

            lots.forEach( lot => combinedLots.push( lot ) );

            this.lots = combinedLots;

        } );

        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        // this.filterFormGroup.controls.lot_id.setValue( 'all' );
    }

    onCompanySelected (): void {
        const companyId: number = this.filterFormGroup.controls[ 'company_id' ].value;

        if ( companyId == null ) {
            this.filterFormGroup.get( 'lot_id' )?.reset();
            this.lots = [];
            return;
        };
        // Get the lots for the selected company in dropdown
        this.commonService.getLotsByCompanyId( companyId ).subscribe(
            response => {
                response.push( {
                    id: 'all',
                    name: 'All',
                } );
                this.lots = response;
            }
        );
        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        this.filterFormGroup.controls[ 'lot_id' ].setValue( 'all' );
    }

    onCompanyDropDownClosed () {

        this.fetchLots();

    }

    updateFormFilter (): void {

        this.filterService.updateFormFiltersv2( this.filterFormGroup, [
            'company_id',
            'lot_id',
            'employee_status_id',
            'identity_status_id',
            'current_address_status_id',
            'permanent_address_status_id',
            'icr_status_id',
            'education_status_id',
            'employment_status_id',
            'reference_status_id',
            'pcc_status_id',
            'global_database_status_id',
            'document_status_id'
        ] );

    }

    filterResults (): void {

        this.fetchLots();

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    resetForm (): void {

        this.initForm();

        this.filterResults();

    }
}
