import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';

import { validateEmail } from 'src/app/common/validators/email-validator.directive';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { ClientService } from 'src/app/core/services/client.service';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { UserService } from 'src/app/core/services/user.service';
import { User, UserLevel } from 'src/app/core/models/user.model';
import { LotService } from 'src/app/core/services/lot.service';
import { DownloadExcelService } from 'src/app/core/services/download-excel.service';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';
import { Router } from '@angular/router';


@Component( {
    selector: 'app-client-upload',
    templateUrl: './client-upload.component.html',
} )
export class ClientUploadComponent extends ImportExcelComponent implements OnInit {
    excelHeaders = [ { "EMAIL": "client@one.com", "USER_NAME": "Client One", "DESIGNATION": "HR", "MOBILE": "9876543210" } ];
    acceptedHeader = [ 'email', 'user_name', 'designation', 'mobile' ];
    uploadForm!: FormGroup;
    lots: any[] = [];
    routes: any[] = [];
    errorMessage: string = "";
    spocUsers: User[] = [];
    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor (
        protected override fb: FormBuilder,
        private commonService: CommonService,
        private lotService: LotService,
        public global: GlobalService,
        private clientService: ClientService,
        private userService: UserService,
        private downloadExcelService: DownloadExcelService,
        private router: Router,
    ) {
        super( fb );
    }

    ngOnInit (): void {
        this.initForm();
        this.getClientRoutes();
        this.getSpocUserList();
    }

    getSpocUserList () {

        const levelIds = [ UserLevel.Admin, UserLevel.SuperAdmin ];

        this.userService.getUsersByLevelId( levelIds ).subscribe( response => {
            this.spocUsers = response;
        } );
    }

    initForm (): void {

        this.uploadForm = this.fb.group( {
            company_id: [ null, Validators.required ],
            lot_id: [ null, Validators.required ],
            access_ids: [ null, Validators.required ],
            spoc_user_id: [ null, Validators.required ],
            clients: this.fb.array( [] ),
            send_password: [ true, Validators.required ],
        } );
    }

    get companyId () {
        return this.uploadForm.get( 'company_id' ) as FormArray;
    }

    get clients () {
        return this.uploadForm.get( 'clients' ) as FormArray;
    }

    get lotId () {
        return this.uploadForm.get( 'lot_id' ) as FormArray;
    }

    get companies () {
        return this.global.fetchCompanies();
    }

    get users (): User[] {
        return this.global.fetchUsers();
    }

    onCompanySelected (): void {

        this.lotId.reset();

        this.lots = [];

        const companyId: number = this.companyId.value;

        if ( !companyId ) return;

        this.lotService.getLotsByCompanyId( companyId ).subscribe( response => {
            this.lots = response;
        } );

    }

    downloadSampleExcel (): void {
        this.downloadExcelService.downloadClientSampleExcel();
    }

    getClientRoutes (): void {

        this.commonService.getClientRoutes().subscribe( response => {
            response.push( {
                id: 'all',
                name: 'All',
            } );
            this.routes = response;
        } );

    }

    get isFileDisabled () {
        if ( this.uploadForm.invalid ) {
            return true;
        }
        return false;
    }

    initExcelForm (): void {

        const isValidExcelHeader = this.isValidExcelHeader();

        this.errorMessage = "";

        if ( !isValidExcelHeader ) {

            this.clients.clear();

            this.errorMessage = "Excel Header Mismatch"

        }

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );

    }

    isValidExcelHeader () {
        if ( JSON.stringify( this.acceptedHeader ) === JSON.stringify( this.headerRaw ) ) {
            return true;
        }
        return false;
    }

    addClient ( formGroup: AbstractControl<any, any> ) {

        this.clients.push( this.fb.group( {
            email: [ formGroup.get( 'email' )?.value, [ Validators.required, validateEmail( true ) ] ],
            user_name: [ formGroup.get( 'user_name' )?.value, [ Validators.required, validateAllowedCharacters( '' ) ] ],
            designation: [ formGroup.get( 'designation' )?.value, Validators.maxLength( 255 ) ],
            mobile: [ formGroup.get( 'mobile' )?.value, [ Validators.pattern( /^(\s*\d{10}\s*,\s*)*\s*\d{10}\s*$/ ) ] ],
        } ) );
    }

    applyValidations (): void {
        this.clients.clear();

        this.excelFormArray.controls.forEach( formGroup => {

            this.addClient( formGroup );

        } );
    }

    setValidationMessages (): void {
    }

    onSubmit () {
        this.isSubmitting = true;

        this.clientService.bulkCreate( this.uploadForm.value ).subscribe(

            response => {
                this.commonService.notifications.success( 'Client Created' );

                this.router.navigate( [ '/clients' ] );

            },

            error => {

                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }

}
