import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { ReferenceVerificationStatusType } from '../../core/enums/reference-verification-status-type.enum';
import { validateEmail } from 'src/app/common/validators/email-validator.directive';
import { Employee } from 'src/app/core/models/employee.model';
import Status from 'src/app/core/models/status.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ReferenceService } from 'src/app/core/services/reference.service';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { validateDateRange } from 'src/app/common/validators/date-range-validator.directive';
import { WorkAssigned } from 'src/app/work/task-completed/task-completed.component';


@Component( {
    selector: 'app-reference-edit-form',
    templateUrl: './reference-edit-form.component.html',
    styleUrls: [ './reference-edit-form.component.css' ],
} )
export class ReferenceEditFormComponent implements OnInit {

    @Input() formGroup!: FormGroup | any;
    @Input() verificationFormGroup!: FormGroup | any;
    @Input() formArray!: FormArray;
    @Input() i!: number;
    @Input() employee!: Employee;
    @Input() vendors: any[] = [];
    @Input() attachments: any[] = [];

    @Output() uploaded = new EventEmitter<any>();

    statusChanged: boolean = false;
    date: Date = new Date();
    current_date: string = new Date( this.date.getTime() - ( this.date.getTimezoneOffset() * 60000 ) ).toJSON().slice( 0, 10 );

    ratings = [
        {
            'key': '0',
            'label': 'No Comment',
        },
        {
            'key': '1',
            'label': 'Very Poor',
        },
        {
            'key': '2',
            'label': 'Poor',
        },
        {
            'key': '3',
            'label': 'Good',
        },
        {
            'key': '4',
            'label': 'Very Good',
        },
        {
            'key': '5',
            'label': 'Excellent',
        },
    ];

    //1:Awaiting Details,2:Switch Off/Unreachable,4:Reference not Responding,29:Language Problem
    validStatusesForWorkCompleted: number[] = [ 1, 2, 4, 29 ];


    constructor (
        public verificationService: ReferenceService,
        public route: ActivatedRoute,
        public router: Router,
        public fb: FormBuilder,
        public common: CommonService,
        public global: GlobalService,
        public http: HttpClient,
        public el: ElementRef,
        public shortcutService: ShortcutService,
        public confirmationModalService: ConfirmationModalService
    ) {
    }

    get verification (): FormGroup { return this.formGroup; }

    get verificationId (): number { return this.verification.get( 'id' )?.value; }

    get verificationType (): string { return this.verification.get( 'type' )?.value; }

    get isTypeTenant (): boolean { return this.verificationType === 'tenant'; }

    get isTypeEmployment (): boolean { return this.verificationType === 'employment'; }

    get isTypeInPerson (): boolean { return this.verificationType === 'physical'; }

    get isTypePhone (): boolean { return this.verificationType === 'phone'; }

    get statusId (): number { return this.verification.get( 'status_id' )?.value; }

    get completedAt (): AbstractControl { return this.verification.get( 'completed_at' ) as AbstractControl; }

    get status (): Status | undefined {

        if ( !this.statuses ) return;

        return this.statuses.find( i => i.id === this.statusId );
    }

    get workCompleted (): AbstractControl {

        return this.formGroup.get( 'work_completed' ) as AbstractControl;
    }

    get workAssigned (): WorkAssigned[] {

        return this.formGroup.get( 'work_assigned' )?.value;
    }

    get statuses (): Status[] { return this.global.fetchStatuses()[ 'reference' ]; }

    get statusType () {
        return this.status ? this.status.type : null;
    }

    get nameNumberValidations (): boolean | any {

        if ( !this.status ) return true;

        return this.statusType === 'verified' ||
            this.statusType === 'discrepancy' ||
            this.statusType === 'under_process';

    }

    get isStatusRedOrGreen (): boolean {

        return this.statusType === 'verified' || this.statusType === 'discrepancy';

    }

    get commanFieldValidations (): boolean {

        if ( !this.status ) return true;

        return this.statusType === 'verified' || this.statusType === 'discrepancy';
    }

    get clientRemarkValidations (): boolean {

        return this.statusType === 'discrepancy'
            || this.statusId === ReferenceVerificationStatusType.VerificationStopped
            || this.statusId === ReferenceVerificationStatusType.NotApplicable
            || this.statusId === ReferenceVerificationStatusType.ClientTerminated
            || this.statusId === ReferenceVerificationStatusType.UnableToValidate;
    }

    get addressValidations (): boolean {
        return this.isTypeInPerson && this.isStatusRedOrGreen;
    }

    get dateOfVerificationValidations (): boolean {

        return this.isStatusRedOrGreen
            || this.statusId === ReferenceVerificationStatusType.VerificationStopped
            || this.statusId === ReferenceVerificationStatusType.ClientTerminated;
    }

    get districts () { return this.global.fetchDistricts(); }

    ngOnInit (): void {

        this.applyDefaultValidations();

        this.updateCreatedAtValidation();
    }

    updateCreatedAtValidation () {

        this.completedAt?.addValidators( validateDateRange( new Date( this.employee.created_at ), new Date( this.current_date ) ) )

    }

    upload ( verification: FormGroup, url: string ) {

        this.uploaded.emit( { verification, url } );

    }

    onDeleteVerificationButtonClicked () {

        const option: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( option, () => this.deleteVerification() );

    }

    deleteVerification () {

        this.verificationService.deleteVerification( this.verificationId ).subscribe( response => {

            this.formGroup.addControl( 'is_deleted', this.fb.control( [ true ] ) );

        } )

    }

    onTypeChanged ( $event: any ) {
        return $event;

    }

    onStatusChanged ( $statusId: number ) {

        if ( this.dateOfVerificationValidations ) {

            this.formGroup.get( 'completed_at' )?.setValidators( [ Validators.required, validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );
            this.formGroup.get( 'completed_at' )?.setValue( new Date().toJSON().slice( 0, 10 ) );
            this.formGroup.get( 'completed_at' )?.updateValueAndValidity();

        } else {

            this.formGroup.get( 'completed_at' )?.setValidators( [] );
            this.formGroup.get( 'completed_at' )?.setValue( null );
            this.formGroup.get( 'completed_at' )?.updateValueAndValidity();

        }

        if ( this.validStatusesForWorkCompleted.indexOf( $statusId ) != -1 && this.formGroup.get( 'work_completed' ) ) {
            this.formGroup.get( 'work_completed' )?.setValidators( [ Validators.required ] );
            this.formGroup.get( 'work_completed' )?.updateValueAndValidity();
        } else if ( this.formGroup.get( 'work_completed' ) ) {
            this.formGroup.get( 'work_completed' )?.setValidators( [] );
            this.formGroup.get( 'work_completed' )?.updateValueAndValidity();
        }


    }

    onRedFlagChanged ( $event: any ) {

        if ( $event.target.value == 1 ) {

            this.formGroup.get( 'potential_red_flags_remarks' )?.setValidators( [ Validators.required ] );
            this.formGroup.get( 'potential_red_flags_remarks' )?.updateValueAndValidity();

        } else {

            this.formGroup.get( 'potential_red_flags_remarks' )?.setValidators( [] );
            this.formGroup.get( 'potential_red_flags_remarks' )?.updateValueAndValidity();

        }

    }

    onIntegrityIssuesChanged ( $event: any ) {

        if ( $event.target.value == 1 ) {

            this.formGroup.get( 'integrity_issues_remarks' )?.setValidators( [ Validators.required ] );
            this.formGroup.get( 'integrity_issues_remarks' )?.updateValueAndValidity();

        } else {

            this.formGroup.get( 'integrity_issues_remarks' )?.setValidators( [] );
            this.formGroup.get( 'integrity_issues_remarks' )?.updateValueAndValidity();

        }

    }

    onRentReceviedOnTimeChange ( $event: any ) {

        if ( $event.target.value == 0 ) {

            this.formGroup.get( 'rent_received_remarks' )?.setValidators( [ Validators.required ] );
            this.formGroup.get( 'rent_received_remarks' )?.updateValueAndValidity();

        } else {

            this.formGroup.get( 'rent_received_remarks' )?.setValidators( [] );
            this.formGroup.get( 'rent_received_remarks' )?.updateValueAndValidity();

        }

    }

    onAnyDamageCausedChange ( $event: any ) {

        if ( $event.target.value == 1 ) {

            this.formGroup.get( 'damage_caused_remarks' )?.setValidators( [ Validators.required ] );
            this.formGroup.get( 'damage_caused_remarks' )?.updateValueAndValidity();

        } else {

            this.formGroup.get( 'damage_caused_remarks' )?.setValidators( [] );
            this.formGroup.get( 'damage_caused_remarks' )?.updateValueAndValidity();

        }

    }

    onAnyIssuesChange ( $event: any ) {

        if ( $event.target.value == 1 ) {

            this.formGroup.get( 'issue_remarks' )?.setValidators( [ Validators.required ] );
            this.formGroup.get( 'issue_remarks' )?.updateValueAndValidity();

        } else {

            this.formGroup.get( 'issue_remarks' )?.setValidators( [] );
            this.formGroup.get( 'issue_remarks' )?.updateValueAndValidity();

        }

    }

    applyDefaultValidations () {

        this.formGroup.get( 'name' )?.setValidators( [ validateAllowedCharacters( '.' ) ] );
        this.formGroup.get( 'name' )?.updateValueAndValidity();

        this.formGroup.get( 'email' )?.setValidators( [ validateEmail() ] );
        this.formGroup.get( 'email' )?.updateValueAndValidity();

        this.formGroup.get( 'father_name' )?.setValidators( [ validateAllowedCharacters( '.' ) ] );
        this.formGroup.get( 'father_name' )?.updateValueAndValidity();

        this.formGroup.get( 'number' )?.setValidators( [ validateNumericCharacters( '' ) ] );
        this.formGroup.get( 'number' )?.updateValueAndValidity();

        this.formGroup.get( 'pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );

        if ( this.validStatusesForWorkCompleted.indexOf( this.statusId ) != -1 && this.formGroup.get( 'work_completed' ) ) {
            this.formGroup.get( 'work_completed' )?.setValidators( [ Validators.required ] );
            this.formGroup.get( 'work_completed' )?.updateValueAndValidity();
        } else if ( this.formGroup.get( 'work_completed' ) ) {
            this.formGroup.get( 'work_completed' )?.setValidators( [] );
            this.formGroup.get( 'work_completed' )?.updateValueAndValidity();
        }

    }

}
