<div class="modal-lg" *ngIf="employee">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Send Insuff Mail For {{employee.name}}</h4>
            <a type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </a>
        </div>
        <div class="modal-body">
            <div class="row">
                <form [formGroup]="emailForm">
                    <div class="col-md-12 form-group">
                        <label class="center-block">Candidate Email</label>
                        <app-multi-select [options]="employeeEmails" [placeholder]="'Select Candidate Email'"
                            [controlName]="'to'" [formGName]="emailForm"></app-multi-select>
                    </div>
                    <div class="col-md-12 form-group" *ngIf="clients.length > 0">
                        <label class="center-block">CC Recruiters</label>
                        <app-multi-select [options]="clients" [placeholder]="'Select CC Recruiters'"
                            [controlName]="'cc'" [formGName]="emailForm"></app-multi-select>
                    </div>
                    <div class="col-md-12 form-group" *ngIf="clients.length == 0 && !loadingClients">
                        No Recruiters
                    </div>
                    <div class="col-md-12 form-group" *ngIf="loadingClients">
                        Loading recruiters.....
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="center-block">CC Others</label>
                        <input class="form-control" formControlName="extraEmails"
                            placeholder="comma separated emails abx@xyz.com,efg@pqr.com">
                        <small class="text-danger"
                            [hidden]="emailForm.get('extraEmails')?.valid || emailForm.get('extraEmails')?.pristine">
                            Please provide valid emails
                        </small>
                    </div>
                </form>
            </div>

            <div class="row" *ngIf="employee">

                <div class="col-md-2" [formGroup]="emailForm">
                    <input id="loa_attach" type="checkbox" name="loaAttatch" formControlName="is_loa_attached"
                        (change)="onLoaAttached()">
                    <label for="loa_attach" style="padding-left: 10px;">Attach LOA</label>
                </div>

            </div>

            <br>

            <div class="row" *ngIf="employee">
                <div class="col-md-12 form-group">
                    <div *ngFor="let verification of insuffVerifications">
                        <span>{{ verification.verification_type }} #{{ verification.id }}: {{
                            verification.client_remarks }}</span>
                    </div>
                    <div *ngIf="insuffVerifications.length === 0">
                        No Insuff Verifications
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-group">
                    <ckeditor [(ngModel)]="contents"></ckeditor>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="pull-right">
                <button class="btn btn-primary" (click)="sendMail()"
                    [disabled]="sending || emailForm.invalid">Send</button>
            </div>
            <div class="pull-left">
                <button class="btn btn-danger" (click)="bsModalRef.hide()"> Cancel</button>
            </div>
        </div>
    </div>
</div>