import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Company } from 'src/app/core/models/company.model';
import { CompanyService } from 'src/app/core/services/company.service';

export class CompanyDropdownChangedEvent {
    id!: number
    name!: string
}


@Component( {
    selector: 'app-company-dropdown-field',
    templateUrl: './company-dropdown-field.component.html'
} )
export class CompanyDropdownFieldComponent implements OnInit {

    @Input() form!: FormGroup;

    @Input() multiSelect: boolean = false;

    // If multiSelect is enabled, an array is emitted. Otherwise, a single event is emitted.
    @Output() changed: EventEmitter<CompanyDropdownChangedEvent | CompanyDropdownChangedEvent[]> =
        new EventEmitter<CompanyDropdownChangedEvent | CompanyDropdownChangedEvent[]>();


    get companies (): Company[] { return this.companyService.getCompanies(); }

    constructor ( public companyService: CompanyService ) { }

    ngOnInit (): void {

        // TODO: use setter once instead of getter for performance imporvement
        // this.companies = this.companyService.getCompanies();

    }

    onCompanySelected ( $event: any ) {

        this.changed.emit( $event );

    }
}
