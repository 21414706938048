<div class="wrapper-md">
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Two-Factor Authentication
                </div>

                <div class="panel-body">
                    <app-errors [errors]="errors"></app-errors>
                    <form [formGroup]="authForm" (ngSubmit)="submitOtp()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Please enter the 6 digit OTP sent to your email ID</label><br /><br />
                                <input id="otp" formControlName="otp" class="form-control" type="number"
                                    placeholder="* * * * * *" />
                            </fieldset>
                            <button id="login" class="btn btn-lg btn-primary btn-block" [disabled]="!authForm.valid"
                                type="submit">
                                Submit OTP
                            </button>
                        </fieldset>
                    </form>

                    <br />
                    <span *ngIf="!shouldShowResend">
                        Please wait {{remainingSeconds}} seconds to resend
                    </span>
                    <a *ngIf="shouldShowResend" (click)="retryLogin()">
                        Click here to restart login
                    </a>
                </div>
            </div>

            <span class="center-text red-text bold-text">Please do not refresh this page</span>

            <!-- remove this -->
            <!-- <div class="retry-login-container">
                <button (click)="retryLogin()" [disabled]="isResendDisabled" class="retry-login-btn"
                    [ngClass]="{'disabled': isResendDisabled}">
                    Retry Login
                </button>
                <span *ngIf="isResendDisabled"> ( {{ remainingSeconds }} seconds remaining) </span>
            </div> -->
        </div>
    </div>
</div>