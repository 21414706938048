import Status from './status.model';

export class StatusResponse {
    'address': Status[];
    'cases': Status[];
    'document': Status[];
    'education': Status[];
    'employee': Status[];
    'employment': Status[];
    'globaldb': Status[];
    'icr': Status[];
    'identity': Status[];
    'pcc': Status[];
    'reference': Status[]

    [ key: string ]: Status[]
}
