import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DocumentVerificationTypes } from 'src/app/core/enums/document-verification-types.enum';
import { DocumentType } from 'src/app/core/interfaces/document-type';
import { CommonService } from 'src/app/core/services/common.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-document-auto-verify-modal',
    templateUrl: './document-auto-verify-modal.component.html'
} )
export class DocumentAutoVerifyModalComponent {

    @ViewChild( 'autoVerifyModal', { static: false } )
    public autoVerifyModal!: ModalDirective;

    @Input() filterParams!: any;

    @Input() checkedIds!: [];

    @Input() verification!: string;

    DocumentVerificationTypes = DocumentVerificationTypes;

    selectedSources: { [ key: number ]: string } = {};

    constructor (
        private documentService: DocumentService,
        private commonService: CommonService,
        public global: GlobalService,
    ) { }

    get documentVerifications (): DocumentType[] {
        return this.global.fetchDocumentTypes().filter( item => item.sources && item.sources.length > 0 );
    }

    show (): void {
        this.autoVerifyModal.show();
    }

    hide (): void {
        this.autoVerifyModal.hide();
    }

    onSourceSelect ( event: Event, documentTypeId: number ): void {
        this.selectedSources[ documentTypeId ] = ( event.target as HTMLSelectElement ).value;
    }

    autoVerify (): void {
        const payload = {
            id: this.checkedIds,
            sources: this.selectedSources
        };

        this.hide();

        this.documentService.autoVerify( payload, this.filterParams ).subscribe( {
            next: () => {
                this.commonService.notifications.success( 'Success', 'Request to Auto Verify Sent' );
            },
            error: () => {
                this.commonService.notifications.error( 'Error', 'Unable to send request' );
            }
        } );
    }

}
