import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Employee } from '../../core/models/employee.model';
import { CommonService } from '../../core/services/common.service';
import { IcrService } from '../../core/services/icr.service';
import { IdentityService } from '../../core/services/identity.service';
import { IcrVcourtComponent } from '../../icr/icr-vcourt.component';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-submit-to-icr',
    templateUrl: './submit-to-icr.component.html',
    styleUrls: [ './submit-to-icr.component.css' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )

export class SubmitToIcrComponent extends IcrVcourtComponent implements OnInit {


    constructor (
        public override identityService: IdentityService,
        public override icrService: IcrService,
        public override commonService: CommonService,
        public override route: ActivatedRoute,
        public override router: Router,
        public override global: GlobalService,
        public override fb: FormBuilder,
        public override changeDetect: ChangeDetectorRef,
        public override confirmationModalService: ConfirmationModalService

    ) {
        super( icrService, commonService, route, router, global, fb, changeDetect, confirmationModalService, identityService, );

    }

    get states () { return this.global.fetchStates(); }

    get statuses () { return this.global.fetchStatuses(); }

    override ngOnInit (): void {
        this.checkedIds = [];
        this.verification = 'icr';
        this.subscribeToQueryChanges();
    }

    populateForm ( employees: Employee[] ): void {

        this.changeDetect.markForCheck();
        for ( const employee of employees ) {
            if ( employee.identity_verifications && employee.icr_verifications ) {

                for ( let i: number = 0; i < employee.identity_verifications.length; i++ ) {
                    if (
                        employee.icr_verifications[ i ] &&
                        employee.identity_verifications[ i ]
                    ) {

                        if ( employee.icr_verifications[ i ].address === '' ) {
                            employee.icr_verifications[ i ].address =
                                employee.identity_verifications[ i ].address;
                            employee.icr_verifications[ i ].pincode =
                                employee.identity_verifications[ i ].pincode;
                            employee.icr_verifications[ i ].state_id =
                                employee.identity_verifications[ i ].state_id;
                            employee.icr_verifications[ i ].district_id =
                                employee.identity_verifications[ i ].district_id;
                            employee.icr_verifications[ i ].district_name =
                                employee.identity_verifications[ i ].district_name;
                            employee.icr_verifications[ i ].state_name =
                                employee.identity_verifications[ i ].state_name;
                        }
                    }
                }
            }
        }

        const verificationFormGroup: any = employees.map( employee => this.fb.group( {
            name: [ employee.name ],
            employee_id: [ employee.id ],
            track_id: [ employee.track_id ],
            company: this.fb.group( {
                id: [ employee.company.id ],
                name: [ employee.company.name ],
            } ),
            father_name: [ employee.father_name ],
            dob: [ employee.dob ],
            created_at: [ employee.created_at ],
            is_checked: [ false ],
            identity_verifications: [ employee.identity_verifications ],
            icr_verifications_form_array: this.setIcrVerification( employee ),
            via: '',
        } ) );

        const verificationGroupArray: FormArray = this.fb.array( verificationFormGroup );

        this.icrFormGroup.setControl( 'formArray', verificationGroupArray );


    }

    filterResults (): void {
        this.identityService.getEmployees( this.filterParams )?.subscribe( ( response: any ) => {
            this.populateForm( response.data );
            this.pagination = response;
            this.employees = response.data;
            this.checkedIds = [];
        } );

    }

    copyAddressToIcrFromCheckedIdentity ( type: string ): void {

        for ( let i: number = 0; i < this.formArray.length; i++ ) {

            const formGroup: FormGroup = this.formArray.controls[ i ] as FormGroup;


            if ( formGroup.value.is_checked ) {

                const icr_verifications_form_array: any = [];

                icr_verifications_form_array.push( formGroup.controls[ 'icr_verifications_form_array' ] );

                // console.log(icr_verifications_form_array[0].value.controls[0].controls);
                for ( const icr_verifications_controls of icr_verifications_form_array ) {

                    for ( const icr of icr_verifications_controls.controls ) {

                        if ( icr.value.is_checked ) {

                            this.employees[ i ].identity_verifications.forEach( verification => {


                                if ( verification.type_name === type ) {
                                    this.copyToIcr( verification, this.employees[ i ], i, icr );
                                }

                            } );

                        }
                    }
                }

            }
        }
        this.commonService.notifications.success( 'Successful', 'Address Copied successfully.' );

    }


    showIdentity ( employee: FormGroup ): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            fragment: this.filterParams.toString(),
            queryParamsHandling: 'merge',
        };

        this.router.navigate( [ 'identity', employee.get( 'employee_id' )?.value, 'edit' ], navigationExtras );
    }

    showEmployee ( employee: FormGroup ): void {
        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            fragment: this.filterParams.toString(),
            queryParamsHandling: 'merge',
        };

        this.router.navigate( [ 'employees', employee.get( 'employee_id' )?.value, 'edit' ], navigationExtras );
    }


    OnTypeheadSelect ( event: any, name: string, icr: FormGroup ): void {

        const controlId: string = name + '_id';

        if ( name === 'state' ) {
            icr.get( 'district_name' )?.setValue( '' );
            icr.get( controlId )?.setValue( event.item.id );
        } else if ( name === 'district' ) {
            icr.get( controlId )?.setValue( event.item.id );
        } else {
            // this.verificationFormGroup.get(controlId).setValue(event.item.id);
        }
    }

}
