import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

export function validateDateRange ( minDate: Date, maxDate: Date ) {

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        // If the control value is null or empty, return an empty object
        if ( control.value === null || control.value === '' ) {
            return {};
        }

        let startDate = new Date( minDate ).setHours( 0, 0, 0, 0 );
        let endDate = new Date( maxDate ).setHours( 0, 0, 0, 0 );

        let dateValue = new Date( control.value ).setHours( 0, 0, 0, 0 )

        if ( dateValue < startDate || dateValue > endDate ) {
            return { 'validateRange': true };
        }

        return {};
    };
}


@Directive( {
    selector: '[validateDateRange]',
    providers: [ { provide: NG_VALIDATORS, useExisting: DateRangeValidatorDirective, multi: true } ],
} )
export class DateRangeValidatorDirective implements Validator {

    @Input() minDate!: Date;
    @Input() maxDate!: Date;

    validate ( control: AbstractControl ): { [ key: string ]: any } {
        return validateDateRange( this.minDate, this.maxDate )( control );
    }
}
