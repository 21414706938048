import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { CreditReportService } from 'src/app/core/services/credit-report.service';
import { JsonUtil } from 'src/app/core/utils/json-util';

@Component( {
    selector: 'app-credit-report-response-modal',
    templateUrl: './credit-report-response-modal.component.html',
    styleUrls: [ './credit-report-response-modal.component.css' ]
} )
export class CreditReportResponseModalComponent implements OnInit {

    @ViewChild( 'creditReportResponseModal', { static: false } )
    public creditReportResponseModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();

    public response!: any;
    public source!: any;
    attachments: any = [];
    constructor (
        private creditReportService: CreditReportService,
        private commonService: CommonService
    ) { }

    ngOnInit () {
    }

    show ( id: string, source: string ): void {

        this.source = source;

        this.creditReportService.getResponse( id ).subscribe( {

            next: ( response: any ) => {
                this.attachments = response[ 'attachments' ];
                this.setResponse( response[ 'response' ] );
                this.creditReportResponseModal.show();
            },
            error: ( errors: any ) => {

                this.commonService.notifications.error( 'Some Error Occurred' );
                this.commonService.notifications.error( errors );
            },
        } );
    }

    setResponse ( response: string ): void {

        if ( JsonUtil.isJsonString( response ) ) {

            this.response = JSON.parse( response );
        } else {

            this.response = response;
        }
    }

    hide (): void {
        this.creditReportResponseModal.hide();
    }
}
