export enum GlobalDbVerificationStatusType {
    UnderProcess = 1,
    NoRecordFound = 2,
    SentToVendor = 3,
    UnderReview = 4,
    RecordFound = 5,
    PossibleRecordFound = 6,
    VerificationStopped = 7,
    DocumentRequired = 8,
    OnHold = 9,
    NotApplicable = 10,
    DataEntryDone = 11,
    ClientTerminated = 12
}
