<div *ngIf="lot.id" class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">{{ lot.company_name }}</h1>
        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a [routerLink]="['/companies', lot.company_id]">{{ lot.company_name }}</a></li>
            <li><a [routerLink]="['/lots', lot.id]">{{ lot.name }}</a></li>
            <li>Merge</li>
        </ul>
    </div>
</div>

<form [formGroup]="lotMergeFormGroup">
    <fieldset [disabled]="isSubmitting">
        <div class="wrapper-md">
            <div class="row">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-md-6 panel-title">
                                All Lots
                            </div>
                            <div class="col-md-6 text-right text-center-xs">
                                <button class="btn btn-primary" (click)="lotMerge()"
                                    [disabled]="!lotMergeFormGroup.valid || track_ids.length<=0"> Merge Lots
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">

                        <app-errors [errors]="errors"></app-errors>

                        <div class="row">
                            <div class="col-md-12">
                                <fieldset class="form-group">
                                    <app-multi-select [options]="allLots" [placeholder]="'Select Lots'"
                                        [controlName]="'lot_id'" [formGName]="lotMergeFormGroup"
                                        [multipleValue]="false"></app-multi-select>
                                </fieldset>

                            </div>

                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <input type="file" [accept]="acceptedFileType" [disabled]="isFileDisabled"
                                        (change)="onFileChange($event)" class="form-control" />
                                </fieldset>
                                <fieldset class="form-group">
                                    <a target="_blank"
                                        href="https://docs.google.com/spreadsheets/d/1FxZ08sRdwLR509mVDzwetihIKcksz3ZewNMLf-1I1J8/edit?usp=sharing">Download
                                        Sample</a>
                                </fieldset>

                            </div>
                            <div class="col-md-3" *ngIf="track_ids.length>0">
                                Total Records: {{track_ids.length}};
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>



        <div class="wrapper-md">
            <div class="panel panel-default" *ngIf="employees && !isExcelFileUploaded">
                <div class="panel-heading">
                    <div class="row" *ngIf="employees.data">

                        <div class="col-md-6 panel-title">
                            Showing {{ employees.data.length }} out of total {{ employees.total }} results
                        </div>

                        <div class="col-md-6 text-right text-center-xs">
                            <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                            <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                        </div>
                    </div>
                </div>


                <div class="panel-body">

                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 10%;">
                                    <label class="i-checks m-b-none">
                                        <input type="checkbox" [checked]="isAllChecked" (change)="checkAll($event)">
                                        <i></i>
                                    </label>
                                </th>
                                <th>
                                    Track ID
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let employee of employees.data | paginate
									: {
										id: 'server',
										itemsPerPage: employees.per_page,
										currentPage: employees.current_page,
										totalItems: employees.total
									}">
                                <td>
                                    <label class="i-checks m-b-none">
                                        <input type="checkbox" class="checkbox-all" value="{{employee.track_id}}"
                                            (change)="getValues(employee.track_id,$event)" />
                                        <i></i>
                                    </label>
                                </td>
                                <td>
                                    {{employee.track_id}}
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>

            </div>
            <div class="panel panel-default" *ngIf="employees && isExcelFileUploaded">

                <div class="panel-body">

                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 10%;">
                                    <label class="i-checks m-b-none">
                                        <input type="checkbox" [checked]="isAllChecked" (change)="checkAll($event)">
                                        <i></i>
                                    </label>
                                </th>
                                <th>
                                    Track ID
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let trackIds of excelFormArray.value">

                                <td>
                                    <label class="i-checks m-b-none">
                                        <input type="checkbox" class="checkbox-all" value="{{trackIds.track_id}}"
                                            (change)="getValues(trackIds.track_id,$event)" />
                                        <i></i>
                                    </label>
                                </td>
                                <td>
                                    {{trackIds.track_id}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </div>

    </fieldset>
</form>