import { DialogService } from 'src/app/core/services/dialog.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { VcourtService } from '../../core/services/vcourt.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { CnrParser } from 'src/app/core/interfaces/cnr-parser';

@Component( {
    selector: 'app-vcourt-cnr-details',
    templateUrl: './vcourt-cnr-details.component.html',
    styleUrls: [ './vcourt-cnr-details.component.css' ],
    encapsulation: ViewEncapsulation.ShadowDom
} )
export class VcourtCnrDetailsComponent implements OnInit {

    cnr: string = '';
    ecourtRawHtml: SafeHtml = '';
    filterParams!: Params;

    constructor (
        private activatedRoute: ActivatedRoute,
        private vcourtService: VcourtService,
        private sanitizer: DomSanitizer,
        private dialogService: DialogService
    ) { }

    ngOnInit (): void {

        this.activatedRoute.queryParams.subscribe( params => {
            this.filterParams = { ...params }
            this.fetchCnrDetails( this.filterParams?.[ 'cnr' ] );
        } );

    }


    fetchCnrDetails ( cnr: string ): void {

        this.vcourtService.fetchCnrDetails( cnr, this.filterParams ).subscribe( {
            next: ( cnrDetail: CnrParser ) => {

                if ( !cnrDetail.data.html ) {
                    this.dialogService.confirm( 'We are processing... ', cnrDetail.message );
                    return;
                }

                this.ecourtRawHtml = this.sanitizer.bypassSecurityTrustHtml( cnrDetail.data.html );
                this.dialogService.hide();
            },
            error: ( err ) => {
                console.error( 'Error fetching CNR details:', err );
                this.dialogService.hide();
            },
        } );
    }
}
