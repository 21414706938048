import { DigitalAddressForm } from './../../core/models/address-verification.model';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { Pagination } from 'src/app/core/models/pagination.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfirmationModalService, ConfirmationModalOptions } from 'src/app/core/services/confirmation-modal.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { DigitalAddressService } from 'src/app/core/services/digital-address.service';
import { DigitalAddressStatusType } from 'src/app/core/models/digital-address-status.model';

@Component( {
    selector: 'app-digital-address-list',
    templateUrl: './digital-address-list.component.html'
} )
export class DigitalAddressListComponent extends ExportExcelComponent implements OnInit, OnDestroy {
    pagination: Pagination = new Pagination();
    loading!: boolean;
    filterFormGroup!: FormGroup;
    clients!: any[];
    fetchingInProgress: boolean = false;
    maxEmailFollowUpAllowed: number = 10;
    maxSmsFollowUpAllowed: number = 10;
    filtersSubscription!: Subscription;


    constructor (
        private digitalAddressService: DigitalAddressService,
        public filterService: FilterService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) {
        super();
    }

    ngOnInit (): void {
        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

    filterResults ( params: Params ): void {

        const updatedParams = this.updateParams( params );

        this.digitalAddressService.filter( updatedParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );
    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }

    sendFollowUp ( id: any ): void {

        this.digitalAddressService.sendFollowUp( id ).subscribe( {
            next: () => {

                this.filterService.filter();

                this.commonService.notifications.success( 'Follow Up sent Successfully' );

            }
        } );
    }

    onSendFollowUpButtonClicked ( id: any ): void {

        const options: ConfirmationModalOptions = { text: 'You want to send follow-up' };

        this.confirmationModalService.confirm( options, () => this.sendFollowUp( id ) );

    }

    inviteCreateStatus ( $status: any ): void {

        if ( $status ) {
            this.filterService.filter();
        }
    }

    exportExcel () {

        const updatedParams = this.updateParams( this.filterService.filters );

        const initialState: DownloadExcelInitialState = {
            filterParams: updatedParams,
            paramsString: updatedParams.toString(),
            route: 'digital-address-invites/download-excel',
            fileName: 'Digital Address Form',
            method: 'get',
            postParams: null,
        };

        this.digitalAddressService.showDownloadModal( initialState );
    }

    refreshPage (): void {
        this.filterService.filter();
    }

    toggleInviteStatus ( inviteId: number ): void {

        this.digitalAddressService.toggleDigitalAddressStatus( inviteId ).subscribe(
            response => {

                this.filterService.filter();

                if ( response.success ) {
                    this.commonService.notifications.success( 'Success', 'Status changed successfully' );
                } else {
                    this.commonService.notifications.error( 'error', 'Some error occured' );
                }
            }
        );
    }

    updateParams ( params: any ): HttpParams {

        let httpParams = new HttpParams();

        const multipleValueFilters = [ 'company_id', 'lot_id', 'email_status', 'sms_status' ];

        Object.keys( params ).forEach( key => {
            // Check if the key is in the array of keys to be converted to string
            if ( multipleValueFilters.includes( key ) ) {
                // Convert the value to string and append it to the HttpParams object
                httpParams = httpParams.set( key, params[ key ].toString() );
            } else {
                httpParams = httpParams.set( key, params[ key ] );
            }
        } );

        return httpParams;
    }

    getStatusColor ( status: DigitalAddressStatusType ): string {
        if ( status === DigitalAddressStatusType.PENDING ) {
            return 'label-warning'
        }
        if ( status === DigitalAddressStatusType.COMPLETED ) {
            return 'label-success';
        }
        if ( status == DigitalAddressStatusType.CANCELLED ) {
            return 'label-danger'
        }
        return '';
    }

    showFollowUpButton ( digitalAddressForm: any ): boolean {

        if ( digitalAddressForm.filled_at ) return false;

        if ( digitalAddressForm.sms_count == 0 && digitalAddressForm.email_count == 0 ) return false;

        if ( digitalAddressForm.sms_follow_up_count >= this.maxSmsFollowUpAllowed && digitalAddressForm.email_follow_up_count >= this.maxEmailFollowUpAllowed ) return false;

        return true;
    }

    onDownloadExcel () {

        const updatedParams = this.updateParams( this.filterService.filters );

        const initialState: DownloadExcelInitialState = {
            filterParams: updatedParams,
            paramsString: updatedParams.toString(),
            route: 'digital-address-invites/download-excel',
            fileName: 'Digital Address Invite ',
            method: 'get',
            postParams: null,
        };

        this.digitalAddressService.showDownloadModal( initialState );
    }
}
