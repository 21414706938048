import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';

import { CommonService } from 'src/app/core/services/common.service';
import { InviteService } from 'src/app/core/services/invite.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { validateOnlySpaceNotAllow } from 'src/app/common/validators/only-space-not-allow-validator.directive';

@Component( {
    selector: 'app-form-invite-modal',
    templateUrl: './form-invite-modal.component.html',
} )
export class FormInviteModalComponent implements OnInit {

    forms!: any[];
    message!: string;
    email!: string;
    data!: {};
    inviteForm!: FormGroup;
    isSubmitting: boolean = false;

    @ViewChild( 'sendInvite', { static: true } )
    public sendInvite!: ModalDirective;

    @Output() onInviteCreate = new EventEmitter<boolean>();

    constructor (
        private inviteService: InviteService,
        private commonService: CommonService,
        private fb: FormBuilder,
        public global: GlobalService,
    ) {
    }

    get companies () { return this.global.fetchCompanies(); }

    ngOnInit () {
        this.initForm();
    }

    initForm () {
        this.inviteForm = this.fb.group( {
            'company_id': [ '' ],
            'form_id': [ '', [ Validators.required ] ],
            'form_version': [ null, [ Validators.required ] ],
            'message': [ '' ],
            'candidates': this.fb.array( [ this.initCandidateRows() ] ),
        } );
    }

    onOptionsSelected () {

        const companyId = this.inviteForm.controls[ 'company_id' ].value;

        if ( !companyId ) return;

        this.getFormsList( companyId );
    }

    initCandidateRows () {
        return this.fb.group( {
            email: [ '', [ Validators.required, validateEmail() ] ],
            name: [ '', [ Validators.required, validateAllowedCharacters( '' ) ] ],
            phone_number: [ '', [ validateNumericCharacters( '' ), validateLength( '10' ) ] ],
            client_employee_id: [ null, [ validateOnlySpaceNotAllow() ] ],
        } );
    }

    get candidatesArray (): FormArray {
        return this.inviteForm.get( 'candidates' ) as FormArray;
    }

    addNewCandidate () { // addNewCandid
        // control refers to your form array
        const control = <FormArray> this.inviteForm.controls[ 'candidates' ];
        // add new formgroup
        control.push( this.initCandidateRows() );
    }

    deleteCandidate ( index: number ) {
        // control refers to your form array
        const control = <FormArray> this.inviteForm.controls[ 'candidates' ];
        // remove the chosen row
        control.removeAt( index );
    }

    show () {
        this.forms = [];

        this.initForm();

        this.sendInvite.show();
    }

    getFormsList ( id: number ) {
        const data = { 'companyId': id };

        this.inviteService.getFormsList( data ).subscribe(
            response => {

                response.push( { id: "", name: "Select Batch" } );

                this.forms = response;
            }
        );
    }

    hide () {
        this.initForm();

        this.sendInvite.hide();
    }

    sendMail () {

        this.isSubmitting = true;
        this.inviteService.sendMail( this.inviteForm.value ).subscribe(
            ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] );
                this.onInviteCreate.emit( true );
                this.isSubmitting = false;
                this.hide();

            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to send mail' );
                this.isSubmitting = false;
                this.hide();
            }
        );

    }

}
