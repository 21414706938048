import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { Pagination } from '../models/pagination.model';
import { VcourtJob } from '../models/vcourt-job.model';
import { HttpParams } from '@angular/common/http';
import { CnrParser } from '../interfaces/cnr-parser';


@Injectable()
export class VcourtService {

    constructor ( private api: ApiService ) {
    }

    create ( values: Object ): Observable<any[]> {
        return this.api.post( 'vcourt/', values );
    }

    getVcourtJob ( id: number ): Observable<VcourtJob> {
        return this.api.get( 'vcourt/' + id + '/get-job' );
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'vcourt', params );
    }

    fetchCases ( id: number ): Observable<any> {
        return this.api.get( 'vcourt/' + id + '/fetch-cases' );
    }

    getCasesByJobId ( id: number, params: HttpParams ): Observable<any> {
        return this.api.get( 'vcourt/' + id, params );
    }

    markJobStatusAs ( values: any[] ): Observable<any[]> {
        return this.api.post( 'vcourt/jobs/mark-job-status', values );
    }

    markCaseStatusAs ( values: any[] ): Observable<any[]> {
        return this.api.post( 'vcourt/jobs/mark-case-status', values );
    }

    navigate ( id: number, params: HttpParams ): Observable<any[]> {
        return this.api.get( 'vcourt/navigate/' + id, params );
    }

    fetchCasesForPendingJobs ( jobIds: any[] ) {
        return this.api.post( 'vcourt/fetch-all', jobIds );
    }

    getDataForExcel ( params: HttpParams ) {
        return this.api.get( 'vcourt/download-excel', params );
    }

    markWorkComplete ( values: Params ): Observable<any[]> {
        return this.api.post( 'vcourt/jobs/mark-work-complete', values );
    }

    fetchCnrDetails ( cnr: string, params: Params ): Observable<CnrParser> {
        return this.api.get( 'vcourt/fetch-cnr/' + cnr, params );
    }
}

