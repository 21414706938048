<form [formGroup]="verificationFormGroup" (ngSubmit)="updateVerification('employment')"
    (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">

        <div *ngIf="employee && employee.id" class="row bg-light lter b-b wrapper-md">

            <app-verification-edit-header [verification]="'employment'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>

            <app-employment-create-company #companyCreateModal (onCompanyCreated)="onCompanyCreated()">
            </app-employment-create-company>

            <div class="col-md-6">
                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="companyCreateModal.show()">
                    <i class="fa fa-plus"></i>Create Company
                </a>


                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createEmployment()">
                    <i class="fa fa-plus"></i>
                    Add Employment Verification
                </a>
                <a class="btn m-b-xs btn-sm btn-primary btn-addon" routerLink="/employment/companies">
                    All Companies
                </a>
                <a class="btn m-b-xs btn-sm btn-primary btn-addon" *ngIf="isEmailDisabled"
                    (click)="onSendConsentFormButtonClicked(verificationFormGroup.get('id')?.value)">
                    Send Consent Mail
                </a>
            </div>

        </div>


        <div class="wrapper-md">
            <div class="row">

                <div class="col-md-3" *ngIf="employee">

                    <!--Remarks-->
                    <app-employee-remarks *ngIf="employee" [remarks]="employee.remarks"></app-employee-remarks>

                    <!--Employee Details-->
                    <app-employee-side-block [refreshEmployee]="refreshEmployee"></app-employee-side-block>
                    <app-employee-basic-details [employee]="employee" [submitting]="isSubmitting">
                    </app-employee-basic-details>

                    <!--Employee Consent Form-->
                    <app-employee-consent-form *ngIf="employee.documents" [employeeDocuments]="employee.documents"
                        (onConsentUpdated)="verificationUpdated()"></app-employee-consent-form>


                    <!-- Invited Employee Fields Response -->
                    <app-employee-invite-form-response *ngIf="employee?.invite_response"
                        [inviteResponse]="employee.invite_response">
                    </app-employee-invite-form-response>


                </div>

                <div class="col-md-9">
                    <div formArrayName="formArray">
                        <div *ngFor="let verification of formArray.controls; let i=index" [formGroupName]="i">

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    Employment Verification #{{i+1}}
                                </div>

                                <app-employment-form-item *ngIf="verification" [current_date]="current_date"
                                    [created_at]="employee.created_at.slice(0,10)"
                                    [verificationFormGroup]="verificationFormGroup" [formArray]="formArray"
                                    [editFormGroup]="verification" [formGName]="i"
                                    [employmentCompanies]="['employmentCompanies']" [isSubmitting]="isSubmitting"
                                    [verification]="verification" (onVerificationUpdated)="verificationUpdated()">
                                </app-employment-form-item>

                                <div class="panel-footer">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <a class="btn btn-primary"
                                                *ngIf="verification.get('employment_company_id')?.value"
                                                (click)="sendEmail( verification)">
                                                Send Mail
                                            </a>
                                            <a class="btn btn-primary ml-3" (click)="sendFollowUpMail(verification)"
                                                *ngIf="verification.get('employment_company_id')?.value"
                                                style="margin-left: 15px;">
                                                Send Follow Up Mail
                                            </a>
                                        </div>
                                        <app-employment-email-log-modal #emailLogModal></app-employment-email-log-modal>
                                        <div class="col-md-offset-9 justify-content-evenly" [appCan]="'admin'">
                                            <a class="btn btn-primary"
                                                (click)="emailLogModal.show(verification.get('id')?.value)">
                                                Email Logs
                                            </a>
                                            <a class="btn btn-danger" style="margin-left: 15px;"
                                                (click)="onDeleteVerificationButtonClicked(verification.get('id')?.value,i)">
                                                Delete Verification
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>