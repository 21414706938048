import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Injectable, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from '../../models/employee.model';
import { ShortcutService } from '../../services/shortcut.service';
import { ImageCropComponent } from '../image-crop/image-crop.component';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common.service';
import { FileUploader } from 'ng2-file-upload';
import { Attachment } from '../../models/attachment.model';
import { ConfirmationModalOptions, ConfirmationModalService } from '../../services/confirmation-modal.service';


@Component( {
    selector: 'app-identity-edit',
    template: ' ',
} )
// @Injectable( {
//     providedIn: 'root'
// } )
export class VerificationEditComponent implements OnInit, OnDestroy {

    verification!: any;
    employee!: Employee;
    employeeId!: number;
    verificationFormGroup!: FormGroup;
    isSubmitting!: boolean;
    filterParams: HttpParams = new HttpParams();
    uploader: FileUploader = new FileUploader( { url: '' } );
    crmUrl: string;
    editable: any[] = [];
    attachments: Attachment[] | any = [];
    date: Date = new Date();
    current_date: string = new Date( this.date.getTime() - ( this.date.getTimezoneOffset() * 60000 ) ).toJSON().slice( 0, 10 );

    maxFileSize: number = 300;
    maxFileError: boolean = false;
    cropper!: ImageCropComponent;
    refreshEmployee: Subject<any> = new Subject();
    next!: Subscription;
    back!: Subscription;

    readonly GLOBAL_DB_VERIFICATION_TYPE: string = "global-db"
    readonly GLOBAL_DB_VERIFICATION_RELATION: string = "global_db_verifications"

    @ViewChild( 'cropper', { static: false } )

    protected viewContainerRef!: ViewContainerRef;
    protected verificationService: any;

    constructor (
        protected route: ActivatedRoute,
        protected router: Router,
        protected fb: FormBuilder,
        protected common: CommonService,
        protected confirmationModalService: ConfirmationModalService,
        protected http: HttpClient,
        protected el: ElementRef,
        protected shortcuts: ShortcutService,
    ) {
        this.crmUrl = environment.old_crm_url;
        this.isSubmitting = true;
    }


    ngOnInit (): void {


    }

    subscribeToQueryChanges (): void {

        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
        } );
    }


    get formArray (): FormArray | any {
        return this.verificationFormGroup.get( 'formArray' ) as FormArray;
    }

    handleUpdateResponse ( response: any ): void {

        if ( response.navigation_id === 'none' ) {

            this.showVerificationPage();
            this.common.notifications.warning( 'WARNING', 'NO CANDIDATE AVAILABLE TO NAVIGATE' );

        } else {

            this.showVerification( response.navigation_id );
        }
    }

    showVerificationPage (): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'merge',
        };

        const link: any[] = [ this.verification ];
        this.router.navigate( link, navigationExtras );
    }

    showVerification ( employeeId: number ): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'merge',
        };

        const link = [ this.verification, employeeId, 'edit' ];
        this.router.navigate( link, navigationExtras );

        this.verificationService.findByEmployeeId( employeeId ).subscribe(
            ( employee: Employee ) => {
                this.employee = employee;
            }
        );
    }


    checkFileSize ( inputEl: HTMLInputElement | any, fileCount: number ): void {

        let fileSize: number = 0;

        for ( let i: number = 0; i <= fileCount; i++ ) {
            if ( inputEl.files[ i ] ) {
                fileSize = inputEl.files[ i ].size / 1024;
                if ( fileSize > this.maxFileSize ) {
                    this.maxFileError = true;
                    this.common.notifications.error( 'Unable to upload!! File size must be less than 300KB' );
                }
            }
        }
    }

    upload ( verification: FormGroup | any, typeOfFile: string ): void {

        const verificationId: number = verification.value.id;

        // Locate the file element meant for the file upload.
        const inputEl: HTMLInputElement | any = this.el.nativeElement.querySelector( '#' + typeOfFile + '_' + verificationId );

        // Get the total amount of files attached to the file input.
        const fileCount: number = inputEl.files.length;

        this.checkFileSize( inputEl, fileCount );

        if ( !this.maxFileError ) {

            // Create a new form data instance
            const formData: FormData = new FormData();

            // Check if the file count is greater than zero, to be sure a file was selected.
            if ( fileCount > 0 ) { // a file was selected

                for ( let i: number = 0; i < fileCount; i++ ) {

                    formData.append( 'file_' + i, inputEl.files.item( i ) );
                }

                formData.append( 'type', typeOfFile + '_' + verificationId );

                const url: string = environment.api_url + this.verification + '/' + verification.value.id + '/upload';

                const headers: HttpHeaders = new HttpHeaders( { Authorization: 'Bearer ' + localStorage.getItem( 'token' ) } );


                // TODO:: move http to service

                this.http.post( url, formData, { headers } ).pipe(

                    map( ( res: any ) => res ) ).subscribe(
                        ( success: any ) => {

                            if ( verification.get( typeOfFile ) ) {
                                verification.get( typeOfFile )?.setValue( success[ 'file_name' ] );
                            }
                            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                                ( employee: Employee ) => {
                                    this.employee = employee;
                                    this.getAttachments( employee );
                                },
                            );
                            this.common.notifications.success( 'File uploaded Successfully' );
                        },
                        ( error ) => this.common.notifications.error( 'Unable to upload File' )
                    );
            }
        }
        this.maxFileError = false;
    }

    getAttachments ( employee: Employee | any ): void {

        this.employee = employee;

        let relationName: string = "";

        // TODO: Refactor usage of this keyword to avoid if-else check

        if ( this.verification == this.GLOBAL_DB_VERIFICATION_TYPE ) {

            relationName = this.GLOBAL_DB_VERIFICATION_RELATION;

        } else {

            relationName = this.verification + '_verifications';

        }

        for ( const index in employee[ relationName ] ) {

            const verification: any = employee[ relationName ][ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }


    /**
     * Zoom In Button
     *
     * @param imageId
     */
    zoomInImage ( imageId: string ): void {

        const element = document.getElementById( imageId );

        if ( element ) {

            const currentWidth: number = parseInt( ( element.style.width.replace( '%', '' ) ), 10 );
            const currentHeight: number = parseInt( ( element.style.height.replace( '%', '' ) ), 10 );

            const newWidth: number = currentWidth + currentWidth / 5;
            const newHeight: number = currentHeight + currentHeight / 5;

            if ( newWidth < 250 ) {
                element.style.width = newWidth.toString() + '%';
                element.style.height = newHeight.toString() + '%';
            }
        }
    }

    /**
     * Zoom Out Button
     *
     * @param imageId
     */
    zoomOutImage ( imageId: string ): void {

        const element = document.getElementById( imageId );

        if ( element ) {

            const currentWidth: number = parseInt( ( element.style.width.replace( '%', '' ) ), 10 );
            const currentHeight: number = parseInt( ( element.style.height.replace( '%', '' ) ), 10 );

            const newWidth: number = currentWidth - currentWidth / 5;
            const newHeight: number = currentHeight - currentHeight / 5;

            if ( newWidth > 50 ) {
                element.style.width = newWidth.toString() + '%';
                element.style.height = newHeight.toString() + '%';
            }
        }
    }

    deleteVerification ( verificationId: number, verificationArrayKey: number ): void {
        this.verificationService.deleteVerification( verificationId ).subscribe(
            ( response: any ) => {

                // Remove the deleted verification from array and hide the row from table
                this.formArray.removeAt( verificationArrayKey );

                this.common.notifications.success( 'Verification Deleted', 'Verification Deleted Successfully' );

            },
            () => {
                this.common.notifications.error( 'Error Occurred', 'Unable to delete Verification' );
            }
        );
    }

    /**
     *
     * @param verificationId
     * @param verificationArrayKey | key of the verifications array
     */
    onDeleteVerificationButtonClicked ( verificationId: number, verificationArrayKey: number ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.deleteVerification( verificationId, verificationArrayKey ) );

    }

    ngOnDestroy (): void {


    }

    /**
     * Disable fields if completed at is present
     *
     * @param {AbstractControl} form
     */
    protected disableFields ( form: AbstractControl ): void {

        if (
            form.get( 'completed_at' ) &&
            form.get( 'completed_at' )?.value &&
            this.common.userLevelId() < 4 &&
            form.get( 'status_id' )?.value !== 31
        ) {

            form.disable();

            this.editable.push( true );

        } else {
            form.enable();
            this.editable.push( false );
        }

    }

}
