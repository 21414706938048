<div class="row bg-light lter b-b wrapper-md" style="margin-top: 40px;">

    <div class="col-md-6">
        <h3 class="m-n h3">VCourt: Candidate Details</h3>

        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a routerLink="/vcourt">Vcourt</a></li>
            <li *ngIf="vcourtJob.company_id">
                <a [routerLink]="['/companies', vcourtJob.company_id]">
                    {{vcourtJob.company_name }}
                </a>
            </li>
            <li *ngIf="vcourtJob.lot_id"><a [routerLink]="['/lots', vcourtJob.lot_id]">{{ vcourtJob.lot_name }}</a></li>
            <li><a [routerLink]="['/employees', vcourtJob.employee_id,'edit']"> {{ vcourtJob.track_id }}</a></li>
        </ul>

    </div>
    <div class="col-md-6 text-right">
        <div class="btn-group">
            <button (click)="navigate('previous')" class="btn btn-default btn-addon">
                <i class="fa fa-arrow-left"></i>Back
            </button>
            <button (click)="navigate('next')" class="btn btn-default btn-addon">
                <i class="fa fa-arrow-right"></i>Next
            </button>
        </div>

        <ul class="breadcrumb insta-breadcrumb">
            <li><strong>Icr Status: {{vcourtJob.icr_status}}</strong></li>
        </ul>
    </div>
</div>

<!--Basic Details Fixed Panel-->
<div style="position: fixed;background: #ddd;z-index: 1000;margin: 0px 0px 0px 0px;padding: 9px 0px 8px 20px;
    top: 50px;width: 100%;font-size: 13px;">

    <div class="row">
        <div class="col-md-3">
            NAME: {{vcourtJob.name}}
        </div>
        <div class="col-md-3">
            FATHER NAME: {{vcourtJob.father_name}}
        </div>

        <div class="col-md-3">
            DOB: {{vcourtJob.dob}}
        </div>

        <div class="col-md-3">
            JOB ID: {{vcourtJob.job_id}}
        </div>

    </div>
    <div class="row">
        <div class="col-md-9">
            ADDRESS: {{vcourtJob.address}}
        </div>
        <div class="col-md-3" style="padding-left: 117px">
            TRACK ID: {{vcourtJob.track_id}}
        </div>
    </div>
</div>

<!--Filters-->


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="form-group col-sm-2" *ngIf="vcourtJob.type =='candidate'">
                    <label class="center-block">Filter By Name</label>
                    <input class="form-control" [(ngModel)]="nameSearch">
                </div>
                <div class="form-group col-sm-2" *ngIf="vcourtJob.type =='candidate'">
                    <label class="center-block">Filter By Case Address</label>
                    <input class="form-control" [(ngModel)]="addressSearch">
                </div>
                <div class="form-group col-sm-2" *ngIf="vcourtJob.type =='candidate'">
                    <label class="center-block">Filter By District </label>
                    <input class="form-control" [(ngModel)]="districtSearch">
                </div>

                <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                    <div class="form-group col-sm-2">
                        <label class="center-block">Filter By Status</label>
                        <select class="form-control" formControlName="exact_match_status">
                            <option [value]="all"> Select Status</option>
                            <option [value]="1"> RF</option>
                            <option [value]="2"> PRF</option>
                            <option [value]="3"> Offender</option>
                            <option [value]="4"> All</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-2">
                        <label class="center-block"> Apply Filters</label>
                        <button class="btn btn-primary">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-sm-2">
                        <label class="center-block"> Remove Filters</label>
                        <button class="btn btn-danger" (click)="removeFilters()">
                            Remove Filters
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-2">
            <button class="btn btn-default btn-addon" (click)="fetchCasesAgain($event)">
                <i class="fa fa-refresh"></i>
                Fetch Results Again
            </button>

            <a *ngIf="vcourtJob.via=='verify24*7' && vcourtJob.type=='company'"
                class="btn btn-link text-main text-semibold" target="_blank"
                href="http://api.verify24x7.in:50150/check_status?verify_id={{vcourtJob.job_id}}&user=sotsearchinstaveritas&auth_token=aje841c4dc1d706cd73538f9f16f1d55ad65">
                Direct Link
            </a>
        </div>
        <div class="col-md-4">
            <button class="btn btn-default btn-addon" (click)="refreshPage()">
                <i class="fa fa-refresh"></i>
                Refresh Page
            </button>
        </div>

        <div class="col-sm-6 text-right" *ngIf="vcourtJob.track_id">

            <button class="btn-group btn btn-danger" *ngIf="incompleteWorkList.includes('Red Case L1')"
                (click)="markWorkComplete('Red Case L1')" style="margin-right:18px ">
                Red L1 Task Completed
            </button>

            <button class="btn-group btn btn-success" *ngIf="incompleteWorkList.includes('NRF Case L1')"
                (click)="markWorkComplete('NRF Case L1')" style="margin-right:18px ">
                NRF L1 Task Completed
            </button>

            <button class="btn-group btn btn-warning" (click)="markJobStatusAs('Level Three')"
                style="margin-right:18px ">
                Level Three
            </button>

            <button class="btn-group btn btn-warning" (click)="markJobStatusAs('Level Three - Calling')"
                style="margin-right:18px ">
                Level Three Calling
            </button>

            <button class="btn-group btn btn-success" (click)="markJobStatusAs('No Record Found')"
                style="margin-right:18px ">
                No Record Found
            </button>
            <button class="btn-group btn btn-warning" (click)="markJobStatusAs('Under Review')"
                style="margin-right:18px ">
                Under Review
            </button>
            <button class="btn-group btn btn-warning" (click)="markJobStatusAs('Case Reviewed')">
                Case Reviewed
            </button>
        </div>
    </div>

    <br>

    <!--Table-->
    <div *ngIf="vcourtJob.type=='company' && pagination">
        <app-vcourt-job-show-company-search-results [vcourtJob]="vcourtJob" [pagination]="pagination">
        </app-vcourt-job-show-company-search-results>
    </div>

    <div *ngIf="vcourtJob.type=='candidate' && results">
        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="row">
                    <div class="col-md-6 panel-title" *ngIf="results">
                        Total Number of
                        Cases:{{(results|vcourtResultsFilter:nameSearch:addressSearch:districtSearch).length}}
                    </div>

                    <div class="col-md-6 text-right text-center-xs">
                        <a *ngIf="vcourtJob.duplicate_case_id" [routerLink]="['/vcourt', vcourtJob.duplicate_case_id]"
                            style="font-weight: 100;color: red" class="btn btn-link text-main text-semibold">
                            Duplicate of {{ vcourtJob.duplicate_case_id }} Case
                        </a>
                        <a *ngIf="vcourtJob.via=='verify24*7';else instaveritasDirectLink"
                            class="btn btn-link text-main text-semibold" target="_blank"
                            href="http://api.verify24x7.in:50145/check_status?verify_id={{vcourtJob.job_id}}&user=instaveritas&auth_token=5109247b545b6e5053c125dd91809f3c">
                            Direct Link
                        </a>
                        <ng-template #instaveritasDirectLink>
                            <a class="btn btn-link text-main text-semibold" target="_blank"
                                href="http://ecourts.instaveritas.com/api/search?name={{vcourtJob.name}}&fathers_name={{vcourtJob.father_name}}&address={{vcourtJob.address}}&district={{ vcourtJob.district}}&pincode={{ vcourtJob.pincode}}">
                                Direct Link
                            </a>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width:16%;">Name</th>
                            <th style="width:40%;">Address</th>
                            <th style="width:17%;">State & District</th>
                            <th style="width:19%;">Case Details</th>
                            <th style="width:11%;">Type/Category</th>
                            <th style="width:8%;">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let result of results| vcourtResultsFilter: nameSearch:addressSearch:districtSearch"
                            [ngClass]="{ 'bg-danger' : result.exact_match }" id="{{result.id}}">

                            <td>
                                <ul class="list-unstyled">
                                    <li innerHTML=" {{result.name|highlight: nameSearch}}"></li>
                                    <li>Case ID: <b>{{result.id}}</b></li>
                                </ul>

                                <a href="#" class="text-info btn-link" [popover]="popTemplate"
                                    triggers="mouseenter:mouseleave" container="body">More Details</a>

                                <ng-template #popTemplate>
                                    <ul class="list-unstyled">
                                        <li> {{result.court_name}}</li>
                                        <li> Status: {{result.case_status}}</li>
                                        <li> Score: {{result.score}}</li>
                                    </ul>
                                </ng-template>
                            </td>
                            <td innerHTML=" {{result.address|highlight: addressSearch}}" style="word-wrap: break-word">
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li innerHTML=" State: {{result.state|highlight: stateSearch}}"></li>
                                    <li innerHTML=" District: {{result.district|highlight: districtSearch}}"></li>
                                </ul>
                            </td>

                            <td>
                                <ul class="list-unstyled">
                                    <li>Year: {{result.case_year}}</li>
                                    <li>Acts: {{result.under_acts}}</li>
                                    <li>Sections: {{result.under_sections}}</li>
                                    <li *ngIf="result.cnr_number; else noCnr;">
                                        <a [routerLink]="['../fetch-cnr']" [queryParams]="{cnr: result.cnr_number}"
                                            target="_blank" class="text-info btn-link">Cnr Details
                                        </a>
                                    </li>
                                    <ng-template #noCnr>
                                        <li class="text-muted">Cnr not available</li>
                                    </ng-template>
                                </ul>
                            </td>
                            <td>
                                <a href="{{result.link}}" target="_blank" class="text-info btn-link">Link</a>
                                <ul class="list-unstyled">
                                    <li>
                                        Type: {{result.case_type}}
                                    </li>
                                    <li>
                                        Category: {{result.case_category}}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <div class="btn-group-vertical">
                                    <button class="btn btn-default" (click)="markCaseStatusAs('rf',result)">
                                        <i class="fa fa-check" *ngIf="result.exact_match==='RF'"> </i>
                                        RF
                                    </button>
                                    <button class="btn btn-default btn-xs" (click)="markCaseStatusAs('prf',result)">
                                        <i class="fa fa-check" *ngIf="result.exact_match==='PRF'"> </i>
                                        PRF
                                    </button>
                                    <button class="btn btn-default btn-xs"
                                        (click)="markCaseStatusAs('offender',result)">
                                        <i class="fa fa-check" *ngIf="result.exact_match==='Offender'"> </i>
                                        Offender
                                    </button>
                                    <button *ngIf="result.exact_match" class="btn btn-default btn-xs"
                                        (click)="markCaseStatusAs('status_removed',result)">
                                        Remove Status
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


</div>