
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { switchMap } from 'rxjs/operators';
import { Client } from '../../core/models/client.model';
import { ClientService } from '../../core/services/client.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-client-edit',
    templateUrl: './client-edit.component.html',
    styleUrls: [ './client-edit.component.css' ],
} )
export class ClientEditComponent implements OnInit {

    client: Client = new Client();
    editForm: FormGroup;
    isSubmitting: boolean = false;
    lots!: any[];
    routes!: any[];

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private clientService: ClientService,
        private fb: FormBuilder,

        private commonService: CommonService
    ) {
        this.editForm = this.fb.group( {
            id: [],
            name: [ '', [ Validators.required, Validators.maxLength( 255 ) ] ],
            email: [ '', [ Validators.required, Validators.email ] ],
            mobile_number: [ '', Validators.pattern( '^[0-9]{10}$' ) ],
            designation: [ '', Validators.maxLength( 255 ) ],
            lot_id: [ [], Validators.required ],
            access_ids: [ [], Validators.required ],
        } );
    }

    ngOnInit (): void {

        this.getClientRoutes();

        this.getClient();
    }

    updateClient (): void {

        this.isSubmitting = true;
        this.clientService.update( this.editForm.value.id, this.editForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Client Updated' );
                this.router.navigate( [ '/clients' ] );
            }
        );
    }

    getClientRoutes (): void {

        this.commonService.getClientRoutes().subscribe(
            response => {
                response.push( {
                    id: 'all',
                    name: 'All',
                } );
                this.routes = response;
            }
        );
    }

    getClient (): void {

        this.route.paramMap.pipe(
            switchMap( ( params: ParamMap ) => this.clientService.find( +params.get( 'id' )! ) ) )
            .subscribe( response => {
                this.client = response;
                response.lots.push( {
                    id: 'all',
                    name: 'All',
                } );
                this.lots = response.lots;

                this.editForm.patchValue( this.client );
            } );
    }

}
