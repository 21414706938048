import { LotService } from './../../core/services/lot.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Errors } from '../../core/models/errors.module';
import { ClientService } from '../../core/services/client.service';
import { CommonService } from '../../core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { User, UserLevel } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';

@Component( {
    selector: 'app-client-create',
    templateUrl: './client-create.component.html',
    styleUrls: [ './client-create.component.css' ],
} )
export class ClientCreateComponent implements OnInit {
    createForm!: FormGroup;
    isSubmitting: boolean = false;
    errors: Errors = new Errors();
    lots: any[] = [];
    routes!: any[];
    spocUsers: User[] = [];

    constructor (
        private router: Router,
        private clientService: ClientService,
        private fb: FormBuilder,
        public global: GlobalService,
        private commonService: CommonService,
        private userService: UserService,
        private lotService: LotService
    ) { }

    get companies () {
        return this.global.fetchCompanies();
    }

    get users (): User[] {
        return this.global.fetchUsers();
    }

    ngOnInit (): void {

        this.initForm();

        this.getClientRoutes();

        this.getSpocUserList();
    }

    getSpocUserList () {

        const levelIds = [ UserLevel.Admin, UserLevel.SuperAdmin ];

        this.userService.getUsersByLevelId( levelIds ).subscribe( response => {
            this.spocUsers = response;
        } );
    }

    initForm (): void {

        this.createForm = this.fb.group( {
            id: [],
            name: [ '', [ Validators.required, Validators.maxLength( 255 ) ] ],
            email: [ '', [ Validators.required, Validators.email ] ],
            company_id: [ '', Validators.required ],
            lot_id: [ [], Validators.required ],
            mobile_number: [ '', Validators.pattern( '^[0-9]{10}$' ) ],
            designation: [ '', Validators.maxLength( 255 ) ],
            access_ids: [ [], Validators.required ],
            spoc_user_id: [ [], Validators.required ],
            send_password: [ true, Validators.required ],
        } );
    }


    getClientRoutes (): void {

        this.commonService.getClientRoutes().subscribe( response => {
            response.push( {
                id: 'all',
                name: 'All',
            } );
            this.routes = response;
        } );
    }

    createClient (): void {

        this.isSubmitting = true;
        this.clientService.create( this.createForm.value ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( 'Client Created' );
                this.router.navigate( [ '/clients' ] );
            },
            error: ( error ) => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            }
        }
        );
    }

    onCompanySelected (): void {

        const companyId: number = this.createForm.controls[ 'company_id' ].value;

        if ( !companyId ) return;

        this.lotService.getLotsByCompanyId( companyId ).subscribe( response => {
            response.push( {
                id: 'all',
                name: 'All',
            } );
            this.lots = response;
        } );
    }
}
