<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Pending Jobs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="refresh()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>
<div class="wrapper-md">
    <div class="panel panel-default col-md-offset-2 col-md-8">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 15%">Queue Name</th>
                        <th style="width: 15%">Pending Jobs</th>
                        <th style="width: 50%">Count Of Jobs</th>
                        <th *ngIf="isSuperAdmin" style="width: 20%">Clear Pending Jobs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of data">

                        <td>
                            <strong>{{log.name}}</strong>
                        </td>

                        <td>
                            {{log.total_count}}
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li *ngFor="let job of log.job_wise_count">
                                    {{job.name}} : {{job.count}}
                                </li>
                            </ul>
                        </td>
                        <td *ngIf="isSuperAdmin">
                            <a class="btn btn-danger" (click)="onclearQueueButtonClicked(log.name)">Clear
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
