import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VcourtService } from '../../core/services/vcourt.service';
import { VcourtJob } from '../../core/models/vcourt-job.model';
import { Pagination } from '../../core/models/pagination.model';
import { VcourtResult } from '../../core/models/vcourt-result.model';
import { Router, ActivatedRoute, NavigationExtras, ParamMap, Params } from '@angular/router';
import { Employee } from '../../core/models/employee.model';
import { HttpParams } from '@angular/common/http';
import { Lot } from '../../core/models/lot.model';
import { CommonService } from '../../core/services/common.service';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-vcourt-job-list',
    templateUrl: './vcourt-job-list.component.html',
} )
export class VcourtJobListComponent extends ExportExcelComponent implements OnInit {
    loading!: boolean;
    jobs: VcourtJob[] = [];
    jobIds: any = [];
    results: VcourtResult[] = [];
    pagination: Pagination = new Pagination();
    employee: Employee = new Employee();
    filterParams: HttpParams = new HttpParams();
    // Added variables for filters.
    all: string = 'all';
    instaveritas: string = 'instaveritas';
    verify247: string = 'verify24*7';
    lots: Lot[] = [];

    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    constructor (
        private vcourtService: VcourtService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        public global: GlobalService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) {
        super();
        this.initForm();
    }

    get companies () {
        return this.global.fetchCompanies();
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            company_id: 'all',
            candidate_name: '',
            track_id: '',
            address: '',
            job_status: 'all',
            lot_id: 'all',
            via: 'all',
            vcourt_created_after: 'all',
            vcourt_created_before: 'all',
            page: 'all',
        } );
    }

    ngOnInit () {


        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );

            this.setDefault();

            this.filterResults();
        } );
    }

    private setDefault () {

        this.filterParams.keys().forEach( key => {

            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    filterResults (): void {
        this.vcourtService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );
    }

    filterQueriedResults (): void {

        this.filterParams = new HttpParams();

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };


        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );

    }

    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    showResultsByJobId ( id: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            fragment: this.filterParams.toString(),
            queryParamsHandling: 'merge',
        };

        const link = [ '/vcourt/', id ];
        this.router.navigate( link, navigationExtras );
    }

    // Fetch the cases if not fetched.
    refreshCases ( event: any, id: number ) {
        event.target.innerHTML = 'Fetching Cases..';


        this.vcourtService.fetchCases( id ).subscribe( ( response ) => {

            if ( response.success == true ) {
                event.target.className = 'btn btn-xs btn-success';
                event.target.innerHTML = response.message;
            }
            else {
                event.target.className = 'btn btn-xs btn-danger';
                event.target.innerHTML = response.message;
            }
        }
        );


    }

    onCompanySelected (): void {

        const companyId = this.filterFormGroup.controls[ 'company_id' ].value;

        if ( !companyId ) return;

        // Get the lots for the selected company in dropdown
        this.commonService.getLotsByCompanyId( companyId ).subscribe(
            response => {
                response.push( {
                    id: 'all',
                    name: 'All',
                } );
                this.lots = response;
            }
        );
        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        this.filterFormGroup.controls[ 'lot_id' ].setValue( 'all' );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    fetchCasesForPendingJobs (): void {

        this.vcourtService.fetchCasesForPendingJobs( this.jobIds ).subscribe( {
            next: ( response: any ) => {

                this.pagination = new Pagination();

                this.commonService.notifications.success( 'Successful', response[ 'message' ] );

                this.filterResults();

            },
            error: ( error: any ) => {

                this.commonService.notifications.error( 'Error', 'There is some error' );

            }
        } );
    }

    /**
     * Fetch the cases for the jobs whose status is "Pending"
     */
    onFetchCasesForPendingJobsButtonClicked (): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.fetchCasesForPendingJobs() );

    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( option: any, data: any ) {
        if ( data.target.checked ) {
            this.jobIds.push( option )
        } else {
            const index = this.jobIds.indexOf( option );
            if ( index !== -1 ) {
                this.jobIds.splice( index, 1 );
            }
        }
    }

    checkAll ( event: any ) {
        if ( event.target.checked ) {
            // TODO: Can be improved
            for ( let i = 0; i < this.pagination.data.length; i++ ) {
                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all' )[ i ] ).checked = true;
                this.jobIds.push( this.pagination.data[ i ].id );
            }

        } else {
            for ( let i = 0; i < this.pagination.data.length; i++ ) {
                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all' )[ i ] ).checked = false;
                this.jobIds = [];
            }

        }
    }

    onNewRequestSubmit () {
        this.filterResults();
    }

    exportExcel () {
        this.vcourtService.getDataForExcel( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'vcourt-billing-excel' );
            } );
    }


}
