<div *ngIf="employee && employee.id" class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">{{ employee.name }} Verification</h1>
        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a [routerLink]="['/companies', employee.company_id]">{{ employee.company_name }}</a></li>
            <li><a [routerLink]="['/lots', employee.lot_id]">{{ employee.lot_name }}</a></li>
            <li><a [routerLink]="['/employees', employee.id,'edit']"> {{ employee.track_id }}</a></li>
            <li>Created on : {{ employee.created_at }}</li>
        </ul>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <div class="btn-group">
            <button [disabled]="!editForm.valid" (click)="updateAndBack()" class="btn btn-default btn-addon">
                <i class="fa fa-arrow-left"></i>Back
            </button>
            <button [disabled]="!editForm.valid" (click)="updateAndRefresh()" class="btn btn-default btn-addon">
                <i class="fa fa-refresh"></i>Refresh
            </button>
            <button [disabled]="!editForm.valid" (click)="updateAndNext()" class="btn btn-default btn-addon">
                <i class="fa fa-arrow-right"></i>Next
            </button>
        </div>

        <!-- <ul class="breadcrumb insta-breadcrumb">
            <li><a [href]="[crmUrl+'employees/'+ employee.id+'/report']"> Report</a></li>
            <li><a [href]="[crmUrl+'employees/'+ employee.id+'/report?download=yes']">Download Report</a></li>
            <li><a routerLink="/employees/{{employee.id}}/history"> History </a></li>
        </ul> -->

        <!-- modal way to open report -->
        <ul class="breadcrumb insta-breadcrumb">
            <li><a [href]="[crmUrl+'employees/'+ employee.id+'/report']" target="_blank">View Report</a></li>
            <li><a downloadDocument [employee]="employee" documentType="pdf" [href]="downloadReportLink">Download
                    Report</a></li>
            <li><a routerLink="/employees/{{employee.id}}/history"> History </a></li>
        </ul>

    </div>
</div>

<app-pdf-viewer-popup #pdfViewerModal [reportPdfUrl]="reportPdfUrl"
    (onReload)="onReportReload()"></app-pdf-viewer-popup>

<div class="wrapper-md" *ngIf="employee">

    <div class="row">

        <!--Update Form-->
        <div class="col-md-9">

            <div class="col-md-12">
                <form [formGroup]="editForm">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            &nbsp; Employee Basic Details
                        </div>

                        <div class="panel-body">

                            <div class="row">

                                <div class="form-group col-md-3">
                                    <label>Name</label>
                                    <input formControlName="name" class="form-control" type="text" />
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Client Employee Id</label>
                                    <input formControlName="client_employee_id" class="form-control" type="text" />

                                </div>
                                <div class="form-group col-md-3">
                                    <label>Father name</label>
                                    <input formControlName="father_name" class="form-control" type="text" />

                                </div>
                                <div class="form-group col-md-3">
                                    <label>Number</label>
                                    <input formControlName="mobile_number" class="form-control" type="text" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label>Expected Joining Date </label>
                                    <input formControlName="expected_joining_date" class="form-control" type="date" />
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Date Of Birth </label>
                                    <input formControlName="dob" class="form-control" />
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Age</label>
                                    <input formControlName="age" class="form-control" type="text" />
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Completion Date</label>
                                    <input formControlName="completion_date" class="form-control" type="date" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label>Interim Report Date</label>
                                    <input formControlName="interim_report_date" class="form-control" type="date" />
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Email Id</label>
                                    <input formControlName="email" class="form-control" type="email" />
                                    <small class="text-danger"
                                        [hidden]="editForm.get('email')?.valid || editForm.get('email')?.pristine">
                                        Wrong format
                                    </small>
                                </div>

                                <div class="form-group col-md-3">
                                    <label>Official Email</label>
                                    <input formControlName="official_email" class="form-control" type="email" />
                                    <small class="text-danger"
                                        [hidden]="editForm.get('official_email')?.valid || editForm.get('official_email')?.pristine">
                                        Wrong format
                                    </small>
                                </div>

                                <div *ngIf="statuses" class="form-group col-md-3">
                                    <label>Verification Status</label>
                                    <select class="form-control" formControlName="verification_status">
                                        <option *ngFor="let status of statuses['employee']" [value]="status.id">{{
                                            status.title }}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Employment Type</label>
                                    <select class="form-control" formControlName="employment_type">
                                        <option [ngValue]="null">Select Employment</option>
                                        <option value="fresher">Fresher</option>
                                        <option value="experienced">Experienced</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label>Upload Resume</label>
                                            <input id="resume_url" class="form-control" type="file" multiple
                                                (change)="uploadResume()" />
                                        </div>
                                        <div class="col-md-12" style="margin-top: 7px">
                                            <div class="row" *ngIf="employee && employee.resume_url">
                                                <div class="col-md-6">
                                                    <a [href]="employee.resume_url" target="_blank"> Resume Url</a>
                                                </div>
                                                <div class="col-md-3">
                                                    <button appCan='admin' (click)="onDeleteButtonClicked()"
                                                        class="btn btn-danger btn-sm"><i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label>Upload Documents</label>
                                            <input appUploadDocument
                                                (uploaded)="onDocumentUploaded($event, employee.id)" type="file"
                                                multiple class="form-control" [documentableId]="employee.id"
                                                documentableType="candidate" documentType="document" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6" appCan='superadmin' style="margin-top: 20px; padding-top: 6px;"
                                    formGroupName="settings">
                                    <div class="form-group col-md-3">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" formControlName="email_visibility">
                                            Email Visibility
                                        </label>
                                    </div>

                                    <div class="form-group col-md-3">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" formControlName="mobile_visibility">
                                            Mobile Visibility
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!-- metdata and other fields  -->
            <div class="col-md-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Metadata
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="form-group col-md-2" *ngFor="let field of metafields|keyvalue">
                                <label>{{ field.key }}</label>
                                <input class="form-control" type="text" [value]="field.value" disabled />
                            </div>
                        </div>
                        <div class="row" *ngIf="employee?.location">
                            <div class="form-group col-md-2">
                                <label>Location Name</label>
                                <input class="form-control" type="text" [value]="employee?.location.name" disabled />
                            </div>
                            <div class="form-group col-md-2">
                                <label>Location Code</label>
                                <input class="form-control" type="text" [value]="employee?.location.code" disabled />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-2">
                                <label>Recruiter Name</label>
                                <input class="form-control" type="text" [value]="employee.recruiter?.name" disabled />
                            </div>
                            <div class="form-group col-md-2">
                                <label>Recruiter Email</label>
                                <input class="form-control" type="text" [value]="employee.recruiter?.email" disabled />
                            </div>
                            <div class="form-group col-md-2">
                                <label>Recruiter Number</label>
                                <input class="form-control" type="text" [value]="employee.recruiter?.mobile_number"
                                    disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Attachments -->
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Employee Folder Documents
                    </div>

                    <div class="panel-body">

                        <div *ngIf="attachmentsLoading" class="d-flex align-items-center">
                            <i class="fa fa-spinner fa-spin fa-2x fa-fw align-items-center"></i>
                        </div>

                        <div class="row" *ngFor="let attachment of attachments">
                            <div class="form-group col-md-10">
                                <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="attachment.url"
                                    target="_blank">
                                    &nbsp;&nbsp; {{attachment.filename}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Documents -->
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Documents
                    </div>
                    <div class="panel-body">

                        <div *ngIf="documentLoading" class="d-flex align-items-center">
                            <i class="fa fa-spinner fa-spin fa-2x fa-fw align-items-center"></i>
                        </div>

                        <div class="row" *ngFor="let document of documents">

                            <div class="form-group col-md-10">
                                <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="document.url"
                                    target="_blank">
                                    &nbsp;&nbsp; {{document.name + "_" + document.filename}}
                                </a>
                            </div>

                            <div class="form-group col-md-2">
                                <button appCan='admin' (click)="onDeleteButtonClicked(document.id)"
                                    class="btn btn-danger btn-sm"><i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-3">
            <div class="row">
                <!--All Verifications-->
                <div class="col-md-12">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            Verifications
                            <span class="pull-right">
                                <a style="margin-left: 5px;" routerLink="/employees/{{employee.id}}/documents">Review
                                    Document</a>
                            </span>
                        </div>
                        <div class="panel-body">
                            <div class="" *ngFor="let verification of employee.verifications">
                                <a class="btn btn-default btn-block"
                                    (click)="openVerification(openVerificationInterface[verification])">
                                    {{verificationInterface[verification]}}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="employee?.invite_response">

                    <app-employee-invite-form-response [inviteResponse]="employee.invite_response">
                    </app-employee-invite-form-response>

                </div>

                <div class="col-md-12">
                    <app-employee-file-list [employeeDocuments]="employeeDocuments"
                        [employee]="employee"></app-employee-file-list>
                </div>

            </div>
        </div>

        <!--Attachments-->
        <!-- <div class="col-md-9" *ngIf="attachments?.length > 0">


        </div> -->
    </div>
</div>