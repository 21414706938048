import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors } from '../core/models/errors.module';
import { AuthService, LoginResponse } from '../core/services/auth.service';
import { CommonService } from '../core/services/common.service';
import { GlobalService } from '../core/services/global.service';
import { AuthDataService } from '../core/services/auth-data.service';

@Component( {
    selector: 'app-login',
    templateUrl: './login.component.html',
} )
export class LoginComponent implements OnInit {

    title: string = '';
    errors: Errors = new Errors();
    isSubmitting = false;
    authForm: FormGroup;
    redirectUrl!: string;

    get email () {
        return this.authForm.get( 'email' );
    }

    get password () {
        return this.authForm.get( 'password' );
    }

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private fb: FormBuilder,
        private commonService: CommonService,
        public authDataService: AuthDataService
    ) {
        this.authForm = this.fb.group( {
            'email': [ '', Validators.required ],
            'password': [ '', Validators.required ],
        } );
    }

    ngOnInit () {

        this.authDataService.setShowTwoFactorPage( false );

        this.redirectUrl = this.route.snapshot.queryParamMap.get( 'redirectUrl' ) ?? '/home';

        if ( localStorage.getItem( 'token' ) ) {
            this.router.navigateByUrl( this.redirectUrl );
        }

        this.route.url.subscribe( {
            next: ( data ) => this.title = 'Sign in',
        } );

    }

    submitForm () {

        // TODO: check if we need this
        this.isSubmitting = true;

        this.errors = new Errors();

        this.authService.login( this.authForm.value ).subscribe( {
            next: ( response ) => {
                this.onSuccessResponse( this.email?.value, response );
            },
            error: ( errors ) => {
                this.onErrorResponse( errors );
            }
        } );

    }

    private onErrorResponse ( errors: any ) {

        this.router.navigate( [ '/login' ], { queryParams: { redirectUrl: this.redirectUrl } } );
        this.authDataService.setShowTwoFactorPage( false );
        this.errors = errors;
        this.isSubmitting = false;
        this.commonService.notifications.error( errors?.error?.message, errors?.error?.error, { timeOut: 10000 } );
    }

    private onSuccessResponse ( email: string, response: LoginResponse ) {

        if ( response.success == true ) {
            this.commonService.notifications.info( response.message );
            this.authDataService.setUserEmail( email );
            this.authDataService.setShowTwoFactorPage( true );
        }

        this.authService.userLoggedIn.emit( true );

    }
}
