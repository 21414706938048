import { Component, ElementRef, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';


@Component( {
    selector: 'app-typeahead-dropdown',
    templateUrl: './typeahead-dropdown.component.html',
    styleUrls: [ './typeahead-dropdown.component.css' ],
} )
export class TypeaheadDropdownComponent implements OnInit {

    @Input() mainFormGroup!: FormGroup | any;
    @Input() formArray!: FormArray;
    @Input() formGName!: FormGroup | any;
    @Input() fGroup!: FormGroup | any;
    @Input() name!: string;
    @Input() options!: any;
    @Input() labelName!: any;
    @Input() requiredField: boolean = false;

    @Output() changed = new EventEmitter<number>();

    get controlName (): string { return this.name + '_name'; }
    get controlId (): string { return this.name + '_id'; }

    constructor (

        protected el: ElementRef,
        protected cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit (): void {

    }

    public changeTypeaheadNoResults ( event: any, formGroup: FormGroup ): void {

        // TODO: Remove Set timeout as its used as a temporary bug fix
        setTimeout( () => {
            if ( formGroup.get( this.controlName )?.value === '' ) {

                formGroup.get( this.controlId )?.setValue( null );
            }
        } );

    }


    // TypeAhead functionality.
    OnSelect ( event: any, formGroup: FormGroup ): void {

        // TODO: Remove Set timeout as its used as a temporary bug fix
        setTimeout( () => {

            formGroup.get( this.controlId )?.setValue( event.item.id );

            if ( this.controlName === 'state_name' ) {

                formGroup.controls[ 'district_name' ].setValue( '' );
            }

            this.changed.emit( event.item.id );

        } );
    }
}
