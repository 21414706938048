<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">PCC Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="pcc"></app-verification-stats>
</ng-container>

<app-verification-filters verification="pcc" [filterParams]="filterParams"
    filterBy="company,lot,verification_status,created_after,created_before,completion_date_from,completion_date_to,assigned_to,state,district,pincode,applied_date_present,track_id,dov_present,work_completed,applied_at,qa_required,qa_done,work_assignment">
</app-verification-filters>
<app-work-assign-modal #workAssignModal [verification]="'pcc'" [checkedIds]="ids" (onWorkAssigment)="onModalUpdated()">
</app-work-assign-modal>

<div class="wrapper-md">
    <div class="row" style="padding: 0 0 0 20px">
        <a class="btn btn-primary btn-addon" [routerLink]="['/pcc', 'import']">
            <i class="fa fa-upload" *ngIf="pagination.data"></i>
            Import Excel
        </a>

        <!-- <a class="btn btn-primary btn-addon" target="_blank" [href]="[crmUrl + 'pcc/challan']">
            <i class="fa fa-print"></i>
            Print Challan
        </a>

        <a class="btn btn-primary btn-addon" target="_blank" [href]="[crmUrl + 'pcc/letterheads']">
            <i class="fa fa-print"></i>
            Print Letterheads
        </a> -->

        <!-- new way -->
        <a class="btn btn-primary btn-addon" [routerLink]="['/pcc', 'challan']">
            <i class="fa fa-print"></i>
            Print Challan
        </a>

        <a class="btn btn-primary btn-addon" [routerLink]="['/pcc', 'letterheads']">
            <i class="fa fa-print"></i>
            Print Letterheads
        </a>

        <a [appCan]="'editor'" class="btn btn-primary btn-addon" (click)="downloadNewExcel()">
            <i class="fa fa-edit"></i>
            Download Excel
        </a>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="row wrapper">
            <div class="col-sm-8" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses['pcc']"
                            (click)="onChangeStatusButtonClicked(status.id, status.title)">
                            <a class="dropdown-item">{{ status.title }}</a>
                        </li>
                    </ul>
                </div>

                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('identity')"
                    class="btn btn-danger btn-addon">
                    <i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{ user.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light" id="pcc-list">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)" />
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:50%;">Details</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let verification of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    };
              let i = index
            ">
                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{ verification.id }}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id, $event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <a class="btn-link text-main text-semibold"
                                href="/#/pcc/{{ verification.employee_id }}/edit" target="_blank">
                                <strong> {{ verification.employee_name }}</strong>
                            </a>
                            <br />
                            <a class="btn-link text-main" (click)="showPcc(verification)">{{
                                verification.track_id
                                }}</a>
                            <br />
                            <a class="btn-link" routerLink="/lots/{{ verification.lot_id }}">
                                {{ verification.lot_name }}
                            </a>
                            <br />
                            <a class="btn-link" routerLink="/companies/{{ verification.company_id }}">
                                {{ verification.company_name }}
                            </a>
                        </td>

                        <td class="{{ verification.color }}">
                            <app-verification-status verification="pcc" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at">
                            </app-verification-status>
                            <div class="row" *ngIf="attachments[i] && attachments[i]['attachments']">
                                <div class="col-md-12"
                                    *ngFor="let attachment of attachments[i]['payment_screenshot']; let i = index">
                                    <a href="{{ attachment['url'] }}" class="btn-link">
                                        {{ attachment["name"] | titlecase}} {{i+1}}
                                    </a>
                                </div>

                                <div class="col-md-12" *ngFor="
                                    let attachment of attachments[i]['application_screenshot']; let i= index
                                ">
                                    <a href="{{ attachment['url'] }}" class="btn-link">
                                        {{ attachment["name"] |titlecase }} {{ i+1 }}
                                    </a>
                                </div>
                                <div class="col-md-12" *ngIf="verification.pcc_report">
                                    <a href="{{ verification.pcc_report }}" class="btn-link">
                                        Pcc Report Url
                                    </a>
                                </div>
                            </div>

                            <div class="row" *ngIf="attachments[i] && attachments[i]['attachments']">
                                <div class=" col-md-12"
                                    *ngFor="let attachment of attachments[i]['attachments']; let i = index">
                                    <a href="{{attachment['url']}}" class="btn-link">
                                        {{ attachment["name"] | titlecase}} {{ i+1 }}
                                    </a>
                                </div>
                            </div>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Address: {{ verification.full_address }}</li>
                                <li>Duration of Stay: {{ verification.duration_of_stay }}</li>
                                <li>Police Station : {{ verification.police_station }}</li>
                                <li>
                                    Payment Reference Number :
                                    {{ verification.payment_reference_number }}
                                </li>
                                <li>
                                    Pcc Application Number :
                                    {{ verification.pcc_application_number }}
                                </li>
                                <li *ngFor="
                    let work of verification.work_assigned[0]
                  ">
                                    <strong>{{ work.title }} is assigned to
                                        {{ work.assigned_to }}</strong>
                                </li>
                            </ul>
                        </td>

                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id, i)">
                                Delete
                            </button>

                            <div class="row" *ngIf="attachments[i]">
                                <div class="col-md-12"
                                    *ngFor="let attachment of attachments[i]['payment_screenshot']; let i= index;">
                                    <button (click)="
                      onDeleteAttachmentButtonClicked(
                        verification.id,
                        attachment['id'],
                        'delete_payment_url_' + attachment['id']
                      )
                    " class="btn btn-danger btn-xs" id="delete_payment_url_{{ attachment['id'] }}">
                                        Delete {{ attachment["name"] | titlecase}} {{i+1}}
                                    </button>
                                </div>

                                <div class="col-md-12" *ngFor="
                    let attachment of attachments[i]['application_screenshot']; let i= index;
                  ">
                                    <button (click)="
                      onDeleteAttachmentButtonClicked(
                        verification.id,
                        attachment['id'],
                        'delete_application_screenshot_' + attachment['id']
                      )
                    " class="btn btn-danger btn-xs" id="delete_application_screenshot_{{ attachment['id'] }}">
                                        Delete {{ attachment["name"] | titlecase}} {{i+1}}
                                    </button>
                                </div>

                                <div class="col-md-12"
                                    *ngFor="let attachment of attachments[i]['attachments']; let i=index;">

                                    <button
                                        (click)="onDeleteAttachmentButtonClicked(verification.id,attachment['id'],'delete_attachment_'+attachment['id'])"
                                        class="btn btn-danger btn-xs" id="delete_attachment_{{attachment['id']}}">
                                        Delete {{ attachment["name"] | titlecase}} {{i+1}}
                                    </button>

                                </div>

                                <div class="col-md-12" *ngIf="verification.pcc_report">
                                    <button (click)="
                      onDeleteFileButtonClicked(
                        verification.id,
                        'pcc_report',
                        'delete_pcc_report_' + i
                      )
                    " class="btn btn-danger btn-xs" id="delete_pcc_report_{{ i }}">
                                        Delete Pcc Report
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>