<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Employees List</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="refreshPage()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-employee-dashboard-filters></app-employee-dashboard-filters>

<div class="list-page-buttons">
    <div class="btn-group" role="group">
        <button appCan="editor" class="btn btn-primary btn-space" (click)="exportLogsExcel()">
            <i class="fa fa-download"></i>
            Download Excel
        </button>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination && pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="scroll-container" style="overflow-x: auto;">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="max-width: 150px;">Name</th>
                        <th style="max-width: 150px;">Employee Status</th>
                        <th style="max-width: 150px;">Identity</th>
                        <th style="max-width: 150px;">Current Address</th>
                        <th style="max-width: 150px;">Permanent Address</th>
                        <th style="max-width: 150px;">ICR</th>
                        <th style="max-width: 150px;">Education</th>
                        <th style="max-width: 150px;">Employment</th>
                        <th style="max-width: 150px;">Reference</th>
                        <th style="max-width: 150px;">Global </th>
                        <th style="max-width: 150px;">Pcc</th>
                        <th style="max-width: 150px;">Document</th>
                        <th style="max-width: 150px;">Timeline</th>
                        <th style="max-width: 150px;">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let employee of pagination.data | paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total };let i = index">
                        <td style="max-width: 350px; width: 350px;">
                            <div *ngIf="employee.employee_id; else elseBlock">
                                <a routerLink="/employees/{{employee.employee_id}}/edit">
                                    <b>Name:</b> {{ employee.employee_name }} <br />
                                </a>
                            </div>
                            <ng-template #elseBlock>
                                <b>Name:</b> {{ employee.employee_name }} <br />
                            </ng-template>
                            <div *ngIf="employee.employee_id; else elseBlock">
                                <a routerLink="/employees/{{employee.employee_id}}/edit">
                                    <b>Track ID:</b> {{ employee.track_id }} <br />
                                </a>
                            </div>
                            <ng-template #elseBlock>
                                <b>Track ID:</b> {{ employee.track_id }} <br />
                            </ng-template> <br />
                            <b>Company:</b> {{ employee.company.company_name }} <br />
                            <b>Lot:</b> {{ employee.lot?.lot_name }} <br />
                        </td>
                        <td style="max-width: 350px; width: 350px;">
                            <div>
                                <a
                                routerLink="/employees/{{employee.employee_id}}/edit"
                                type="button"
                                class="btn btn-block"
                                [ngClass]="employee.verification_status?.color || 'white'">
                                    <span class="font">
                                        {{employee.verification_status?.title}}
                                    </span>
                                </a>
                                <br />
                                <br />
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let identity of employee.identity_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{identity.status.color}}"
                                        (click)="showVerificationPage(employee, 'identity')">
                                        <span class="font">{{identity.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="identity.client_remarks"
                                        [popover]="identity.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ identity.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let address of employee.current_address_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{address.status.color}}"
                                        (click)="showVerificationPage(employee, 'address')">
                                        <span class="font">{{address.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="address.client_remarks"
                                        [popover]="address.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ address.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let address of employee.permanent_address_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{address.status.color}}"
                                        (click)="showVerificationPage(employee, 'address')">
                                        <span class="font">{{address.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="address.client_remarks"
                                        [popover]="address.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ address.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let icr of employee.icr_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{icr.status.color}}"
                                        (click)="showVerificationPage(employee, 'icr')">
                                        <span class="font">{{icr.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="icr.client_remarks"
                                        [popover]="icr.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ icr.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let education of employee.education_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{education.status.color}}"
                                        (click)="showVerificationPage(employee, 'education')">
                                        <span class="font">{{education.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="education.client_remarks"
                                        [popover]="education.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ education.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let employment of employee.employment_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{employment.status.color}}"
                                        (click)="showVerificationPage(employee, 'employment')">
                                        <span class="font">{{employment.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="employment.client_remarks"
                                        [popover]="employment.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ employment.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let reference of employee.reference_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{reference.status.color}}"
                                        (click)="showVerificationPage(employee, 'reference')">
                                        <span class="font">{{reference.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="reference.client_remarks"
                                        [popover]="reference.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ reference.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let global of employee.global_db_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{global.status.color}}"
                                        (click)="showVerificationPage(employee, 'global-db')">
                                        <span class="font">{{global.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="global.client_remarks"
                                        [popover]="global.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ global.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let pcc of employee.pcc_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{pcc.status.color}}"
                                        (click)="showVerificationPage(employee, 'pcc')">
                                        <span class="font">{{pcc.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="pcc.client_remarks"
                                        [popover]="pcc.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ pcc.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 250px; width: 250px;">
                            <div *ngFor="let document of employee.document_verifications">
                                <div>
                                    <button type="button" class="btn btn-block {{document.status.color}}"
                                        (click)="showVerificationPage(employee, 'document')">
                                        <span class="font">{{document.status.title}}</span>
                                    </button>
                                    <div class="client-remarks" *ngIf="document.client_remarks"
                                        [popover]="document.client_remarks" triggers="mouseenter:mouseleave">
                                        Client Remarks: {{ document.client_remarks }}
                                    </div><br /><br />
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 200px; width: 200px;">
                            Created : {{ employee.created_at | date: 'mediumDate' }} <br /><br />
                            <span *ngFor="let email of employee.email_logs; let i = index">
                                Email {{ i+1 }} : {{ email.created_at | date : 'mediumDate' }} : {{ email.sender ?
                                email.sender.name : 'Error' }} <br />
                                Email {{ i+1 }} : Status : {{ email.delivery_status }} <br /><br />
                            </span>
                        </td>
                        <td style="max-width: 100px; width: 100px;">
                            <button *ngIf="isEmailDisabled(employee)" type="button" class="btn btn-primary"
                                (click)="showInsuffEmailModal(employee)">Send
                                Insuff
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <app-insuff-email-modal #insuffEmailModal></app-insuff-email-modal> -->

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>
