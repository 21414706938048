import { ClientFormsService } from './../../core/services/client-forms.service';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Errors } from '../../core/models/errors.module';
import { Lot } from '../../core/models/lot.model';
import { Verification } from '../../core/models/verification.model';
import { CommonService } from '../../core/services/common.service';
import { CompanyService } from '../../core/services/company.service';
import { LotService } from '../../core/services/lot.service';
import { VerificationService } from '../../core/services/verification.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { idDocuments } from 'src/app/client-forms-v2/client-forms-v2.enum';
import { ClientFormType } from 'src/app/core/enums/client-form-type.enum';


@Component( {
    selector: 'app-lot-edit',
    templateUrl: './lot-edit.component.html',
    styleUrls: [ './lot-edit.component.css' ],
    providers: [ LotService, VerificationService, CompanyService ],
} )
export class LotEditComponent implements OnInit {

    errors: Errors = new Errors();
    isSubmitting = false;
    editFormGroup: FormGroup;
    lot: Lot = new Lot();
    allVerifications: Verification[] = [];
    allClients!: any[];
    clientForms: any[] = [];
    verificationValiadtion: boolean = true;
    selectedOption: string[] = [];
    identityList = idDocuments;
    maxLimit: number = 5;

    get users () {
        return this.global.fetchUsers();
    }

    get settings (): FormGroup {
        return this.editFormGroup.get( 'settings' ) as FormGroup;
    }

    get verifications () {
        return this.editFormGroup.get( 'verifications' );
    }

    get currentAddressFormId () {
        return this.settings.get( 'current_digital_address_form_id' ) as FormControl
    }

    get permanentAddressFormId () {
        return this.settings.get( 'permanent_digital_address_form_id' ) as FormControl
    }

    get digitalAddressForms () {

        if ( this.clientForms.length == 0 ) return [];

        return this.clientForms.filter( form => form.type == ClientFormType.DigitalAddress )
    }

    get isCurrentAddressSelected (): boolean {

        const verifications = this.verifications?.value ?? [];

        if ( !verifications || verifications?.length == 0 ) return false

        return verifications.includes( "current_address_check" )
    }

    get isPermanentAddressSelected (): boolean {

        const verifications = this.verifications?.value ?? [];

        if ( !verifications || verifications?.length == 0 ) return false

        return verifications.includes( "permanent_address_check" )
    }

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private lotService: LotService,
        public global: GlobalService,
        private verificationService: VerificationService,
        private companyService: CompanyService,
        private commonService: CommonService,
        private clientFormsService: ClientFormsService,
        private confirmationModalService: ConfirmationModalService
    ) {
        this.editFormGroup = this.fb.group( {
            'name': [ '', Validators.required ],
            'cities': [ '' ],
            'editors': [ [] ],
            'clients': [ [] ],
            'status': [],
            'status_id': [],
            'type': [ '', Validators.required ],
            'verifications': [ [] ],
            'instructions': [ '' ],
            'settings': this.fb.group( {
                'icr_civil_status': [ false ],
                'icr_criminal_status': [ false ],
                'gst_number': [ '' ],
                'billing_name': [ '' ],
                'email_visibility': [],
                'mobile_visibility': [],
                'current_digital_address_form_id': [],
                'permanent_digital_address_form_id': []
            } ),
        } );
    }

    ngOnInit () {

        this.verificationService.getAllVerifications().then( verifications => {
            this.allVerifications = verifications;
        } );

        this.route.paramMap.pipe(
            switchMap( ( params: ParamMap ) => this.lotService.find( +params.get( 'id' )! ) ) )
            .subscribe( response => {
                this.lot = response;
                this.companyService.getAllClients( this.lot.company_id ).subscribe( clients => {

                    this.allClients = clients;

                    this.editFormGroup.patchValue( this.lot );

                    this.getFormDetails();
                } );
            } );
    }

    onVerificationDropdownClosed () {

        if ( !this.isCurrentAddressSelected ) {
            this.currentAddressFormId?.setValue( null );
        }

        if ( !this.isPermanentAddressSelected ) {
            this.permanentAddressFormId?.setValue( null );
        }
    }

    updateLot () {
        this.isSubmitting = true;
        this.errors = new Errors();
        this.lotService.update( this.lot.id, this.editFormGroup.value ).subscribe(
            response => {

                this.commonService.notifications.success( 'Lot Updated' );
                this.router.navigate( [ '/companies', this.lot.company_id ] );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.errors = error;
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    getFormDetails () {
        this.clientFormsService.getAll( { lot_id: this.lot.id } ).subscribe(
            response => {
                this.clientForms = response.data;
            }
        );
    }


    onVerificationsChanged ( values: any ): void {

        const lotVerifications: string[] = this.editFormGroup.get( 'verifications' )?.value;

        const checker = ( array: any[] ) => array.every( Boolean );

        if ( this.clientForms ) {
            const boolArrayOfIsSubsetResponse = this.clientForms.map( ( form: any ) => {
                if ( this.isSubset( lotVerifications, form.verifications ) ) {
                    return true;
                }
                return false;
            } )

            this.verificationValiadtion = checker( boolArrayOfIsSubsetResponse );

            if ( !this.verificationValiadtion ) {
                this.commonService.notifications.warning( 'Warning!', 'Lot and Form verifications are out of sync' );
            }
        }
    }

    isSubset ( lotVerifications: string | any[], formVerifications: string | any[] ): boolean {


        for ( let i = 0; i < formVerifications.length; i++ ) {

            if ( lotVerifications.includes( formVerifications[ i ] ) ) {
                continue;
            }

            return false;
        }

        return true;
    }
}
