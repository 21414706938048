import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShortcutService } from 'src/app/core/services/shortcut.service';

import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { Address } from '../../core/models/address.model';
import { Employee } from '../../core/models/employee.model';
import { GlobalDbVerification } from '../../core/models/global-db-verification.model';
import { CommonService } from '../../core/services/common.service';
import { GlobalDbService } from '../../core/services/global-db.service';
import { EnvironmentService } from './../../core/services/environment.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { GlobalDbVerificationStatusType } from 'src/app/core/enums/global-db-verification-status-type.enum';

@Component( {
    selector: 'app-global-db-edit',
    templateUrl: './global-db-edit.component.html',
    styleUrls: [ './global-db-edit.component.css' ],
} )
export class GlobalDbEditComponent extends VerificationEditComponent implements OnInit, OnChanges {

    verifications: GlobalDbVerification[] = [];
    address: Address[] = [];
    override crmUrl!: string;
    vendors!: any[];
    override verificationFormGroup!: FormGroup;
    override attachments: any[] = [];
    countries!: any[];
    statusChanged: boolean = false;

    constructor (
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override verificationService: GlobalDbService,
        public global: GlobalService,
        protected override common: CommonService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        private environmentService: EnvironmentService,
        protected override confirmationModalService: ConfirmationModalService

    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );
        this.initForm();
    }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    get statuses () { return this.global.fetchStatuses(); }

    override ngOnInit (): void {

        this.verification = 'global-db';

        this.crmUrl = this.environmentService.crmUrl;

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                employee => {
                    this.employee = employee;
                    this.populateForm( employee );
                    this.populateExtraDetails( employee );
                    this.isSubmitting = false;
                    this.applyDefaults();
                },
            );
        } );

        this.subscribeToQueryChanges();

        this.common.getVendors( 'global_db' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
        this.common.getCountries().subscribe(
            response => {
                this.countries = response;
            }
        );
    }

    initForm (): void {


        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            name: [ null ],
            father_name: [ null ],
            dob: [ null ],
            id: [ null ],
            formArray: this.fb.array( [] ),
        } );

    }

    populateForm ( employee: Employee ): void {

        this.verificationFormGroup.patchValue( employee );

        const verifications: any[] = employee.global_db_verifications as GlobalDbVerification[];

        const verificationFormGroup: FormGroup[] = verifications.map( verification => this.fb.group( verification ) );

        const verificationGroupArray: FormArray = this.fb.array( verificationFormGroup );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );
    }


    ngOnChanges (): void {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );
        } );
    }

    createGlobalDb (): void {

        this.verificationService.create( this.employee.id ).subscribe(
            response => {
                this.populateForm( response );
                this.common.notifications.success( 'Verification added' );
            },
            error => {
                this.common.notifications.error( 'Unable to Add Verification' );
            }
        );

    }

    populateExtraDetails ( employee: Employee ): void {

        this.employee = employee;

        for ( const index in employee.global_db_verifications ) {

            const verification: any = employee.global_db_verifications[ index ];

            this.attachments[ index ] = verification.attachments;
        }

    }

    applyDefaults (): void {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );

            this.applyDefaultValidations( form );

            // Subscribe to any Change in Verification Status
            form.get( 'status_id' )?.valueChanges.subscribe(
                ( statusId: any ) => {
                    this.applyValidationsForStatusChange( statusId, form );
                }
            );

        } );
    }


    /**
     * Apply Validations when Status is changed
     *
     * @param statusId
     * @param {AbstractControl} form
     */
    protected applyValidationsForStatusChange ( statusId: any, form: AbstractControl ): void {

        // List of statuses for which validations is required on completion Date.
        const validStatusesForCompletionDate: number[] = [
            GlobalDbVerificationStatusType.NoRecordFound,
            GlobalDbVerificationStatusType.UnderReview,
            GlobalDbVerificationStatusType.RecordFound,
            GlobalDbVerificationStatusType.PossibleRecordFound,
            GlobalDbVerificationStatusType.VerificationStopped,
            GlobalDbVerificationStatusType.DocumentRequired,
            GlobalDbVerificationStatusType.NotApplicable,
            GlobalDbVerificationStatusType.ClientTerminated,
            GlobalDbVerificationStatusType.OnHold
        ];

        // List of statuses for which validations is required on client remarks.
        const validStatusesForClientRemarks: number[] = [
            GlobalDbVerificationStatusType.UnderReview,
            GlobalDbVerificationStatusType.RecordFound,
            GlobalDbVerificationStatusType.PossibleRecordFound,
            GlobalDbVerificationStatusType.VerificationStopped,
            GlobalDbVerificationStatusType.DocumentRequired,
            GlobalDbVerificationStatusType.NotApplicable,
            GlobalDbVerificationStatusType.ClientTerminated,
            GlobalDbVerificationStatusType.OnHold
        ];

        const validStatusesForAddressRelatedFields: number[] = [
            GlobalDbVerificationStatusType.NoRecordFound,
            GlobalDbVerificationStatusType.SentToVendor,
            GlobalDbVerificationStatusType.UnderReview,
            GlobalDbVerificationStatusType.RecordFound,
            GlobalDbVerificationStatusType.PossibleRecordFound
        ];

        const validStatusesForSendToVendorDateAndVendorName: number[] = [
            GlobalDbVerificationStatusType.NoRecordFound,
            GlobalDbVerificationStatusType.SentToVendor,
            GlobalDbVerificationStatusType.UnderReview,
            GlobalDbVerificationStatusType.RecordFound,
            GlobalDbVerificationStatusType.PossibleRecordFound
        ];

        // Check if changed status is in our list.
        if ( validStatusesForCompletionDate.indexOf( statusId ) !== -1 ) {

            form.get( 'completed_at' )?.setValidators(
                [ Validators.required, validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ]
            );

            if ( this.statusChanged ) {
                form.get( 'completed_at' )?.setValue( new Date().toJSON().slice( 0, 10 ) );
            }

        } else {


            if ( this.statusChanged ) {
                form.get( 'completed_at' )?.setValue( '' );
            }

            form.get( 'completed_at' )?.setValidators(
                [ validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ]
            );
        }

        // Check if changed status is in our list.
        if ( validStatusesForClientRemarks.indexOf( statusId ) !== -1 ) {

            form.get( 'client_remarks' )?.setValidators( [ Validators.required ] );

        } else {
            form.get( 'client_remarks' )?.setValidators( [] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForAddressRelatedFields.indexOf( statusId ) !== -1 ) {

            form.get( 'address' )?.setValidators( [ Validators.required ] );
            form.get( 'state_id' )?.setValidators( [ Validators.required ] );
            form.get( 'district_id' )?.setValidators( [ Validators.required ] );
            form.get( 'pincode' )?.setValidators( [ Validators.required, Validators.minLength( 6 ) ] );

        } else {
            form.get( 'address' )?.setValidators( [] );
            form.get( 'state_id' )?.setValidators( [] );
            form.get( 'district_id' )?.setValidators( [] );
            form.get( 'pincode' )?.setValidators( [] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForSendToVendorDateAndVendorName.indexOf( statusId ) !== -1 ) {

            form.get( 'sent_to_vendor_on' )?.setValidators( [ Validators.required ] );
            form.get( 'vendor_id' )?.setValidators( [ Validators.required ] );

        } else {
            form.get( 'sent_to_vendor_on' )?.setValidators( [] );
            form.get( 'vendor_id' )?.setValidators( [] );
        }

        form.get( 'completed_at' )?.updateValueAndValidity();
        form.get( 'client_remarks' )?.updateValueAndValidity();
        form.get( 'address' )?.updateValueAndValidity();
        form.get( 'state_id' )?.updateValueAndValidity();
        form.get( 'district_id' )?.updateValueAndValidity();
        form.get( 'pincode' )?.updateValueAndValidity();
        form.get( 'sent_to_vendor_on' )?.updateValueAndValidity();
        form.get( 'vendor_id' )?.updateValueAndValidity();
    }

    /**
     * Set Default Validations to be applied on Page Load
     *
     * @param {AbstractControl} form
     */
    private applyDefaultValidations ( form: AbstractControl ): void {

        this.applyValidationsForStatusChange( form.get( 'status_id' )?.value, form );
    }

    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                },
                () => {
                    this.isSubmitting = false;
                }
            );
        }
    }

}
