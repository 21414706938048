
import { UserService } from 'src/app/core/services/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { InsuffEmailModalComponent } from '../insuff-email-modal/insuff-email-modal.component';
import { Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Employee } from 'src/app/core/models/employee.model';
import { NewPagination, Pagination } from 'src/app/core/models/pagination.model';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';

@Component( {
    selector: 'app-employee-dashboard',
    templateUrl: './employee-dashboard.component.html',
    styleUrls: [ './employee-dashboard.component.css' ],
} )
export class EmployeeDashboardComponent implements OnInit, OnDestroy {

    filtersSubscription!: Subscription;
    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading: any;

    constructor (
        private fb: FormBuilder,
        private router: Router,
        private filterService: FilterService,
        private employeeService: EmployeeService,
        private bsModalService: BsModalService,
        private userService: UserService
    ) {
    }

    ngOnInit () {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );

    }

    filterResults ( params: Params ): void {

        const updatedParams = this.updateParams( params );

        this.employeeService.getAll( updatedParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

    isEmailDisabled ( employee: Employee ): boolean {
        return ( employee.settings.email_visibility || this.userService.isAdmin );
    }

    showPage ( $event: any ) {

        this.filterService.filterByPage( $event );

    }

    refreshPage (): void {
        this.filterService.filter();
    }

    updateParams ( params: any ): HttpParams {
        let httpParams = new HttpParams();

        const multipleValueFilters = [ 'company_id', 'lot_id', 'employee_status_id', 'identity_status_id',
            'current_address_status_id', 'permanent_address_status_id', 'icr_status_id', 'education_status_id', 'employment_status_id',
            'reference_status_id', 'pcc_status_id', 'global_database_status_id', 'document_status_id' ];

        Object.keys( params ).forEach( key => {
            if ( multipleValueFilters.includes( key ) ) {
                httpParams = httpParams.set( key, params[ key ].toString() );
            } else {
                httpParams = httpParams.set( key, params[ key ] );
            }
        } );

        return httpParams;
    }

    showInsuffEmailModal ( employee: Employee ) {

        const initialState = {
            employee: employee,
        };

        this.bsModalService.show( InsuffEmailModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )
    }

    showVerificationPage ( employee: Employee, verification: string ) {

        this.router.navigate( [ verification, employee.employee_id, 'edit' ], {
            fragment: this.filterParams.toString(),
            queryParamsHandling: 'merge',
        } );

    }

    exportLogsExcel (): void {

        const updatedParams = this.updateParams( this.filterService.filters );

        const initialState: DownloadExcelInitialState = {
            filterParams: updatedParams,
            paramsString: updatedParams.toString(),
            route: 'employees/download-excel',
            fileName: 'Employees List Excel ',
            method: 'get',
            postParams: null,
        };

        this.employeeService.showDownloadModal( initialState );
    }

}
