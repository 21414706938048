import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Employee } from '../models/employee.model';

@Injectable( {
    providedIn: 'root',
} )

export class DownloadService {

    constructor ( private http: HttpClient, private sanitizer: DomSanitizer ) { }

    downloadFile ( fileUrl: string, fileType: string, body: any ): Observable<Blob> {

        const headers = new HttpHeaders()
            .set( 'Accept', 'application/' + fileType )
            .set( 'Authorization', 'Bearer ' + localStorage.getItem( 'token' ) );

        return this.http.post( fileUrl, body, { headers, responseType: 'blob' } ).pipe(
            map( ( response: Blob ) => {
                return new Blob( [ response ], { type: 'application/' + fileType } );
            } )
        );
    }

    getFile ( blobFile: Blob, fileName: string ): void {

        const url = window.URL.createObjectURL( blobFile );

        const link = document.createElement( 'a' );

        link.href = url;

        link.setAttribute( 'download', fileName );

        document.body.appendChild( link );

        link.click();
    }

    getFileUrl ( blobFile: Blob ): SafeResourceUrl {

        return this.sanitizer.bypassSecurityTrustResourceUrl( window.URL.createObjectURL( blobFile ) );

    }

    reportName ( employee: Employee, documentType: string ): string {

        let reportPrefix = 'Instaveritas_Report_';

        let fileName = employee.client_employee_id && employee.client_employee_id.length
            ? reportPrefix + '_' + employee.client_employee_id + '_'
            : reportPrefix;

        fileName = fileName + employee.name;

        const pattern = /[^\w]/g; // Matches non-word and non-space characters

        fileName = fileName.replace( pattern, '_' ); // Replace invalid characters with underscores

        // Replace multiple consecutive underscores with a single underscore
        fileName = fileName.replace( /_+/g, '_' );

        // Append the .pdf extension
        fileName += "." + documentType;

        return fileName;
    }

}
