<form [formGroup]="verificationFormGroup" (ngSubmit)="updateVerification('document')"
    (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">

        <!--Top Section Of the Form-->
        <div *ngIf="employee &&employee.id" class="row bg-light lter b-b wrapper-md">

            <app-verification-edit-header [verification]="'document'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>
        </div>

        <div class="wrapper-md">

            <div class="panel">
                <div class="panel-body">
                    <div class="row">
                        <div class="form-group col-md-2">
                            <label class="center-block">Employee Name</label>
                            <input formControlName="name" class="form-control" readonly>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Client Employee Id</label>
                            <input formControlName="client_employee_id" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Mobile Number</label>
                            <input formControlName="mobile_number" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Father Name</label>
                            <input formControlName="father_name" class="form-control" readonly>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Date Of Birth</label>
                            <input formControlName="dob" class="form-control" readonly>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Gender</label>

                            <select formControlName="gender" class="form-control">
                                <option value=""></option>
                                <option value="m">Male</option>
                                <option value="f">Female</option>
                            </select>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Click here to</label>
                            <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createDocument()">
                                <i class="fa fa-plus"></i>
                                Add Verification
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <div formArrayName="formArray">
                <div *ngFor="let verification of formArray.controls; let i=index" [formGroupName]="i">

                    <app-image-crop #cropper [verification]="verification" [verification_type]="'document'"
                        (saved)="imageSaved()">
                    </app-image-crop>

                    <div class="row">
                        <div class="col-md-8">

                            <!--Document Url Panel-->
                            <div class="panel" *ngIf="verification.get('document_url')?.value"
                                style="overflow: hidden;">
                                <div class="row">
                                    <div class="col-md-12" style="position: inherit">
                                        <button type="button"
                                            (click)="zoomInImage('img_document_url_'+verification.value.id)"
                                            class="btn btn-primary"> +
                                        </button>
                                        <button type="button"
                                            (click)="zoomOutImage('img_document_url_'+verification.value.id)"
                                            class="btn btn-primary"> -
                                        </button>
                                        <button type="button" (click)="cropper.show('document_url')"
                                            class="btn btn-primary"> Crop
                                        </button>
                                    </div>

                                    <div [style.max-height]="getMaxHeight(verification)"
                                        class="col-md-12 identity-edit-image-frame"
                                        id="div_document_url_{{verification.value.id}}">
                                        <img imageZoom style="width: 100%;height: 60%"
                                            [src]="verification.get('document_url')?.value"
                                            id="img_document_url_{{verification.value.id}}" />
                                    </div>
                                </div>
                            </div>

                            <!-- back urls -->
                            <div class="panel" *ngIf="verification.get('back_url')?.value" style="overflow: hidden;">
                                <div class="row">
                                    <div class="col-md-12" style="position: inherit">
                                        <button type="button"
                                            (click)="zoomInImage('img_back_url_'+verification.value.id)"
                                            class="btn btn-primary"> +
                                        </button>
                                        <button type="button"
                                            (click)="zoomOutImage('img_back_url_'+verification.value.id)"
                                            class="btn btn-primary"> -
                                        </button>
                                        <button type="button" (click)="cropper.show('back_url')"
                                            class="btn btn-primary"> Crop
                                        </button>
                                    </div>

                                    <div [style.max-height]="getMaxHeight(verification)"
                                        class="col-md-12 identity-edit-image-frame"
                                        id="div_back_url_{{verification.value.id}}"
                                        *ngIf="verification.get('back_url')?.value">
                                        <img imageZoom style="width: 100%;height: 60%"
                                            [src]="verification.get('back_url')?.value"
                                            id="img_back_url_{{verification.value.id}}" />
                                    </div>
                                </div>
                            </div>

                            <!--Proof Url Panel-->
                            <div class="panel" *ngIf="verification.get('proof_url')?.value" style="overflow: hidden;">
                                <div class="row">
                                    <div class="col-md-12" style="position: inherit">
                                        <button type="button"
                                            (click)="zoomInImage('img_proof_url_'+verification.value.id)"
                                            class="btn btn-primary"> +
                                        </button>
                                        <button type="button"
                                            (click)="zoomOutImage('img_proof_url_'+verification.value.id)"
                                            class="btn btn-primary"> -
                                        </button>
                                        <button type="button" (click)="cropper.show('proof_url')"
                                            class="btn btn-primary"> Crop
                                        </button>
                                    </div>

                                    <div [style.max-height]="getMaxHeight(verification)"
                                        class="col-md-12 identity-edit-image-frame"
                                        id="div_proof_url_{{verification.value.id}}"
                                        *ngIf="verification.get('proof_url')?.value">
                                        <img imageZoom style="width: 100%;height: 60%"
                                            [src]="verification.get('proof_url')?.value"
                                            id="img_proof_url_{{verification.value.id}}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" style="max-height: 600px; overflow-x: scroll;">
                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label class="center-block">Qa Required: </label>
                                            <select class="form-control" formControlName="qa_required">
                                                <option [value]="0"> NO </option>
                                                <option [value]="1"> YES </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="center-block">Qa Done: </label>
                                            <select class="form-control" formControlName="qa_done">
                                                <option [value]="0"> NO </option>
                                                <option [value]="1"> YES </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-md-6 form-group">
                                            <label>Upload Front</label>
                                            <input type="file" id="document_url_{{verification.value.id}}" ng2FileSelect
                                                [uploader]="uploader" class="form-control"
                                                (change)="onDocumentChanged(verification)" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label>Upload Back</label>
                                            <input type="file" id="back_url_{{verification.value.id}}" ng2FileSelect
                                                [uploader]="uploader" class="form-control"
                                                (change)="onBackUrlChanged(verification)" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label>Upload Proof</label>
                                            <input type="file" id="proof_url_{{verification.value.id}}" ng2FileSelect
                                                [uploader]="uploader" class="form-control"
                                                (change)="onProofChanged(verification)" />
                                        </div>


                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Type</label>
                                            <select (change)="onTypeChanged(verification)" class="form-control"
                                                formControlName="type">
                                                <option *ngFor="let document_type of documentTypes"
                                                    [value]="document_type.id">
                                                    {{document_type.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger" *ngIf="verification.get('type')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div *ngIf="statuses" class="col-md-6 form-group">
                                            <label class="center-block">Status</label>
                                            <select (change)="onStatusChanged(verification)" [required]="true"
                                                class="form-control" formControlName="status_id">
                                                <option *ngFor="let status of statuses['document']" [value]="status.id">
                                                    {{status.title}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Completed At</label>
                                            <small class="text-danger" *ngIf="verification.get('completed_at')?.errors">
                                                Required
                                            </small>
                                            <input type="date" class="form-control" formControlName="completed_at"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">
                                                {{ isTypeBankAccount(verification) ? 'Account Number' : 'Doc Number' }}
                                              </label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('document_number')?.errors">
                                                Required
                                            </small>
                                            <input class="form-control" formControlName="document_number">
                                        </div>

                                        <div *ngIf="verification.get('type')?.value != 8" class="col-md-12 form-group">
                                            <label class="center-block">Name</label>
                                            <small class="text-danger" *ngIf="verification.get('name')?.errors">
                                                Only alphabets allowed
                                            </small>
                                            <input class="form-control" formControlName="name" [readonly]="false">
                                        </div>

                                        <div class="col-md-6 form-group" *ngIf="!isTypeCreditReport(verification) && !isTypeBankAccount(verification)">
                                            <label class="center-block">Date of Issue</label>
                                            <input type="date" class="form-control" formControlName="date_of_issue">
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="!isTypeCreditReport(verification) && !isTypeBankAccount(verification)">
                                            <label class="center-block">Date Of Expiry</label>
                                            <input type="date" class="form-control" formControlName="date_of_expiry">
                                        </div>

                                        <div class="col-md-12 form-group" *ngIf="!isTypeCreditReport(verification) && !isTypeBankAccount(verification) && verification.get('type')?.value != 8">
                                            <label class="center-block">Issuing Authority</label>
                                            <input class="form-control" formControlName="issuing_authority">
                                        </div>


                                        <div *ngIf="verification.get('fields')?.value" formGroupName=fields>
                                            <div class="col-md-12 form-group">
                                                <h3>Other Fields</h3>
                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngFor="let field of getFields(verification.get('type')?.value)">
                                                <label class="center-block">{{field.label}}</label>
                                                <input class="form-control" [formControlName]="field.name"
                                                    [type]="field.type">
                                            </div>
                                        </div>

                                        <div *ngIf="verification.get('type')?.value == 8" formGroupName="address">
                                            <div class="col-md-12 form-group"
                                                *ngIf="verification.get('type')?.value == 8">
                                                <label class="center-block">Address</label>
                                                <textarea class="form-control" formControlName="address"></textarea>
                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngIf="verification.get('type')?.value == 8">
                                                <label class="center-block">State</label>
                                                <input formControlName="state_name" [typeahead]="states"
                                                    [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                    placeholder="Select State"
                                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                                    autocomplete="off"
                                                    (typeaheadOnSelect)="OnSelect($event,i,'address.state_id')"
                                                    (typeaheadNoResults)="changeTypeaheadNoResults($event,i,'address.state_id')">
                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngIf="verification.get('type')?.value == 8">
                                                <label class="center-block">District </label>
                                                <input formControlName="district_name"
                                                    [typeahead]="districts |filterByStateId:verification.get('address.state_id')?.value"
                                                    [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                    placeholder="Select District"
                                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                                    autocomplete="off"
                                                    (typeaheadOnSelect)="OnSelect($event,i,'address.district_id')"
                                                    (typeaheadNoResults)="changeTypeaheadNoResults($event,i,'address.district_id')">


                                            </div>
                                            <div class="col-md-12 form-group"
                                                *ngIf="verification.get('type')?.value == 8">
                                                <label class="center-block">Pincode</label>
                                                <small class="text-danger"
                                                    *ngIf="verification.get('address.pincode')?.errors">
                                                    Must contain 6 digits only
                                                </small>
                                                <input class="form-control" formControlName="pincode"
                                                    onkeydown="scrollTo(0,0)">
                                            </div>
                                        </div>


                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Client Remarks:
                                                <textarea rows=2 class="form-control"
                                                    formControlName="client_remarks"></textarea>
                                                <small class="text-danger"
                                                    [hidden]="verification.get('client_remarks')?.valid">
                                                    Required
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-6 form-group">
                                    <label class="center-block">Insufficiency Raised On</label>
                                    <small class="text-danger" *ngIf="verification.get('insuff_raised_at')?.errors">
                                        Invalid
                                    </small>
                                    <input readonly type="date" class="form-control" formControlName="insuff_raised_at"
                                        max="{{current_date}}">
                                </div>

                                <div class="col-md-6 form-group">
                                    <label class="center-block">Insufficiency Cleared On</label>
                                    <small class="text-danger" *ngIf="verification.get('insuff_cleared_at')?.errors">
                                        Invalid
                                    </small>
                                    <input readonly type="date" class="form-control" formControlName="insuff_cleared_at"
                                        max="{{current_date}}">
                                </div>


                                <div class="col-md-12 form-group">

                                    <label>Attachments
                                        <input type="file" id="attachment_{{verification.value.id}}"
                                            class="form-control" multiple
                                            (change)="upload(verification,'attachment')" />
                                    </label>
                                </div>

                                <div class="col-md-6"
                                    *ngIf="verification.get('attachments')?.value['attachments']; else elseDiv">
                                    <div class="row"
                                        *ngFor="let attachment of verification.get('attachments')?.value['attachments']">
                                        <div class="col-md-12">
                                            <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                                {{attachment['file_name']}}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <ng-template #elseDiv>
                                    <div class="col-md-3">
                                        No File Attached
                                    </div>
                                </ng-template>


                                <div class="panel-footer text-right" [appCan]="'admin'">
                                    <div class="row">
                                        <div class="col-sm-offset-7 col-sm-4">
                                            <a class="btn btn-danger"
                                                (click)="onDeleteVerificationButtonClicked(verification.get('id')?.value,i)">
                                                Delete Verification
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
