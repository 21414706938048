<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Edit Client</h1>
</div>

<div class="wrapper-md">

    <div class="row">
        <form [formGroup]="editForm" (ngSubmit)="updateClient()">
            <fieldset [disabled]="isSubmitting">
                <div class="col-md-6">
                    <div class="panel panel-default">
                        <div class="panel-body">

                            <div class="col-md-12 form-group">
                                <input formControlName="id" class="form-control form-control-lg" type="hidden"
                                    ngModel="{{client.id}}" />
                                <label>Client Name</label>
                                <input formControlName="name" class="form-control form-control-lg"
                                    ngModel="{{client.name}}" />

                            </div>
                            <div class="col-md-12 form-group">
                                <label>Email Address </label>
                                <input formControlName="email" type="email" class="form-control form-control-lg"
                                    ngModel="{{client.email}}" />
                            </div>

                            <div class="col-md-12 form-group">
                                <label>Lots </label>
                                <app-multi-select [options]="lots" [placeholder]="'Select Lots'"
                                    [controlName]="'lot_id'" [formGName]="editForm"></app-multi-select>
                            </div>

                            <div class="col-md-12 form-group">
                                <label>Access </label>
                                <app-multi-select [options]="routes" [placeholder]="'Select Routes'"
                                    [controlName]="'access_ids'" [formGName]="editForm"></app-multi-select>
                            </div>

                            <div class="col-md-6 form-group">
                                <label>Designation </label>
                                <input formControlName="designation" class="form-control form-control-lg"
                                    ngModel="{{client.designation}}" />

                            </div>
                            <div class="col-md-6 form-group">
                                <label>Mobile Number </label>
                                <input formControlName="mobile_number" class="form-control form-control-lg"
                                    ngModel="{{client.mobile_number}}" />

                            </div>
                            <button class="btn btn-lg btn-primary btn-block" [disabled]="!editForm.valid">
                                Update
                            </button>

                        </div>
                    </div>
                </div>

            </fieldset>
        </form>
    </div>
</div>