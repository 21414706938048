<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Credit Report Logs Page</h1>
    </div>
    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterByParams()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-credit-report-stats [filterParams]="filterParams"></app-credit-report-stats>

<app-credit-report-filters [filterParams]="filterParams"></app-credit-report-filters>

<app-credit-report-response-modal #creditReportResponseModal></app-credit-report-response-modal>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 5%">Log ID</th>
                        <th style="width: 15%">Request</th>
                        <th style="width: 15%">Client Name</th>
                        <th style="width: 10%">Status</th>
                        <th style="width: 10%">Credit Score</th>
                        <th style="width: 10%">Source</th>
                        <th style="width: 5%">Via</th>
                        <th style="width: 10%">Response</th>
                        <th style="width: 5%">Actions</th>
                        <th style="width: 15%">TimeLine</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>{{log.id}}</td>

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    Candidate Name: {{log.name}}
                                </li>
                                <li>
                                    Mobile Number: {{log.mobile_number}}
                                </li>
                                <li>
                                    Pan Number: {{log.pan_number}}
                                </li>
                                <li *ngIf="log?.date_of_birth">
                                    Date of Birth: {{log.mobile_number}}
                                </li>
                                <li *ngIf="log?.address">
                                    Address: {{log.pan_number}}
                                </li>
                                <li *ngIf="log?.pincode">
                                    Pin Code: {{log.mobile_number}}
                                </li>
                            </ul>
                        </td>
                        <td>
                            {{log.client}}
                        </td>
                        <td>
                            <div>
                                <a class="btn {{getColor(log)}}">{{getLabel(log)}}</a>
                            </div>
                        </td>
                        <td>
                            {{log.credit_score}}
                        </td>
                        <td>
                            {{log.driver | uppercase}}
                        </td>
                        <td>
                            {{log.via | uppercase }}
                        </td>
                        <td>
                            <a class="btn btn-primary btn-addon btn-sm"
                                (click)="creditReportResponseModal.show(log.id, log.driver)">View detailed
                                response</a>
                        </td>
                        <td>
                            <a class="btn btn-primary btn-sm" (click)="resend(log.id)">
                                <i class="fa fa-refresh"></i>
                                Resend
                            </a>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Created At: {{log.created_at}}</li>
                                <li>Completed At: {{log.completed_at}}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>
