<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Submit To ICR</h1>
</div>
<app-verification-stats verification="identity"></app-verification-stats>

<app-verification-filters verification="identity" [filterParams]="filterParams"
    filterBy="company,lot,verification_status,icr_verification_status,address_verification_status,state,district,created_after,created_before,completion_date_from,completion_date_to,track_id">
</app-verification-filters>

<form [formGroup]="icrFormGroup" (keydown.enter)="$event.preventDefault()">
    <div class="wrapper-md">

        <div class="panel panel-default">

            <div class="panel-heading">
                <div class="row" *ngIf="pagination.data">
                    <div class="col-md-6 panel-title">
                        Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                    </div>
                    <div class="col-md-6 text-right text-center-xs">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>


            <div class="row wrapper">
                <div class="col-sm-6" *ngIf="checkedIds.length > 0">
                    <div class="btn-group mar-rgt" dropdown>
                        <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                            Submit All to Vcourt <span class="caret"></span>
                        </button>

                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem" (click)="onSubmitFormButtonClicked('verify24*7')">
                                <a class="dropdown-item">Via Verify24*7</a>
                            </li>
                            <li role="menuitem" (click)="onSubmitFormButtonClicked('instaveritas')">
                                <a class="dropdown-item">Instaveritas</a>
                            </li>

                        </ul>
                    </div>

                    <div class="btn-group mar-rgt" dropdown>
                        <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                            Change All By Identity Types <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem" (click)="copyAddressToIcrFromCheckedIdentity('Voter ID Card')">
                                <a class="dropdown-item">Take Address From Voter ID Card</a>
                            </li>
                            <li role="menuitem" (click)="copyAddressToIcrFromCheckedIdentity('Pan Card')">
                                <a class="dropdown-item">Take Address From PAN Card</a>
                            </li>
                            <li role="menuitem" (click)="copyAddressToIcrFromCheckedIdentity('Aadhaar Card')">
                                <a class="dropdown-item">Take Address From Aadhaar Card</a>
                            </li>
                            <li role="menuitem" (click)="copyAddressToIcrFromCheckedIdentity('Driving Licence')">
                                <a class="dropdown-item">Take Address From Driving Licence</a>
                            </li>
                            <li role="menuitem" (click)="copyAddressToIcrFromCheckedIdentity('Passport')">
                                <a class="dropdown-item">Take Address From Passport</a>
                            </li>


                        </ul>
                    </div>
                    <div class="btn-group mar-rgt" dropdown>
                        <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                            Change Status <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem" *ngFor="let status of statuses['icr']"
                                (click)="onChangeStatusButtonClicked(status.id,status.title)">
                                <a class="dropdown-item">{{status.title}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4"></div>
                <div class="col-sm-2" *ngIf="checkedIds.length > 0">
                    <h5>
                        <strong> {{checkedIds.length}} employees selected</strong>
                    </h5>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped b-t b-lig">
                    <thead>
                        <tr>
                            <th>
                                <label class="i-checks m-b-none">
                                    <input type="checkbox" id="checkAll" (change)="checkAll($event)">
                                    <i></i>
                                </label>
                            </th>
                            <th style="width: 20%;">Employee</th>
                            <th style="width: 40%;">ICR Final Details</th>
                            <th style="width: 40%;">Identity Verifications</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="formArray" *ngIf="employees">
                        <tr *ngFor="let employee of formArray.controls|paginate:{ id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total };let i = index;"
                            [formGroupName]="i">
                            <td>
                                <label class="i-checks m-b-none">
                                    <input type="checkbox" class="checkbox-all" value="{{employee.id}}"
                                        formControlName="is_checked" (change)="getValues(employee,$event)" />
                                    <i></i>
                                </label>
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li>
                                        <a class="btn-link" (click)="showIdentity(employee)">{{
                                            employee.get('name').value}}</a>
                                    </li>
                                    <li>
                                        <strong>Father Name:</strong> {{employee.get('father_name').value}}
                                    </li>
                                    <li>
                                        <strong>DOB:</strong> {{employee.get('dob').value}}
                                    </li>

                                    <li><b>
                                            <a class="btn-link" (click)="showEmployee(employee)">{{
                                                employee.get('track_id').value }}</a>
                                        </b></li>

                                    <li>
                                        <strong>Company:</strong> {{getCompanyName(employee)}}
                                    </li>
                                </ul>

                                <ul class="list-unstyled" style="margin-top: 50px"
                                    *ngFor="let icr of employee.get('icr_verifications_form_array')?.value">
                                    <li>
                                        <button type="button" class="btn btn-secondary {{icr.color}}">
                                            <span class="font">{{icr.status}}</span>
                                        </button>
                                    </li>
                                    <li>
                                        <strong>Full Address:</strong> {{icr.full_address}}
                                    </li>
                                </ul>
                            </td>

                            <td>
                                <div formArrayName="icr_verifications_form_array">
                                    <div class="row"
                                        *ngFor="let icr_verification of employee.get('icr_verifications_form_array')?.controls; let j=index"
                                        [formGroupName]="j">
                                        <div class="col-md-1 form-group" *ngIf="employee.get('is_checked').value">
                                            <label class="i-checks m-b-none">
                                                <input type="checkbox" class="checkbox-all"
                                                    value="{{icr_verification.id}}" formControlName="is_checked" />
                                                <i></i>
                                            </label>
                                        </div>
                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Address</label>
                                            <textarea class="form-control" formControlName=address required></textarea>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input formControlName="state_name" [typeahead]="states"
                                                [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                placeholder="Select State" [typeaheadItemTemplate]="customItemTemplate"
                                                class="form-control"
                                                (typeaheadOnSelect)="OnTypeheadSelect($event,'state', icr_verification)"
                                                required>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input formControlName="district_name"
                                                [typeahead]="districts | filterByStateId:icr_verification.get('state_id').value"
                                                [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                placeholder="Select District"
                                                [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                                (typeaheadOnSelect)="OnTypeheadSelect($event,'district', icr_verification)"
                                                required>

                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Landmark</label>
                                            <input formControlName="landmark" class="form-control"
                                                placeholder="landmark">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Pincode</label>
                                            <input formControlName="pincode" class="form-control" placeholder="pincode">
                                        </div>
                                    </div>
                                </div>


                            <td>

                                <ul class="list-unstyled"
                                    *ngFor="let verification of employee.get('identity_verifications').value; let j = index">
                                    <h5>{{ verification.type_name }} : {{ verification.status }}</h5>
                                    <li>Address: {{ verification.address }}</li>
                                    <li [ngClass]="{'text-danger': !verification.district_id}">District:
                                        {{verification.district_name }}
                                    </li>
                                    <li [ngClass]="{'text-danger': !verification.state_id}">State:
                                        {{verification.state_name
                                        }}
                                    </li>
                                    <li>Pincode: {{verification.pincode }}
                                    </li>

                                    <div class="btn-group mar-rgt" dropdown>
                                        <button [ngClass]="{ 'hidden':!verification.state_id}" dropdownToggle
                                            type="button" class="btn btn-primary dropdown-toggle">
                                            Copy {{verification.type_name }} <span class="caret"></span>
                                        </button>
                                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                                            <li role="menuitem"
                                                *ngFor="let icr_verification of employee.get('icr_verifications_form_array').controls;let i = index"
                                                (click)="copyToIcr(verification, employee, i, icr_verification)">
                                                <a class="dropdown-item">Copy to Icr {{i+1}}</a>
                                            </li>

                                        </ul>
                                    </div>

                                    <br>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <footer class="panel-footer">
                <div class="row" *ngIf="pagination.data">
                    <div class="col-sm-12 text-right text-center-xs">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</form>