import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { Pagination } from 'src/app/core/models/pagination.model';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';
import { Company } from '../../core/models/company.model';
import { CompanyService } from '../../core/services/company.service';


@Component( {
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: [ './company-list.component.css' ],
} )
export class CompanyListComponent implements OnInit, OnDestroy {
    filterParams: HttpParams = new HttpParams();
    loading: boolean = false;
    companies: Company[] = [];
    filter: Subscription = new Subscription;
    fetchingInProgress: boolean = false;
    pagination: Pagination = new Pagination();
    filtersSubscription: Subscription = new Subscription;

    constructor (
        private companyService: CompanyService,
        public filterService: FilterService,
    ) {
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );

        this.filterResults( {} );

    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

    filterResults ( params: Params ): void {
        const updatedParams: Params = this.updateParams( params );

        this.filter = this.companyService.filter( updatedParams ).subscribe( {
            next: ( response ) => {
                this.pagination = response;
            }
        }
        );
    }

    showPage ( page: string | number ): void {
        this.filterService.filterByPage( page );
    }


    updateParams ( params: Params ): Params {

        params = Object.assign( {}, params );

        Object.keys( params ).forEach( key => {
            if ( key === 'company_id' ) {
                params[ key ] = params[ key ].toString();
            }
        } );

        return params;
    }
}

