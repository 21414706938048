<form [formGroup]="verificationFormGroup" (ngSubmit)="updateVerification('global-db')"
    (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">

        <div *ngIf="employee && employee.id" class="row bg-light lter b-b wrapper-md">

            <app-verification-edit-header [verification]="'global-db'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>
            <div class="col-md-6">

                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createGlobalDb()">
                    <i class="fa fa-plus"></i>
                    Add Global DB Verification
                </a>

            </div>

        </div>


        <div class="wrapper-md">

            <div class="row">

                <div class="col-md-3">

                    <!--Employee Details-->
                    <app-employee-side-block [refreshEmployee]="refreshEmployee"></app-employee-side-block>
                    <app-employee-basic-details [employee]="employee" [submitting]="isSubmitting">
                    </app-employee-basic-details>

                    <!--Identity Verification-->
                    <div *ngIf="employee">
                        <div class="panel panel-default"
                            *ngFor="let identity of employee.identity_verifications; let i=index">
                            <div class="panel-heading">
                                ID Verification #{{i+1}}
                            </div>

                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <label> Status : {{identity.status_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Name : {{identity.name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Father Name : {{identity.father_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Date of Birth : {{identity.dob}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Type Of Document : {{identity.type_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Address : {{identity.address}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> State : {{identity.state_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> District : {{identity.district_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Pincode : {{identity.pincode}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Remarks-->
                    <app-employee-remarks *ngIf="employee" [remarks]="employee.remarks"></app-employee-remarks>

                </div>

                <div class="col-md-9">
                    <div formArrayName="formArray">
                        <div *ngFor="let verification of formArray.controls; let i=index" [formGroupName]="i">

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    Global Database Verification
                                </div>

                                <div class="panel-body">

                                    <div class="row">
                                        <div class="form-group col-md-3">
                                            <label class="center-block">Qa Required: </label>
                                            <select class="form-control" formControlName="qa_required">
                                                <option [value]="0"> NO </option>
                                                <option [value]="1"> YES </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="center-block">Qa Done: </label>
                                            <select class="form-control" formControlName="qa_done">
                                                <option [value]="0"> NO </option>
                                                <option [value]="1"> YES </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="verification.value.doc_type">
                                        <div class="form-group col-md-4">
                                            <label class="center-block">ID Type:
                                                {{verification.value.doc_type.toUpperCase()}}
                                            </label>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <a href="{{verification.value.front_url}}">Front Url</a>
                                        </div>
                                        <div class="form-group col-md-4" *ngIf="verification.value.back_url">
                                            <a href="{{verification.value.back_url}}">Back Url</a>
                                        </div>
                                        <br>

                                    </div>
                                    <div class="row">
                                        <div *ngIf="statuses" class="col-md-3 form-group">

                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'status'" [labelName]="'Status'"
                                                [options]="statuses['globaldb']">
                                            </app-typeahead-dropdown>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3">

                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'country'" [labelName]="'Country'"
                                                [options]="countries">
                                            </app-typeahead-dropdown>
                                        </div>

                                        <div class="form-group col-md-9">
                                            <label class="center-block ">Address:
                                                <input class="form-control" formControlName="address">
                                            </label>
                                            <small class="text-danger" *ngIf="verification.get('address')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="col-md-3 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'state'" [labelName]="'State'"
                                                [options]="states">
                                            </app-typeahead-dropdown>
                                            <small class="text-danger" *ngIf="verification.get('state_id')?.errors">
                                                Required
                                            </small>

                                        </div>
                                        <div class="col-md-3 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'district'" [labelName]="'District'"
                                                [options]="districts | filterByStateId:verification.get('state_id')?.value">

                                            </app-typeahead-dropdown>
                                            <small class="text-danger" *ngIf="verification.get('district_id')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">LandMark:
                                                <input class="form-control" formControlName="landmark">
                                            </label>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Pincode:
                                                <input class="form-control" formControlName="pincode">
                                            </label>
                                            <small class="text-danger" *ngIf="verification.get('pincode')?.errors">
                                                Required
                                            </small>
                                        </div>

                                    </div>
                                    <div class="row">

                                        <div class="form-group col-md-3">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'vendor'" [labelName]="'Vendor'"
                                                [options]="vendors">
                                            </app-typeahead-dropdown>
                                            <small class="text-danger" *ngIf="verification.get('vendor_id')?.errors">
                                                Required
                                            </small>
                                        </div>




                                    </div>
                                    <div class="row">

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Sent On:
                                                <input type="date" class="form-control"
                                                    formControlName="sent_to_vendor_on"
                                                    min="{{employee.created_at.slice(0,10)}}"
                                                    max="{{verification.get('completed_at')?.value}}">
                                            </label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('sent_to_vendor_on')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="col-md-3 form-group">
                                            <label class="center-block">Completed At
                                                <input type="date" class="form-control"
                                                    min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}"
                                                    formControlName="completed_at">
                                                <small class="text-danger"
                                                    *ngIf="verification.get('completed_at')?.errors">
                                                    Required
                                                </small>
                                            </label>
                                        </div>

                                        <div class="row">

                                            <div class="col-md-3 form-group">
                                                <label class="center-block">Insufficiency Raised On</label>
                                                <small class="text-danger"
                                                    *ngIf="verification.get('insuff_raised_at')?.errors">
                                                    Invalid
                                                </small>
                                                <input readonly type="date" class="form-control"
                                                    formControlName="insuff_raised_at" max="{{current_date}}">
                                            </div>

                                            <div class="col-md-3 form-group">
                                                <label class="center-block">Insufficiency Cleared On</label>
                                                <small class="text-danger"
                                                    *ngIf="verification.get('insuff_cleared_at')?.errors">
                                                    Invalid
                                                </small>
                                                <input readonly type="date" class="form-control"
                                                    formControlName="insuff_cleared_at" max="{{current_date}}">
                                            </div>
                                        </div>

                                        <div class="col-md-3">

                                            <label>Upload Proofs
                                                <input type="file" id="proof_url_{{verification.value.id}}" multiple
                                                    class="form-control" (change)="upload(verification,'proof_url')" />
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Client Remarks:
                                                <textarea rows=3 class="form-control" formControlName="client_remarks">
                                                </textarea>
                                            </label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('client_remarks')?.errors">
                                                Required
                                            </small>
                                        </div>
                                    </div>
                                    <div *ngIf="attachments[i]">
                                        <div class="row" *ngIf="attachments[i]['attachments']">

                                            <div class="col-md-2"
                                                *ngFor="let attachment of attachments[i]['attachments']">

                                                <a href="{{attachment['full_path']}}" class="btn-link">
                                                    {{attachment['file_name']}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-footer text-right" [appCan]="'admin'">
                                    <div class="row">
                                        <div class="col-sm-offset-8 col-sm-4">
                                            <a class="btn btn-danger"
                                                (click)="onDeleteVerificationButtonClicked(verification.get('id')?.value,i)">
                                                Delete Verification
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>