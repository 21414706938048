import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { Employee } from '../../core/models/employee.model';
import { EmploymentVerification } from '../../core/models/employment-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { EmploymentCompanyService } from 'src/app/core/services/employment-company.service';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmploymentEmailModalInitialState } from 'src/app/core/models/employment-email-modal-initial-state';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-employment-edit-page',
    templateUrl: './employment-edit-page.component.html',
    styleUrls: [ './employment-edit-page.component.css' ],
} )
export class EmploymentEditPageComponent extends VerificationEditComponent implements OnInit {
    [ x: string ]: any;

    verifications: EmploymentVerification[] = [];
    override attachments: any[] = [];
    vendors!: any[];
    statusChanged: boolean = false;

    constructor (
        protected override verificationService: EmploymentService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override common: CommonService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected commonService: CommonService,
        private employmentCompanyService: EmploymentCompanyService,
        private bsModalService: BsModalService,
        protected override confirmationModalService: ConfirmationModalService
    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );

        // Initialise the identity verification form
        // with the required form controls
        this.initForm();

    }

    get isEmailDisabled (): boolean { return this.employee.settings.email_visibility }

    initForm (): void {
        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            name: [ null ],
            client_employee_id: [ null ],
            mobile_number: [ null ],
            father_name: [ null ],
            dob: [ null ],
            remarks: [ null ],
            id: [ null ],
            formArray: this.fb.array( [] ),
        } );
    }

    override ngOnInit (): void {

        this.verification = 'employment';
        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( ( params: any ) => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                ( employee: Employee ) => {
                    this.employee = employee;
                    this.populateForm( employee );
                    this.getAttachments( employee );
                    this.isSubmitting = false;
                    this.applyDefaults();
                }
            );
        } );

        // Subscribe to changes in the form.
        this.subscribeToQueryChanges();

        this.common.getVendors( 'employment' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
    }

    populateForm ( employee: Employee ): void {

        this.verificationFormGroup.patchValue( employee );

        const verifications: EmploymentVerification[] = employee.employment_verifications as EmploymentVerification[];

        const verificationFormGroup: FormGroup[] = verifications.map( verification => this.fb.group( verification ) );

        const verificationGroupArray: FormArray = this.fb.array( verificationFormGroup );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );

    }

    createEmployment (): void {

        this.verificationService.create( this.employee.id ).subscribe(
            ( response: any ) => {
                this.populateForm( response );
                this.applyDefaults();
            }
        );

        this.common.notifications.success( 'Employment Verification added' );
    }


    applyDefaults (): void {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );


            // Subscribe to any Change in Verification Status
            form.get( 'status_id' )?.valueChanges.subscribe(
                ( statusId: any ) => {
                    this.statusChanged = true;

                }
            );
        } );
    }


    // Call back from the modal to update the list of all companies.
    onCompanyCreated (): void {
        this.employmentCompanyService.populateCache( true );
    }

    verificationUpdated (): void {
        this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
            employee => {
                this.employee = employee;
                this.populateForm( employee );
                this.getAttachments( employee );
            }
        );
    }

    onEmailSent (): void {

        this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
            employee => {
                this.employee = employee;
                this.populateForm( employee );
                this.getAttachments( employee );
                this.isSubmitting = false;
                this.applyDefaults();
            }
        );

    }


    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.cdr.detectChanges();
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                () => {
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }


    sendConsentForm ( employeeId: number ): void {

        this.verificationService.sendConsentMail( employeeId ).subscribe( {
            next: ( response: any ) => {

                if ( response.success ) {

                    this.commonService.notifications.success( 'Email sent Successfully' );

                } else {

                    this.commonService.notifications.error( 'Unable To Send Mail Please Check Email' );

                }

            },
            error: ( error ) => {

                this.commonService.notifications.error( error );

            }
        } );
    }

    onSendConsentFormButtonClicked ( employeeId: number ): void {

        const options: ConfirmationModalOptions = { text: 'You want to send consent mail?' };

        this.confirmationModalService.confirm( options, () => this.sendConsentForm( employeeId ) );


    }

    sendEmail ( verification: AbstractControl ) {

        const initialState: EmploymentEmailModalInitialState = {
            verification: verification,
            attachments: verification.get( 'attachments' )?.value,
            employeeDocuments: this.employee.documents
        };

        const modal = this.verificationService.showSendEmailModal( initialState );

        modal.onHidden.subscribe( () => {
            this.onEmailSent();
        } );
    }

    sendFollowUpMail ( verification: any ) {

        const initialState: EmploymentEmailModalInitialState = {
            verification: verification,
            attachments: verification.get( 'attachments' )?.value,
            employeeDocuments: this.employee.documents
        };

        const modal = this.verificationService.showSendFollowupEmailModal( initialState );

        modal.onHidden.subscribe( () => {
            this.onEmailSent();
        } );
    }
}
