import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { ClientService } from 'src/app/core/services/client.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { HttpParams } from '@angular/common/http';

@Component( {
    selector: 'app-all-clients-list',
    templateUrl: './all-clients-list.component.html',
    styleUrls: [ './all-clients-list.component.css' ]
} )
export class AllClientsListComponent implements OnInit, OnDestroy {

    pagination: any;
    filtersSubscription: any;
    filterParams: HttpParams = new HttpParams();

    constructor ( private clientService: ClientService,
        private filterService: FilterService,
    ) {
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: HttpParams ) => {
            this.filterResults();
            this.filterParams = params;
        } );

        // The subscription callback triggers only when query params exist or change.
        // To ensure it runs initially, we call filterResults() here.
        this.filterResults();

    }

    updateParams ( params: any ): HttpParams {

        let httpParams = new HttpParams();

        const multipleValueFilters = [ 'company_id' ];

        Object.keys( params ).forEach( key => {
            if ( multipleValueFilters.includes( key ) ) {
                httpParams = httpParams.set( key, params[ key ].toString() );
            } else {
                httpParams = httpParams.set( key, params[ key ] );
            }
        } );

        return httpParams;
    }


    filterResults (): void {

        const updatedParams = this.updateParams( this.filterService.filters );

        this.clientService.getAll( updatedParams ).subscribe(

            ( response: any ) => {

                this.pagination = response;

            }
        );

    }

    exportExcel () {

        const updatedParams = this.updateParams( this.filterService.filters );

        const initialState: DownloadExcelInitialState = {
            filterParams: updatedParams,
            paramsString: updatedParams?.toString(),
            route: 'clients/download-excel',
            fileName: 'Clients List',
            method: 'get',
            postParams: null,
        };

        this.clientService.showDownloadModal( initialState );
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }
}
