<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Digital Address List</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="refreshPage()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-digital-address-follow-up-modal #followUpDetailsModal
    (onInviteCreate)="inviteCreateStatus($event)"></app-digital-address-follow-up-modal>

<app-digital-address-filters></app-digital-address-filters>

<div class="list-page-buttons">
    <div class="btn-group" role="group">
        <button appCan="editor" class="btn btn-primary btn-space" (click)="onDownloadExcel()">
            <i class="fa fa-edit"></i>
            Download Excel
        </button>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination && pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 18%;">Candidate</th>
                        <th style="width: 17%;">Details</th>
                        <th style="width: 13%;">Sender</th>
                        <th style="width: 13%;">Last Digital Invite Sender</th>
                        <th style="width: 10%;">Last Email Status</th>
                        <th style="width: 10%;">Digital Address Status</th>
                        <th style="width: 10%;">SMS Status</th>
                        <th style="width: 9%;">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let digitaladdressform of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    };
              let i = index
            ">
                        <td>
                            <div *ngIf="digitaladdressform.employee_id; else elseBlock">
                                <a routerLink="/employees/{{digitaladdressform.employee_id}}/edit">
                                    <b>Name:</b> {{ digitaladdressform.name }} <br />
                                </a>
                            </div>

                            <ng-template #elseBlock>
                                <b>Name:</b> {{ digitaladdressform.name }} <br />
                            </ng-template>

                            <b>Email:</b> {{ digitaladdressform.email_id }} <br />

                            <b>Number: </b> {{ digitaladdressform.number }} <br />

                            <div *ngIf="digitaladdressform.employee_id">
                                <a routerLink="/employees/{{digitaladdressform.employee_id}}/edit">
                                    <b>Employee Id</b> {{ digitaladdressform.employee_id}}
                                </a>
                            </div>

                            <strong>Sent on:</strong> {{ digitaladdressform.last_sent_at }}<br />

                            <li *ngIf="digitaladdressform.filled_at">
                                <strong>TAT:</strong> {{ digitaladdressform.tat }} days
                            </li>

                            <strong>{{digitaladdressform.address.type | titlecase}} Address:</strong>

                            <a routerLink="/address/{{digitaladdressform.employee_id}}/edit">
                                {{ digitaladdressform.address.full_address}}
                            </a>
                            <br />
                        </td>
                        <td>

                            <strong>Company: </strong>
                            <a class="btn-link text-main text-semibold lots"
                                routerLink="/companies/{{digitaladdressform.lot?.company_id}}">
                                {{ digitaladdressform.company }}
                            </a><br />
                            <strong>Lot: </strong>
                            <a class="btn-link text-main text-semibold lots"
                                routerLink="/lots/{{digitaladdressform.lot?.lot_id}}">
                                {{ digitaladdressform.lot?.lot_name }}
                            </a><br />

                            <strong>Form: </strong>
                            <a class="btn-link text-main text-semibold lots"
                                routerLink="/forms/{{digitaladdressform.form_id}}/edit">
                                {{digitaladdressform.form_name}}
                            </a><br />

                            <strong>Email FollowUp Count:</strong>
                            {{ digitaladdressform.email_follow_up_count }} Followup email sent <br />

                            <strong>Sms FollowUp Count:</strong>
                            {{ digitaladdressform.sms_follow_up_count }} Followup sms sent <br />

                            <strong>Is Filled: </strong>
                            <span>{{digitaladdressform.is_filled ? 'YES' : 'NO'}}</span><br />

                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong>Company : </strong> {{ digitaladdressform.creator?.company }}
                                </li>
                                <li>
                                    <strong>Name : </strong>{{ digitaladdressform.creator?.name }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong> Company : </strong> {{ digitaladdressform.sender?.company }}
                                </li>
                                <li>
                                    <strong> Name : </strong> {{ digitaladdressform.sender?.name }}

                                </li>
                            </ul>
                        </td>
                        <td>
                            <ng-container *ngIf="digitaladdressform.last_email">
                                <div class="row">
                                    <app-digital-address-invite-email-list-item
                                        [lastEmail]="digitaladdressform.last_email"></app-digital-address-invite-email-list-item>
                                </div>
                            </ng-container>
                        </td>
                        <td style="text-align: center;">
                            <button (click)="followUpDetailsModal.show(digitaladdressform.id, digitaladdressform.name)"
                                class="btn btn-block {{getStatusColor(digitaladdressform.status)}} btn-status p-1">
                                {{digitaladdressform.status | uppercase}}
                            </button>
                            <a *ngIf="digitaladdressform.follow_up_count"
                                (click)="followUpDetailsModal.show(digitaladdressform.id, digitaladdressform.name)">
                                See Followups
                            </a>
                        </td>

                        <td>
                            <ng-container *ngIf="digitaladdressform.last_sms">
                                <div class="row">
                                    <app-digital-address-invite-sms-list-item [lastSms]="digitaladdressform.last_sms"
                                        [smsLogs]="digitaladdressform.sms_logs"></app-digital-address-invite-sms-list-item>
                                </div>
                            </ng-container>
                        </td>

                        <td>
                            <button (click)="onSendFollowUpButtonClicked(digitaladdressform.id)"
                                class="btn btn-primary btn-addon" *ngIf="showFollowUpButton(digitaladdressform)">
                                Send Follow Up
                            </button>

                            <!-- <button (click)="toggleInviteStatus(digitaladdressform.id)"
                                class="btn btn-{{digitaladdressform.is_active? 'danger': 'primary'}} btn-addon">
                                {{digitaladdressform.is_active? 'Mark Inactive': 'Mark Active'}}
                            </button><br /><br /> -->

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>