<div class="wrapper-md">
    <form [formGroup]="editCrmForm" (ngSubmit)="submitForm()">
        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Basic Info
                    </div>
                    <div class="panel-body">
                        <fieldset class="form-group" *ngIf="companies">
                            <label class="center-block"> Select Company</label>
                            <app-company-dropdown-field [form]="editCrmForm" (changed)="onCompanyChange()" />
                        </fieldset>

                        <fieldset class="form-group" id="lot" *ngIf="lots">
                            <label class="center-block"> Select Lot</label>
                            <select class="form-control" formControlName="lot_id" (change)="onlotChange($event)">
                                <option value="">Select Type</option>
                                <option *ngFor="let lot of lots" [value]="lot?.id">{{ lot?.name}}</option>
                            </select>
                        </fieldset>

                        <fieldset class="form-group">
                            <label class="center-block"> Select CRM Client</label>
                            <select class="form-control" formControlName="type">
                                <option *ngFor="let clientCrm of clientCrms | keyvalue" [value]="clientCrm.value">
                                    {{clientCrm.key}}
                                </option>
                            </select>

                        </fieldset>

                        <fieldset class="form-group">
                            <label>Url</label>
                            <input type="text" placeholder="www.example.com" class="form-control form-control-lg"
                                formControlName="url" />
                        </fieldset>

                    </div>
                </div>

                <div class="panel panel-default">
                    <div class="panel-heading">
                        Headers
                        <a type="button" (click)="addNewRow('headers')"
                            class="pull-right btn m-b-xs btn-sm btn-primary btn-addon">
                            <i class="fa fa-plus"></i> Add Field
                        </a>
                    </div>
                    <div class="panel-body">
                        <div formArrayName="headers">
                            <div class="row" *ngFor="let headersFormGroup of headers.controls;let i = index"
                                [formGroupName]="i">
                                <div class="col-md-5 form-group">
                                    <label class="center-block"> Header </label>
                                    <input class="form-control" formControlName="header">

                                </div>
                                <div class="col-md-5 form-group">
                                    <label class="center-block">
                                        Value
                                    </label>
                                    <input class="form-control" formControlName="value">
                                </div>
                                <div class="col-md-2 form-group" style="margin-top: 25px;">
                                    <a type="button" (click)="deleteRow('headers',i)"
                                        class="pull-right btn m-b-xs btn-sm btn-danger btn-addon">
                                        <i class="fa fa-trash"></i> Delete
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel panel-default">
                    <div class="panel-heading">
                        Params
                        <a type="button" (click)="addNewRow('params')"
                            class="pull-right btn m-b-xs btn-sm btn-primary btn-addon">
                            <i class="fa fa-plus"></i> Add Field
                        </a>
                    </div>
                    <div class="panel-body">
                        <div formArrayName="params">
                            <div class="row" *ngFor="let paramFormGrup of params.controls;let i = index"
                                [formGroupName]="i">
                                <div class="col-md-5 form-group">
                                    <label class="center-block"> Param </label>
                                    <input class="form-control" formControlName="param">

                                </div>
                                <div class="col-md-5 form-group">
                                    <label class="center-block">
                                        Value
                                    </label>
                                    <input class="form-control" formControlName="value">
                                </div>
                                <div class="col-md-2 form-group" style="margin-top: 25px;">
                                    <a type="button" (click)="deleteRow('params',i)"
                                        class="pull-right btn m-b-xs btn-sm btn-danger btn-addon">
                                        <i class="fa fa-trash"></i> Delete
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-12 center">
                        <button id="submitButton" class="btn btn-lg btn-primary btn-block"
                            [disabled]="editCrmForm.invalid">
                            Update
                        </button>
                    </div>

                </div>

            </div>

            <div class="col-md-6">

                <div class="panel panel-default">
                    <div class="panel-heading">
                        Verifications
                        <a type="button" (click)="addNewRow('verification')"
                            class="pull-right btn m-b-xs btn-sm btn-primary btn-addon">
                            <i class="fa fa-plus"></i> Add Field
                        </a>
                    </div>
                    <div class="panel-body">
                        <div formArrayName="verifications">
                            <div class="row" *ngFor="let verificationFormGroup of verifications.controls;let i = index"
                                [formGroupName]="i">
                                <div class="col-md-5 form-group">
                                    <label class="center-block"> Verification Name </label>
                                    <input class="form-control" formControlName="verification_name">

                                </div>
                                <div class="col-md-5 form-group">
                                    <label class="center-block">
                                        Value
                                    </label>
                                    <input class="form-control" formControlName="value">
                                </div>
                                <div class="col-md-2 form-group" style="margin-top: 25px;">
                                    <a type="button" (click)="deleteRow('verification',i)"
                                        class="pull-right btn m-b-xs btn-sm btn-danger btn-addon">
                                        <i class="fa fa-trash"></i> Delete
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>