import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Lot } from '../../../core/models/lot.model';
import { CommonService } from '../../../core/services/common.service';
import { CompanyService } from '../../../core/services/company.service';
import { LotService } from '../../../core/services/lot.service';
import { ClientCrmService } from '../../../core/services/client-crm.service';
import { ClientCrms } from '../../../core/enums/client-crms.enum'
import { GlobalService } from 'src/app/core/services/global.service';
import { Company } from 'src/app/core/models/company.model';

@Component( {
    selector: 'app-client-crm-create',
    templateUrl: './client-crm-create.component.html',
} )
export class ClientCrmCreateComponent implements OnInit {

    createCrmForm!: FormGroup;
    companyId!: number;
    lots!: Lot[];
    lot!: Lot;
    clientCrms = ClientCrms;
    companies: Company[] = []


    get headers (): FormArray { return this.createCrmForm.get( 'headers' ) as FormArray; }

    get params (): FormArray { return this.createCrmForm.get( 'params' ) as FormArray; }

    get verifications (): FormArray { return this.createCrmForm.get( 'verifications' ) as FormArray; }

    constructor ( private fb: FormBuilder,
        private route: Router,
        private router: ActivatedRoute,
        private companyService: CompanyService,
        private lotService: LotService,
        public crmService: ClientCrmService,
        public commonService: CommonService,
        public globalService: GlobalService
    ) { }

    ngOnInit (): void {

        this.companies = this.globalService.fetchCompanies();

        this.initForm();
        this.companyId = this.router.snapshot.queryParams[ 'companyId' ];

        if ( this.companyId ) {
            this.createCrmForm.get( 'company_id' )?.setValue( this.companyId );
            this.fetchLots();
        }
    }

    onCompanyChange (): void {

        this.companyId = this.createCrmForm.get( 'company_id' )?.value;

        if ( !this.companyId ) return;

        this.fetchLots();
    }

    onlotChange ( $event: any ): void {
        this.lotService.find( $event.target.value ).subscribe( response => {
            this.lot = response;
        } );
    }

    fetchLots (): void {
        this.companyService.getLots( this.companyId ).subscribe( response => {
            this.lots = response;
        } );
    }

    initForm (): void {
        this.createCrmForm = this.fb.group( {
            company_id: [ '', Validators.required ],
            lot_id: [ '', Validators.required ],
            type: [ '', Validators.required ],
            url: [ '', Validators.required ],
            headers: this.fb.array( [
                this.createHeadersRow()
            ] ),
            params: this.fb.array( [ this.createParamsRow() ] ),
            verifications: this.fb.array( [ this.createVerificationsRow() ] ),
        } );
    }

    createHeadersRow (): FormGroup {
        return this.fb.group( {
            header: [ '', [ Validators.required ] ],
            value: [ '', [ Validators.required ] ],
        } );
    }

    createParamsRow (): FormGroup {
        return this.fb.group( {
            param: [ '', [ Validators.required ] ],
            value: [ '', [ Validators.required ] ],
        } );
    }

    createVerificationsRow (): FormGroup {
        return this.fb.group( {
            verification_name: [ '', [ Validators.required ] ],
            value: [ '', [ Validators.required ] ],
        } );
    }

    addNewRow ( type: string ): void {

        if ( type === 'params' ) {
            this.params.push( this.createParamsRow() );
        }

        if ( type === 'headers' ) {
            this.headers.push( this.createHeadersRow() )
        }

        if ( type === 'verification' ) {
            this.verifications.push( this.createVerificationsRow() )
        }

    }

    deleteRow ( type: string, index: number ): void {
        if ( type === 'params' ) {
            this.params.removeAt( index );
        }

        if ( type === 'headers' ) {
            this.headers.removeAt( index );
        }

        if ( type === 'verification' ) {
            this.verifications.removeAt( index );
        }
    }

    submitForm (): void {

        const paresedFormData: any = this.crmService.parseData( this.createCrmForm );

        this.crmService.create( paresedFormData ).subscribe( response => {
            this.commonService.notifications.success( response.message );
            this.route.navigateByUrl( '/client-crm' );
        } )
    }

}

