<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">My Work List</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div *ngIf="companies" class="form-group col-md-2">
                        <label class="center-block">Company</label>
                        <app-company-dropdown-field [form]="filterFormGroup" (changed)="onCompanySelected()" />
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Lot</label>
                        <select class="form-control" formControlName="lot_id">
                            <option *ngFor="let lot of lots" [value]="lot.id">
                                {{lot.name}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Verification</label>
                        <select class="form-control" formControlName="work_assignable_type">
                            <option *ngFor="let verification of verifications" [value]="verification.shortTitle">
                                {{verification.title}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Track Id</label>
                        <input class="form-control" formControlName="track_id">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Created On</label>
                        <input class="form-control" type="date" formControlName="created_on">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Status</label>
                        <select class="form-control" formControlName="is_completed">
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Verification</th>
                        <th>Work</th>
                        <th>Track ID</th>
                        <th>Assigned To</th>
                        <th>Assigned By</th>
                        <th>Verification Status</th>
                        <th>Timeline</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let work of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">
                        <td>
                            <a class="btn-link text-info"
                                routerLink="/{{work.verification_type}}/{{work.employee_id}}/edit">
                                {{work.verification_type | uppercase}}-{{work.track_id}}
                            </a>
                        </td>
                        <td>{{work.title}}</td>
                        <td>{{work.track_id}}</td>
                        <td>{{work.assigned_to}}</td>
                        <td>{{work.assigned_by}}</td>
                        <td>{{work.verification_status}}</td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong>Assigned At: </strong>{{work.assigned_at}}
                                </li>
                                <li *ngIf="work.completed_at">
                                    <strong>Completed At: </strong>{{work.completed_at}}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>

</div>