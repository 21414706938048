import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors } from '../../core/models/errors.module';
import { AuthDataService } from '../../core/services/auth-data.service';
import { AuthService, SubmitOtpResponse } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { GlobalService } from '../../core/services/global.service';

@Component( {
    selector: 'app-submit-otp',
    templateUrl: './submit-otp.component.html',
    styleUrls: [ './submit-otp.component.css' ]
} )
export class SubmitOtpComponent implements OnInit, OnDestroy {

    errors: Errors = new Errors();
    isSubmitting = false;
    authForm: FormGroup;
    userEmail: string | null = null;
    remainingSeconds: number = 30;
    private countDownInterval: any;

    redirectUrl!: string;

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private globalService: GlobalService,
        public authDataService: AuthDataService
    ) {
        this.authForm = this.fb.group( {
            'otp': [ '', [ Validators.required, Validators.pattern( '^[0-9]{6}$' ) ] ],
        } );

    }

    get shouldShowResend (): boolean {
        return this.remainingSeconds <= 1;
    }

    ngOnInit () {

        this.startResendTimer();

        this.userEmail = this.authDataService.getUserEmail();

        if ( !this.userEmail ) {
            this.router.navigate( [ '/login' ] );
        }

        this.redirectUrl = this.route.snapshot.queryParamMap.get( 'redirectUrl' ) ?? '/home';

        if ( localStorage.getItem( 'token' ) ) {
            this.router.navigateByUrl( this.redirectUrl );
        }

        this.route.url.subscribe( {
            next: ( data ) => { },
        } );

    }

    ngOnDestroy (): void {

        clearInterval( this.countDownInterval );

    }

    // Prevents reload on the component
    @HostListener( 'window:beforeunload', [ '$event' ] )
    unloadNotification ( $event: any ) {

        $event.returnValue = true;

    }

    submitOtp () {

        this.errors = new Errors();

        const otp = this.authForm.controls[ 'otp' ].value;

        const userEmail = this.authDataService.getUserEmail();

        if ( !userEmail ) {
            this.commonService.notifications.error( 'User email not found' );
            this.router.navigate( [ '/login' ] );
            return;
        }

        this.authService.submitOtp( otp, userEmail ).subscribe( {
            next: ( response ) => {
                this.onSuccessResponse( response );
            },
            error: ( errors ) => {
                this.errors = errors;
                this.isSubmitting = false;
                this.commonService.notifications.error( errors.error.message );
            }
        } )

    }

    startResendTimer (): void {

        this.countDownInterval = setInterval( () => {

            if ( this.remainingSeconds > 0 ) {

                this.remainingSeconds--;

            } else {

                clearInterval( this.countDownInterval );

            }

        }, 1000 );

    }

    retryLogin (): void {

        this.authDataService.setShowTwoFactorPage( false );

        this.router.navigate( [ '/login' ] );

    }

    private onSuccessResponse ( response: SubmitOtpResponse ) {

        const token = response.token;

        if ( token ) {

            localStorage.setItem( 'token', token );

            this.globalService.populateGlobalVariables();

            this.router.navigateByUrl( this.redirectUrl );
        }

        this.commonService.notifications.success( response.message );

        this.authService.userLoggedIn.emit( true );

    }
}
