import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { GlobalService } from '../core/services/global.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CommonService } from '../core/services/common.service';
import { Pagination } from '../core/models/pagination.model';
import { ChangeDetectorRef, Injectable, OnInit } from '@angular/core';
import { IdentityService } from '../core/services/identity.service';
import { IcrService } from '../core/services/icr.service';
import { Employee } from '../core/models/employee.model';
import { ConfirmationModalOptions, ConfirmationModalService } from '../core/services/confirmation-modal.service';
import { SubmitVcourtResponse } from '../core/models/submit-vcourt-response.model';


@Injectable( {
    providedIn: 'root'
} )
export abstract class IcrVcourtComponent implements OnInit {

    loading!: boolean;
    employees: Employee[] = [];
    pagination: Pagination = new Pagination();
    icrFormGroup!: FormGroup;
    filterParams: HttpParams = new HttpParams();
    checkedIds: any = [];
    verification: string | any;
    verifications!: any[];
    vendors!: any[];
    ids = [];
    icrCheckedIds: any;
    viewPortItems: any;

    abstract filterResults (): void;

    abstract populateForm ( employees: Employee[] ): void;

    abstract OnTypeheadSelect ( event: any, name: any, verification: any ): void;

    constructor (
        public icrService: IcrService,
        public commonService: CommonService,
        public route: ActivatedRoute,
        public router: Router,
        public global: GlobalService,
        public fb: FormBuilder,
        public changeDetect: ChangeDetectorRef,
        public confirmationModalService: ConfirmationModalService,
        public identityService?: IdentityService,
    ) {
        this.initForm();
    }

    get districts () { return this.global.fetchDistricts(); }

    ngOnInit () {
        this.checkedIds = [];
        this.verification = 'icr';
        this.subscribeToQueryChanges();
    }

    initForm () {
        this.icrFormGroup = this.fb.group( {
            formArray: this.fb.array( [] ),
        } );
    }


    setIcrVerification ( employee: Employee ) {
        const control: FormArray = this.fb.array( [] );
        employee.icr_verifications.forEach( ( icr_verification: any ) => {
            control.push(
                this.fb.group( {
                    id: icr_verification.id,
                    status: icr_verification.status,
                    color: icr_verification.color,
                    address: icr_verification.address,
                    state_id: icr_verification.state_id,
                    state_name: icr_verification.state_name,
                    district_id: icr_verification.district_id,
                    district_name: icr_verification.district_name,
                    landmark: icr_verification.landmark,
                    pincode: [ icr_verification.pincode ],
                    is_checked: true,
                    full_address: [ icr_verification.full_address ],
                } )
            );
        } );

        return control;
    }


    showParsedErrorNotification ( key: string, errors: { [ x: string ]: string; }, formBody: any[] ) {

        const splitText: any = key.split( '.' );

        const errorKey = splitText[ splitText.length - 1 ];

        const trackid = formBody[ splitText[ 0 ] ].track_id;

        this.commonService.notifications.error( trackid, errorKey + ' - ' + errors[ key ] );
    }


    submitForm ( formBody: Object | undefined | any ): void {

        this.icrService.submitToVcourt( formBody ).subscribe( {

            next: ( response: SubmitVcourtResponse ) => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Successful', 'Submission Successful : ' + response[ 'count' ] );
                }
            },

            error: ( error: HttpErrorResponse ) => {

                this.commonService.notifications.error( 'Error', 'Something went wrong!' );

                if ( !error.error?.errors ) return;

                Object.keys( error.error.errors ).forEach( key => {
                    this.showParsedErrorNotification( key, error.error.errors, formBody )
                } )
            }
        } );
    }

    onSubmitFormButtonClicked ( via: string ) {

        const formBody: any = [];

        for ( let i = 0; i < this.formArray.length; i++ ) {

            const formGroup = this.formArray.controls[ i ] as FormGroup;

            formGroup.patchValue( { 'via': via } );

            formGroup.get( 'identity_verifications' )?.disable();

            // If selected
            if ( formGroup.controls[ 'is_checked' ].value ) {
                formBody.push( formGroup.value );
            }
        }


        for ( const form of formBody ) {

            const icr = form.icr_verifications_form_array;

            form.icr_verifications_form_array = [];

            for ( let i = 0; i < icr.length; i++ ) {

                if ( icr[ i ].is_checked ) {

                    form.icr_verifications_form_array.push( icr[ i ] );

                }
            }
        }

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService?.confirm( options, () => this.submitForm( formBody ) );


    }


    get formArray (): FormArray {
        return this.icrFormGroup.get( 'formArray' ) as FormArray;
    }

    // Mark/Unmark all check boxes.
    checkAll ( ev: any ) {
        this.checkedIds = [];
        for ( let i = 0; i < this.formArray.length; i++ ) {
            const formGroup = this.formArray.controls[ i ] as FormGroup;
            formGroup.controls[ 'is_checked' ].setValue( false );
            if ( ev.target.checked ) {
                formGroup.controls[ 'is_checked' ].setValue( true );
                this.checkedIds.push( this.pagination.data[ i ].id );
                this.checkAllIcr( formGroup );
            }
        }
    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( employee: any, data: any ) {

        const id = employee.controls.employee_id.value;
        const icr = employee.controls.icr_verifications_form_array.controls;
        const len = icr.length;

        if ( data.target.checked ) {
            this.checkedIds.push( id )
            for ( let i = 0; i < len; i++ ) {
                icr[ i ].is_checked = false;
                icr[ i ].patchValue( { 'is_checked': true } )
            }
            this.checkAllIcr( employee );
        } else {
            const index = this.checkedIds.indexOf( id );
            if ( index !== -1 ) {
                this.checkedIds.splice( index, 1 );
            }
        }
    }


    copyToIcr ( verification: any, employee: any, i: any, icr: FormGroup<any> ): void {


        const formGroup = icr as FormGroup;


        formGroup.controls[ 'address' ].setValue( verification.address );
        formGroup.controls[ 'state_id' ].setValue( verification.state_id );
        formGroup.controls[ 'state_name' ].setValue( verification.state_name );
        formGroup.controls[ 'district_id' ].setValue( verification.district_id );
        formGroup.controls[ 'district_name' ].setValue( verification.district_name );
        formGroup.controls[ 'pincode' ].setValue( verification.pincode );
        formGroup.controls[ 'landmark' ].setValue( verification.landmark );
    }


    showPage ( page: any ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }


    subscribeToQueryChanges () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    changeStatus ( statusId: number ): void {

        this.commonService.changestatus( this.icrCheckedIds, this.verification, statusId ).subscribe( {
            next: ( response: any ) => {

                if ( response[ 'code' ] === 200 ) {

                    this.filterResults();

                    this.commonService.notifications.success( 'Verification Updated', response[ 'message' ] );

                    this.icrCheckedIds = [];

                    const checkBox = <HTMLInputElement> document.getElementById( 'checkAll' );

                    checkBox.checked = false;

                    this.checkedIds = [];

                }
            },
            error: ( errors: any ) => {

                this.commonService.notifications.error( 'Error', 'Something went wrong!' );

                this.commonService.displayValidationErrors( errors );

            }
        } );
    }

    onChangeStatusButtonClicked ( statusId: number, statusTitle: string ) {

        const formBody = [];

        this.icrCheckedIds = [];

        for ( let i = 0; i < this.formArray.length; i++ ) {

            const formGroup = this.formArray.controls[ i ] as FormGroup;
            // If selected
            if ( formGroup.controls[ 'is_checked' ].value ) {
                formBody.push( formGroup.value );
            }
        }

        for ( const form of formBody ) {
            const icr = form.icr_verifications_form_array;
            form.icr_verifications_form_array = [];
            for ( let i = 0; i < icr.length; i++ ) {
                if ( icr[ i ].is_checked ) {
                    if ( this.icrCheckedIds.indexOf( icr[ i ].id ) === -1 ) {
                        this.icrCheckedIds.push( icr[ i ].id );
                    }
                } else {
                    const index = this.icrCheckedIds.indexOf( icr[ i ].id );
                    if ( index !== -1 ) {
                        this.icrCheckedIds.splice( index, 1 );
                    }
                }

            }
        }

        const options: ConfirmationModalOptions = {};

        if ( this.icrCheckedIds.length > 0 ) {

            this.confirmationModalService?.confirm( options, () => this.changeStatus( statusId ) );

        } else {

            options.text = "please select any ";

            this.confirmationModalService?.simpleConfirm( options );

        }

    }

    checkAllIcr ( employee: any ) {
        const icr = employee.controls.icr_verifications_form_array.controls;
        const len = icr.length;

        for ( let i = 0; i < len; i++ ) {
            if ( !icr[ i ].is_checked ) {
                icr[ i ].patchValue( { 'is_checked': true } )
            }
        }
    }

    getCompanyName ( employeeForm: AbstractControl ): string {
        return employeeForm.get( 'company.name' )?.value
    }
}
