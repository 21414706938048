<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Vehicle Rc Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterQueriedResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>


<app-vehicle-stats></app-vehicle-stats>
<app-vehicle-filters></app-vehicle-filters>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>

<app-vehicle-details #vehicleDetails></app-vehicle-details>

<app-vehicle-api-response-modal #vehicleApiResponse></app-vehicle-api-response-modal>

<div class="row" style="padding: 0px 0px 0px 35px" appCan="superadmin">

    <a class="btn btn-primary btn-addon" (click)="resendAll('sms')">
        <i class="fa fa-send"></i>
        Resend Sms
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('parivahan')">
        <i class="fa fa-send"></i>
        Resend Parivahan
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('vahan')">
        <i class="fa fa-send"></i>
        Resend Vahan
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('state')">
        <i class="fa fa-send"></i>
        Resend State
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('vahan_citizen')">
        <i class="fa fa-send"></i>
        Resend Vahan Citizen
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('signzy')">
        <i class="fa fa-send"></i>
        Resend Signzy
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('karza')">
        <i class="fa fa-send"></i>
        Resend Karza
    </a>

    <a class="btn btn-primary btn-addon" (click)="exportLogsExcel()" [appHide]="'vehicle,download.excel'">
        <i class="fa fa-download"></i>
        Download Logs
    </a>

    <a class="btn btn-primary btn-addon" (click)="exportUniqueLogsExcel()" [appHide]="'vehicle,download.excel'">
        <i class="fa fa-download"></i>
        Download Unique Logs
    </a>
    <a class="btn btn-primary btn-addon" (click)="exportDailyUniqueLogsExcel()" [appHide]="'vehicle,download.excel'">
        <i class="fa fa-download"></i>
        Download Daily Unique Logs
    </a>

</div>

<br>

<div class="row" style="margin-left:8px ;">
    <div class="col-md-12">
        <a class="btn btn-primary btn-addon" (click)="exportBlacklistedLogsExcel()"
            [appHide]="'vehicle,blacklisted.excel'">
            <i class="fa fa-download"></i>
            Download Blacklisted Logs
        </a>
        <a class="btn btn-primary btn-addon" (click)="exportChallanLogsExcel()" [appHide]="'vehicle,challan.excel'">
            <i class="fa fa-download"></i>
            Download Challan Logs
        </a>

        <a class="btn btn-primary btn-addon" [routerLink]="['/vehiclerc/import']"
            appAccess="vehicle,challan.excel.import">
            <i class="fa fa-download"></i>
            Import Excel
        </a>
    </div>

</div>

<br>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 10%">Details</th>
                        <th style="width: 4%">Sms</th>
                        <th style="width: 4%">Parivahan</th>
                        <th style="width: 4%">Vahan</th>
                        <th style="width: 4%">State</th>
                        <th style="width: 4%">Vahan Citizen</th>
                        <th style="width: 4%">Karza</th>
                        <th style="width: 4%">Signzy</th>
                        <th style="width: 30%">Timeline</th>
                        <th style="width: 16%" appCan="superadmin">Actions</th>
                        <th style="width: 25%" appCan="superadmin">Webhook</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li><a class="btn-link text-main"
                                        (click)="vehicleDetails.show(log.id, log.number)"><strong>{{log.number}}</strong></a>
                                </li>
                                <li>{{log.client}}</li>
                                <li>{{log.id}} - {{log.extended}}</li>
                            </ul>
                        </td>

                        <td>
                            <app-vehicle-list-item [log]="log" [source]="'sms'"> </app-vehicle-list-item>
                        </td>

                        <td>
                            <app-vehicle-list-item [log]="log" [source]="'parivahan'"> </app-vehicle-list-item>
                        </td>

                        <td>
                            <app-vehicle-list-item [log]="log" [source]="'vahan'"> </app-vehicle-list-item>
                        </td>

                        <td>
                            <app-vehicle-list-item [log]="log" [source]="'state'"> </app-vehicle-list-item>
                        </td>

                        <td>
                            <app-vehicle-list-item [log]="log" [source]="'vahan_citizen'"> </app-vehicle-list-item>
                        </td>

                        <td>
                            <app-vehicle-list-item [log]="log" [source]="'karza'"> </app-vehicle-list-item>
                        </td>

                        <td>
                            <app-vehicle-list-item [log]="log" [source]="'signzy'"> </app-vehicle-list-item>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Received At:- {{log.created_at}}</li>
                                <li>Last Updated:- {{log.updated_at}}</li>
                                <div *ngIf="log.sms_log">
                                    <li>Sms Sent:- {{log.sms_sent_at}}</li>
                                    <li>Sms Received:- {{log.sms_received_at}}</li>
                                </div>
                            </ul>
                        </td>


                        <td appCan="superadmin">
                            <a class="btn-link blue-text" (click)="resend('sms',log)"> Resend SMS</a>
                            <br>
                            <a class="btn-link blue-text" (click)="resend('parivahan',log)"> Resend Parivahan</a>
                            <br>
                            <a class="btn-link blue-text" (click)="resend('vahan',log)"> Resend Vahan</a>
                            <br>
                            <a class="btn-link blue-text" (click)="resend('state',log)"> Resend State</a>
                            <br>
                            <a class="btn-link blue-text" (click)="resend('vahan_citizen',log)"> Resend Vahan
                                Citizen</a>
                            <br>
                            <a class="btn-link blue-text" (click)="resend('karza',log)"> Resend Karza</a>
                            <br>
                            <a class="btn-link blue-text" (click)="resend('signzy',log)"> Resend Signzy</a>
                        </td>
                        <td appCan="superadmin">
                            <div *ngIf="log.webhook_sent; else webhookPending">
                                Sent
                            </div>

                            <ng-template #webhookPending>
                                <div class="">Pending for {{log.webhook_pending_for}}</div>
                            </ng-template>

                            <a class="btn-link blue-text" (click)="resend('webhook',log)"> Resend Webhook</a>
                            <br>
                            <a class="btn-link blue-text" (click)="vehicleApiResponse.show(log)">View Api Response</a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>